<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Stock Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Packet / Lot</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_no"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
                <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                <TomSelect v-model="filter.kapan_id" :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Process'
                }
              }
            }" class="w-full">
                    <option value="0">Select Kapan</option>
                    <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Party</label
              >
              <TomSelect
                v-model="filter.party_id"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Party</option>
              <option v-for="party in page_data.parties" :key="party.id" :value="party.id">{{party.first_name}} {{ party.last_name }}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Process</label
              >
              <TomSelect
                v-model="filter.process_id"
                @change="getProcessChange"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option value="stock">In Stock</option>
              <option v-for="process in page_data.processes" :key="process.id" :value="process.id">{{process.process_name}} ( {{ process.process_short_code ? process.process_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Department</label
              >
              <TomSelect
                v-model="filter.department_id"
                :options="{
                  placeholder: 'Select Department',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Department'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Department</option>
              <option v-for="department in page_data.departments" :key="department.id" :value="department.id">{{department.department_name}} ( {{ department.department_short_code ? department.department_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Manager</label
              >
              <TomSelect
                v-model="filter.manager_id"
                @change="getEmployeeDetails"
                :options="{
                  placeholder: 'Select Manager',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Manager'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Manager</option>
              <option v-for="manager in page_data.managers" :key="manager.id" :value="manager.id">{{manager.first_name}} {{manager.last_name}} ( {{ manager.short_code ? manager.short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Employee Type</label
              >
              <TomSelect
                v-model="filter.employee_type"
                @change="getEmployeeDetails"
                :options="{
                  placeholder: 'Select Employee Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Karigar Type</option>
              <option value="1">Employee</option>
              <option value="2">Planner / Checker</option>
              <option value="3">Jobwork Party</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Employee</label
              >
              <TomSelect
                v-model="filter.employee_id"
                :options="{
                  placeholder: 'Select Employee',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Employee</option>
              <option v-for="employee in page_data.employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}} ( {{ employee.short_name ? employee.short_name : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Type</label
              >
              <TomSelect
                v-model="filter.type"
                :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
              <option value="video">Video</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Stock Type</label>
                    <TomSelect v-model="filter.stock_type" :options="{
                  placeholder: 'Stock Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stock Type'
                    }
                  }
                }" class="w-full">
                        <option value="0">Stock Type</option>
                        <option value="1">Hold</option>
                        <option value="2">Active</option>
                    </TomSelect>
                </div>
            </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="filter.issue_pending"
                class="form-control"
                placeholder=""
                v-model="filter.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                >Days</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="filter.day_order" @change="day_order_change"
              /></div>
            <input
              id="tabulator-html-filter-value"
              v-model="filter.days"
              type="number"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Days"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                >Late</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="filter.late_order" @change="late_order_change"
              /></div>
            <input
              id="tabulator-html-filter-value"
              v-model="filter.late"
              type="number"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Late"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Process Done Stock</label
              >
              <TomSelect
                v-model="filter.process_done"
                @change="getProcessChange"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in page_data.processes" :key="process.id" :value="process.id">{{process.process_name}} ( {{ process.process_short_code ? process.process_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-2">
            <div class="mt-5 flex pt-4">
              <input id="remember-me" type="checkbox" v-model="filter.for_p" class="form-check-input border mr-2 bg-white" />
            <label for="remember-me" class="text-md font-medium">4P</label>
            </div>
            <div>
              <input id="remember-me" type="checkbox" v-model="filter.semi_polish" class="form-check-input border mr-2 bg-white" />
            <label for="remember-me" class="text-md font-medium">Semi Polish</label>
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-5 flex pt-4">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-5 flex pt-4">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1" v-if="page_data.items.length > 0">
          <div class="mt-5 flex pt-4">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="printReportDetails"
            >
            Print
            </button>
          </div>
        </div>
      </div>

      <div>
          <!-- BEgin: Process Report Start-->
          <!-- <div id="responsive-table" class="p-5" v-if="page_data.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div> -->

          <div>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table class="w-full mt-3 border-2">
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Quality</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Proc</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Type</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Dept</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Mgr</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Emp</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">Iss Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PL Wt.</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.shape" :name="'shape'" @onChecked="onShapeCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.color" :name="'color'" @onChecked="onColorCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.purity" :name="'purity'" @onChecked="onPurityCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.cut" :name="'cut'" @onChecked="onCutCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.polish" :name="'polish'" @onChecked="onPolishCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.symmetry" :name="'symmetry'" @onChecked="onSymmetryCheck"></MultipleSelect></th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.fluerocent" :name="'fluerocent'" @onChecked="onFluerocentCheck"></MultipleSelect></th> -->
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.tension" :name="'tension'" @onChecked="onTensionCheck"></MultipleSelect></th> -->
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap"><MultipleSelect :options="page_data.parameters.stone" :name="'stone'" @onChecked="onStoneCheck"></MultipleSelect></th> -->
                      <th class="py-2 text-xs lg:text-md">Iss Date</th>
                      <th class="py-2 text-xs lg:text-md">Days</th>
                      <th class="py-2 text-xs lg:text-md">Late</th>
                      <th class="py-2 text-xs lg:text-md">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.rough_weight ? $h.myFloat(page_data.total.rough_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.polish_weight ? $h.myFloat(page_data.total.polish_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    </tr>
                    <tr v-for="(single_item,ind) in page_data.items" :key="ind" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (ind % 2) == 0}">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.number }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.vepari_no }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.quality_name ? single_item.quality_name : "-" }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.process_short_code ? single_item.process_short_code : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.packet_type ? single_item.packet_type : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.department_short_code ? single_item.department_short_code : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.manager_short_name ? single_item.manager_short_name : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.employee_short_name ? single_item.employee_short_name : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.weight ? $h.myFloat(single_item.weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.cr_polish_weight ? $h.myFloat(single_item.cr_polish_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.shape ? single_item.shape : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.color ? single_item.color : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.purity ? single_item.purity : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.cut ? single_item.cut : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.polish ? single_item.polish : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.symmetry ? single_item.symmetry : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.fluerocent ? single_item.fluerocent : '-' }}</td> -->
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.tension ? single_item.tension : '-' }}</td> -->
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.stone ? single_item.stone : '-' }}</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_date ? single_item.issue_date : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-primary-3 text-white': single_item.packet_duration < single_item.days}">{{ single_item.days }}</td>
                      <td class="border-b text-xs lg:text-md text-center border-r" :class="{'bg-primary-3 text-white': ((single_item.packet_delay > 0 && (single_item.packet_delay < single_item.late) && (single_item.transaction_type == 1 || single_item.transaction_type == 3 || single_item.transaction_type == 4)) || (single_item.process_duration > 0  && (single_item.process_duration < single_item.late) && !(single_item.transaction_type == 1 || single_item.transaction_type == 3 || single_item.transaction_type == 4)))}">{{ single_item.late }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                    <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.rough_weight ? $h.myFloat(page_data.total.rough_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.polish_weight ? $h.myFloat(page_data.total.polish_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    </tr>
                  </tbody>

                </table>
                <!-- BEGIN: Pagination -->
                  <div v-if="page_data.items.length > 0"
                      class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
                    >
                      <ul class="pagination">
                        <li>
                          <button class="pagination__link" :disabled="page_data.current_page == 1" @click="getReportDetailPage(page_data.first_page_url)">
                            <ChevronsLeftIcon class="w-4 h-4" />
                          </button>
                        </li>
                        <!-- <li>
                          <button class="pagination__link" :disabled="page_data.prev_page_url == null" @click="getReportDetailPage(page_data.prev_page_url)">
                            <ChevronLeftIcon class="w-4 h-4" />
                          </button>
                        </li> -->
                        <li v-for="(pagelink,index) in page_data.pagelinks" :key="index">
                          <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" :disabled="pagelink.url == null" @click='getReportDetailPage(pagelink.url)'><span v-if="index == 0"><ChevronLeftIcon class="w-4 h-4" /></span><span v-else-if="index == page_data.pagelinks.length - 1"><ChevronRightIcon class="w-4 h-4" /></span><span v-else>{{ pagelink.label }}</span></button>
                        </li>
                        <!-- <li>
                          <button class="pagination__link" :disabled="page_data.next_page_url == null" @click="getReportDetailPage(page_data.next_page_url)">
                            <ChevronRightIcon class="w-4 h-4" />
                          </button>
                        </li> -->
                        <li>
                          <button class="pagination__link" :disabled="page_data.current_page == page_data.last_page" @click="getReportDetailPage(page_data.last_page_url)">
                            <ChevronsRightIcon class="w-4 h-4" />
                          </button>
                        </li>
                      </ul>
                      <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </select>
                  </div>
                  <!-- END: Pagination -->
              </div>
            </div>
          </div>
        </div>
    </div>
      <!-- END: HTML Table Data -->

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch, computed } from 'vue';
import MultipleSelect from '@/components/MultipleSelect/Main.vue'
import store from '@/store'

export default defineComponent({
  components:{
    MultipleSelect
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const page_data = reactive({
      total : [],
      items : [],
      parties : [],
      managers : [],
      processes : [],
      lot_items : [],
      departments : [],
      employees : [],
      loading : false,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      parameters: {
        color: [],
        cut: [],
        purity: [],
        polish: [],
        shape: [],
        symmetry: [],
        fluerocent: [],
        tension: [],
        stone: []
      }
    })
    const filter = reactive({
      packet_no : '',
      issue_pending : true,
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      party_id : '0',
      kapan_id : '0',
      process_id : '0',
      process_done : '0',
      department_id : '0',
      manager_id : '0',
      employee_id : '0',
      employee_type : '0',
      stock_type : '0',
      type : '0',
      size : '20',
      days : '',
      late : '',
      day_order : true,
      late_order : false,
      for_p : false,
      semi_polish : false,
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
    })
    const api_filter = reactive({
      packet_no : '',
      issue_pending : true,
      issue_date: moment().startOf('month').format('YYYY-MM-DD'),
      receive_date:moment().format('YYYY-MM-DD'),
      party_id : '0',
      kapan_id : '0',
      process_id : '0',
      process_done : '0',
      department_id : '0',
      manager_id : '0',
      employee_id : '0',
      employee_type : '0',
      stock_type : '0',
      type : '0',
      size : '20',
      days : '',
      late : '',
      day_order : true,
      late_order : false,
      for_p : false,
      semi_polish : false,
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
    })
    function getReportDetails() {
      api_filter.packet_no = filter.packet_no
      api_filter.party_id = filter.party_id
      api_filter.kapan_id = filter.kapan_id
      api_filter.process_id = filter.process_id
      api_filter.process_done = filter.process_done
      api_filter.department_id = filter.department_id
      api_filter.manager_id = filter.manager_id
      api_filter.employee_id = filter.employee_id
      api_filter.employee_type = filter.employee_type
      api_filter.for_p = filter.for_p
      api_filter.semi_polish = filter.semi_polish
      api_filter.stock_type = filter.stock_type
      api_filter.type = filter.type
      api_filter.size = filter.size
      api_filter.days = filter.days
      api_filter.late = filter.late
      api_filter.color = filter.color
      api_filter.cut = filter.cut
      api_filter.purity = filter.purity
      api_filter.polish = filter.polish
      api_filter.shape = filter.shape
      api_filter.symmetry = filter.symmetry
      api_filter.fluerocent = filter.fluerocent
      api_filter.tension = filter.tension
      api_filter.stone = filter.stone
      api_filter.late_order = filter.late_order
      api_filter.day_order = filter.day_order
      api_filter.issue_pending = filter.issue_pending
      api_filter.issue_date = filter.issue_date
      api_filter.receive_date = filter.receive_date
      getReportDetail()
      getReportDetailsTotal()
    }
    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        page_data.parameters.color = res.data.color
        page_data.parameters.cut = res.data.cut
        page_data.parameters.purity = res.data.purity
        page_data.parameters.symmetry = res.data.symmetry
        page_data.parameters.polish = res.data.polish
        page_data.parameters.fluerocent = res.data.fluerocent
        page_data.parameters.tension = res.data.tension
        page_data.parameters.shape = res.data.shape
        page_data.parameters.stone = res.data.stone
      })
    }
    const getReportDetail = () => {
      page_data.loading = true

      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        process_done : api_filter.process_done == '0' ? '' : window.btoa(api_filter.process_done) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        stock_type : api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        days : api_filter.days == '' ? '' : window.btoa(api_filter.days),
        late : api_filter.late == '' ? '' : window.btoa(api_filter.late),
        size : api_filter.size,
        for_p : api_filter.for_p,
        semi_polish : api_filter.semi_polish,
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
        late_order : api_filter.late_order,
        day_order : api_filter.day_order,
        issue_pending : api_filter.issue_pending,
        issue_date : api_filter.issue_date,
        receive_date : api_filter.receive_date,
      }

      let promise = axios({
        url: 'api/report/get_stock_report_details',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.items = data.data
          page_data.pagelinks = data.links
          page_data.prev_page_url = data.prev_page_url
          page_data.next_page_url = data.next_page_url
          page_data.first_page_url = data.first_page_url
          page_data.last_page_url = data.last_page_url
          page_data.current_page = data.current_page
          page_data.last_page = data.last_page
          page_data.loading = false
        })
        .catch(_error => {
          // page_data.items = []
          page_data.loading = false
        });
    }
    const getReportDetailPage = (url) => {
      page_data.loading = true

      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        process_done : api_filter.process_done == '0' ? '' : window.btoa(api_filter.process_done) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        stock_type : api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        days : api_filter.days == '' ? '' : window.btoa(api_filter.days),
        late : api_filter.late == '' ? '' : window.btoa(api_filter.late),
        size : api_filter.size,
        for_p : api_filter.for_p,
        semi_polish : api_filter.semi_polish,
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
        late_order : api_filter.late_order,
        day_order : api_filter.day_order,
        issue_pending : api_filter.issue_pending,
        issue_date : api_filter.issue_date,
        receive_date : api_filter.receive_date,
      }

      let promise = axios({
        url: url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.items = data.data
          page_data.pagelinks = data.links
          page_data.prev_page_url = data.prev_page_url
          page_data.next_page_url = data.next_page_url
          page_data.first_page_url = data.first_page_url
          page_data.last_page_url = data.last_page_url
          page_data.current_page = data.current_page
          page_data.last_page = data.last_page
          page_data.loading = false
        })
        .catch(_error => {
          page_data.items = []
          page_data.loading = false
        });
    }
    const getReportDetailsTotal = () => {
      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        process_done : api_filter.process_done == '0' ? '' : window.btoa(api_filter.process_done) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        stock_type : api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        days : api_filter.days == '' ? '' : window.btoa(api_filter.days),
        late : api_filter.late == '' ? '' : window.btoa(api_filter.late),
        late_order : api_filter.late_order,
        day_order : api_filter.day_order,
        color : api_filter.color,
        for_p : api_filter.for_p,
        semi_polish : api_filter.semi_polish,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
        issue_pending : api_filter.issue_pending,
        issue_date : api_filter.issue_date,
        receive_date : api_filter.receive_date,
      }

      let promise = axios({
        url: 'api/report/get_stock_report_details_total',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.total = data.total
        })
        .catch(_error => {
          page_data.total = []
        });
    }
    const printReportDetails = () => {
      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        process_done : api_filter.process_done == '0' ? '' : window.btoa(api_filter.process_done) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        stock_type : api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        days : api_filter.days == '' ? '' : window.btoa(api_filter.days),
        late : api_filter.late == '' ? '' : window.btoa(api_filter.late),
        late_order : api_filter.late_order,
        day_order : api_filter.day_order,
        for_p : api_filter.for_p,
        semi_polish : api_filter.semi_polish,
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
        issue_pending : api_filter.issue_pending,
        issue_date : api_filter.issue_date,
        receive_date : api_filter.receive_date,
        filters : api_filter,
        report_name : 'stock-report-details',
      }

      let promise = axios({
        url: 'api/print/set_stock_report_details',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          let id = data.report_id

          let url = BASE_URL + data.report_name + '?report_id=' + window.btoa(id)
            window.open(url, '_blank');
        })
        .catch(_error => {
          page_data.total = []
        });
    }
    const getPartyDetails = () => {

        let params = {
          // department_id : window.btoa(filter.department_id)
        }
        let api_url = 'api/master/dp_parties'
        let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            page_data.parties = data.items
          })
          .catch(_error => {
            page_data.parties = []
          });
    }
    const getDepartmentDetails = () => {
      let params = {
        // department_id : window.btoa(filter.department_id)
      }
      let api_url = 'api/master/dp_department'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.departments = data.items
        })
        .catch(_error => {
          page_data.departments = []
        });
    }
    const getManagerDetails = () => {
      let params = {
        department_id : filter.department_id == '0' ? '' : window.btoa(filter.department_id),
        process_id : filter.process_id == '0' ? '' : window.btoa(filter.process_id)
      }
      let api_url = ''

      if(filter.department_id == '0' && filter.process_id == '0') {

        api_url = 'api/master/dp_managers'
      } else {
        api_url = 'api/master/get_manager_by_department'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.manager_id = '0'
          page_data.managers = data.items
        })
        .catch(_error => {
          filter.manager_id = '0'
          page_data.managers = []
        });
      }
    const getProcessDetails = () => {
      let params = {
        id : window.btoa(filter.manager_id)
      }
      let api_url = ''

      if(filter.manager_id == '0') {

        api_url = 'api/master/dp_process'
      } else {
        api_url = 'api/master/dp_process'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.process_id = '0'
          page_data.processes = data.items
        })
        .catch(_error => {
          filter.process_id = '0'
          page_data.processes = []
        });
    }
    const getEmployeeDetails = () => {

      let params = {
        manager_id : user_details.value.user_role == 1 ? (filter.manager_id == '0' ? '' : window.btoa(filter.manager_id)) : user_details.value.account_id,
        process_id : filter.process_id == '0' ? '' : window.btoa(filter.process_id),
        employee_type : filter.employee_type == '0' ? '': window.btoa(filter.employee_type),
      }
      let api_url = ''

      if(filter.department_id == '0') {

        api_url = 'api/master/get_employees_by_manager_employee_type'
      } else {
        api_url = 'api/master/get_employees_by_manager_employee_type'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.employee_id = '0'
          page_data.employees = data.items
        })
        .catch(_error => {
          filter.employee_id = '0'
          page_data.employees = []
        });
    }
    const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = '0'
                    page_data.lot_items = []
                });
        }
    function onColorCheck(val) {
      filter.color = val;
      getReportDetails()
    }
    function onPurityCheck(val) {
      filter.purity = val;
      getReportDetails()
    }
    function onCutCheck(val) {
      filter.cut = val;
      getReportDetails()
    }
    function onPolishCheck(val) {
      filter.polish = val;
      getReportDetails()
    }
    function onShapeCheck(val) {
      filter.shape = val;
      getReportDetails()
    }
    function onSymmetryCheck(val) {
      filter.symmetry = val;
      getReportDetails()
    }
    function onStoneCheck(val) {
      filter.stone = val;
      getReportDetails()
    }
    function onFluerocentCheck(val) {
      filter.fluerocent = val;
      getReportDetails()
    }
    function onTensionCheck(val) {
      filter.tension = val;
      getReportDetails()
    }
    function onResetFilter() {
      filter.packet_no = ''
      filter.party_id = '0'
      filter.kapan_id = '0'
      filter.process_id = '0'
      filter.department_id = '0'
      filter.manager_id = '0'
      filter.employee_id = '0'
      filter.employee_type = '0'
      filter.type = '0'
      filter.days = ''
      filter.late = ''
      filter.process_done = '0'
      filter.color = []
      filter.cut = []
      filter.purity = []
      filter.polish = []
      filter.shape = []
      filter.symmetry = []
      filter.fluerocent = []
      filter.tension = []
      filter.stone = []
      filter.issue_pending = true
      filter.for_p = true
      filter.semi_polish = true
      filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
      filter.receive_date =moment().format('YYYY-MM-DD')
    }
    function generateReport() {
      getReportDetails()
    }
    function getProcessChange() {
      if(user_details.value.user_role == 1) {
        getManagerDetails()
      } else {
        getEmployeeDetails()
      }
    }
    function day_order_change() {

      if(filter.day_order == false) {
        filter.late_order = true
      } else {
        filter.late_order = false
      }
    }
    function late_order_change() {

      if(filter.late_order == false) {
        filter.day_order = true
      } else {
        filter.day_order= false
      }
    }
    onMounted(() => {
      getPartyDetails()
      getDepartmentDetails()
      getManagerDetails()
      getProcessDetails()
      getDiamontData()
      generateReport()
      getLotDetails()
    })
    return {
      page_data,
      filter,api_filter,user_details,generateReport,
      getProcessDetails,
      getManagerDetails,
      onResetFilter,
      getEmployeeDetails,
      getProcessChange,
      getReportDetailPage,
      onColorCheck,
      onPurityCheck,
      onCutCheck,
      onPolishCheck,
      onShapeCheck,
      onSymmetryCheck,
      onFluerocentCheck,
      onStoneCheck,
      onTensionCheck,
      day_order_change,
      late_order_change,
      printReportDetails,
      getLotDetails
    }
  }
})
</script>
