/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from "axios";
import { BASE_URL } from "@/constants/config.js";
import './libs'
import { helper as $h } from '@/utils/helper'
import VueBarcode from '@chenfengyuan/vue-barcode';
import print from 'vue3-print-nb'
import moment from 'moment';
import excel from 'vue-excel-export'

// SASS Theme
import './assets/sass/app.scss'

const app = createApp(App)
  .use(store)
  .use(router)
  .use($h)
  .use(VueSweetalert2)
  .component(VueBarcode.name, VueBarcode)
  .use(print)
  .use(excel)

  window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = BASE_URL;


const http = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
})

globalComponents(app)
utils(app)

app.mount('#app')
