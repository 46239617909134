const state = () => {
  return {
    menu: [

      // Admin Requests
      {
        icon: 'SidebarIcon',
        pageName: 'notifications',
        title: 'Purchase',
        roles: [1,3,2,8],
        subMenu: [
          {
            icon: 'BoxIcon',
            pageName: 'purchase-view',
            title: 'Makeable Purchase',
            roles: [1,3,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'rough-purchase-view',
            title: 'Rough Purchase',
            roles: [1,2,8],
          }
        ]
      },
      // Transactions
      {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Process',
        roles: [1,2,3,4,8,5,6],
        subMenu: [
          {
            pageName: 'packet-lise-view',
            title: 'Packets',
            roles: [1,2,8,5,6,4]
          },
          {
            pageName: 'single-packet-transaction',
            title: 'Single Packet',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'multiple-issue-transaction',
            title: 'Multiple Issue',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'planner-multiple-issue-transaction',
            title: 'Planning Packets',
            roles: [5,4,6,2,8,1,3]
          },
          {
            icon: 'BoxIcon',
            pageName: 'party-receive-rough-transaction',
            title: 'Multiple Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'planner-multiple-transfer',
            title: 'Plan Done Packets',
            roles: [5,6]
          },
          // {
            //   icon: 'BoxIcon',
            //   pageName: 'multiple-receive-transaction',
          //   title: 'Multiple Receive',
          //   roles: [1,2,8]
          // },
          {
            icon: 'BoxIcon',
            pageName: 'ls-receive-transaction',
            title: 'LS Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'lab-receive',
            title: 'Lab Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'ls-receive-party-transaction',
            title: 'LS Receive',
            roles: [4]
          },
          {
            icon: 'BoxIcon',
            pageName: 'final-gradding-update',
            title: 'Final Gradding',
            roles: [1]
          },
          {
            pageName: 'rough-purchase-transaction',
            title: 'Rough Transaction',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'transaction-history',
            title: 'Transactions',
            roles: [1,2,4,8,5,6]
          },
          // {
          //   icon: 'BoxIcon',
          //   pageName: 'discuss-transaction',
          //   title: 'Discuss Packets',
          //   roles: [1,3,2,8]
          // },
          // {
          //   icon: 'BoxIcon',
          //   pageName: 'party-receive-rough-transaction',
          //   title: 'Full Process (R)',
          //   roles: [1,2,8]
          // },
        ]
      },
      // Transactions
      {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Discuss',
        roles: [1,2,4,8],
        subMenu: [
          {
            pageName: 'discuss-stocks',
            title: 'Packets',
            roles: [1,2,4,8]
          },
          {
            pageName: 'discuss-transactions',
            title: 'Transaction',
            roles: [1,2,4,8]
          },
        ]
      },
      // Admin Requests
      {
        icon: 'SidebarIcon',
        pageName: 'notifications',
        title: 'Notification',
        roles: [1,2,8],
        subMenu: [
          {
            icon: 'BoxIcon',
            pageName: 'notification-employee',
            title: 'Employee Rate',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'notification-manager',
            title: 'Manager Rate',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'notification-edit-param',
            title: 'Update Parameter',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'notification-update-param',
            title: 'Param Change',
            roles: [1,2,8],
          },
        ]
      },
      // Admin Reports
      {
        icon: 'SidebarIcon',
        pageName: 'reports',
        title: 'Reports',
        roles: [1,2,8,3],
        subMenu: [
          {
            icon: 'BoxIcon',
            pageName: 'size-polish',
            title: 'Polish Size',
            roles: [1],
          },
          {
            icon: 'BoxIcon',
            pageName: 'user_costing_report',
            title: 'Costing Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'user_process_report',
            title: 'Process Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'user_stock_report',
            title: 'Stock Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'stock_tally_report',
            title: 'Stock Tally',
            roles: [2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'user_stock_party_report',
            title: 'Stock Report',
            roles: [3],
          },
          {
            icon: 'BoxIcon',
            pageName: 'packet_stock_summary',
            title: 'Process Stock Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'rtp_report',
            title: 'RTP Report',
            roles: [1,2,8, 4],
          },
          {
            icon: 'BoxIcon',
            pageName: 'mtp_report',
            title: 'MTP Report',
            roles: [1,2,8, 4],
          },
          {
            icon: 'BoxIcon',
            pageName: 'lab_report',
            title: 'LAB Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'full_kapan_report',
            title: 'Full Kapan Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'kapan_done_report',
            title: 'Kapan Done Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'comparison_report',
            title: 'Comparison Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'rejection_report',
            title: 'Rejection Report',
            roles: [1,2,8],
          },
          // {
          //   icon: 'BoxIcon',
          //   pageName: 'process_stock_new_report',
          //   title: 'Process Report',
          //   roles: [1,2,8],
          // },
          {
            icon: 'BoxIcon',
            pageName: 'department_stock_report',
            title: 'Department Stock Report',
            roles: [1],
          },
          {
            icon: 'BoxIcon',
            pageName: 'manager_stock_report',
            title: 'Manager Stock Report',
            roles: [1,2,8],
          },
          // {
          //   icon: 'BoxIcon',
          //   pageName: 'party_stock_report',
          //   title: 'Party Stock Report',
          //   roles: [1],
          // },
          // {
          //   icon: 'BoxIcon',
          //   pageName: 'party_packet_report',
          //   title: 'Party Packet Report',
          //   roles: [1],
          // },
          // {
          //   icon: 'BoxIcon',
          //   pageName: 'makeable_outward_report',
          //   title: 'Makeable Outward Report',
          //   roles: [1,3],
          // },
          // {
          //   icon: 'BoxIcon',
          //   pageName: 'rough_outward_report',
          //   title: 'Rough Outward Report',
          //   roles: [1,3],
          // },
        ]
      },
      {
        icon: 'BoxIcon',
        pageName: 'admin-multiple-packet-plans',
        title: 'Rough Planning',
        roles: [1]
      },
      // Rough To Makeable Transfer
      {
        icon: 'MonitorIcon',
        pageName: 'rough-to-makeable-view',
        title: 'Makeable Transfer',
        roles: [8]
      },
      // Rough To Makeable Transfer
      {
        icon: 'MonitorIcon',
        pageName: 'urgent-packets-view',
        title: 'Urgent',
        roles: [1]
      },
      // Outword View
      {
        icon: 'MonitorIcon',
        pageName: 'outword-packets-view',
        title: 'Outward',
        roles: [3]
      },
      // Closings
      {
        icon: 'MonitorIcon',
        pageName: 'manager-closings-view',
        title: 'Closings',
        roles: [2,8]
      },
      {
        icon: 'MonitorIcon',
        pageName: 'admin-closings-view',
        title: 'Closings',
        roles: [1]
      },
      // Master
      {
        icon: 'MonitorIcon',
        pageName: 'master',
        title: 'master',
        roles: [1]
      },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'admin-dashboard',
      //   title: 'Dashboard',
      //   access: 84
      // },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'party-dashboard',
      //   title: 'Dashboard',
      //   access: 85
      // },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'jober-dashboard',
      //   title: 'Dashboard',
      //   access: 86
      // },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'ManagerDashboard',
      //   title: 'Dashboard',
      //   access: 87
      // },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'planner-dashboard',
      //   title: 'Dashboard',
      //   access: 242
      // },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'checker-ashboard',
      //   title: 'Dashboard',
      //   access: 243
      // },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'lsbs-dashboard',
      //   title: 'Dashboard',
      //   access: 250
      // },
      // // Admin Transfered Rough Packets
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'admin-jobwork-done',
      //   title: 'R Receive',
      //   access: 259,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'rough-to-makeable',
      //       title: 'R Makeable',
      //       access: 256
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'rough-to-rough',
      //       title: 'R Rough',
      //       access: 257
      //     }
      //   ]
      // },
      // // Admin Transfered Rough Packets
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'admin-reports',
      //   title: 'Report',
      //   access: 260,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'packet-report',
      //       title: 'Packet',
      //       access: 261
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'stock-report',
      //       title: 'Stock',
      //       access: 262
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'process-report',
      //       title: 'Process',
      //       access: 263
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'department-report',
      //       title: 'Department',
      //       access: 264
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'employee-report',
      //       title: 'Employee',
      //       access: 265
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'outward-report',
      //       title: 'Outward',
      //       access: 266
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'party-report',
      //       title: 'Party',
      //       access: 267
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'rough-report',
      //       title: 'Rough',
      //       access: 268
      //     }
      //   ]
      // },
      // // Admin Requests
      // {
      //   icon: 'SidebarIcon',
      //   pageName: 'admin-notifications',
      //   title: 'Notification',
      //   access: 212,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'rate-requests',
      //       title: 'Employee Rate',
      //       access: 213
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'rate-requests-manager',
      //       title: 'Manager Rate',
      //       access: 254
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'rate-requests-jober',
      //       title: 'JobWork Rate',
      //       access: 255
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'param-change-requests',
      //       title: 'Param Change',
      //       access: 214
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'param-change-mdl-request',
      //       title: 'Edit Param',
      //       access: 215
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'admin-discuss',
      //       title: 'Discuss',
      //       access: 216
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'costing-notification',
      //       title: 'Costing',
      //       access: 269
      //     }
      //   ]
      // },
      // // Admin Scan Packets
      // {
      //   icon: 'InboxIcon',
      //   pageName: 'process-packet',
      //   title: 'Packet',
      //   access: 94
      // },
      // // Admin Urgent packets
      // {
      //   icon: 'MonitorIcon',
      //   pageName: 'admin-urgent-packets',
      //   title: 'Urgent',
      //   access: 232
      // },
      // // Admin Closing
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'admin-closing',
      //   title: 'Closing',
      //   access: 230
      // },
      // // Admin Jobwork Done
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'admin-jobwork-done',
      //   title: 'OutWord',
      //   access: 237
      // },
      // Admin Master
      // // Party Requests
      // {
      //   icon: 'SidebarIcon',
      //   pageName: 'party-notifications',
      //   title: 'Notification',
      //   access: 218,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'party-discuss',
      //       title: 'Discuss',
      //       access: 217
      //     }
      //   ]
      // },
      // // Party Jobwork Done
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'party-jobwork-done',
      //   title: 'OutWord',
      //   access: 239
      // },
      // // Manager Packet Issue Receive Process
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'side-menu-menu-layout',
      //   title: 'Process',
      //   access: 101,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'SinglePacket',
      //       title: 'Single Pkt - I/R',
      //       access: 95
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'ManagerPackets',
      //       title: 'Multiple Pkt - I',
      //       access: 96
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'MultiplePackets',
      //       title: 'Multiple Pkt - R',
      //       access: 97
      //     }
      //   ]
      // },
      // // Admin Transfered Rough Packets
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'manager-reports',
      //   title: 'Report',
      //   access: 271,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'manager-stock-report',
      //       title: 'Stock',
      //       access: 270
      //     }
      //   ]
      // },
      // // Manager Transfer Packet Process
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'side-menu-menu-layout',
      //   title: 'Packets',
      //   access: 102,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'TransferedPackets',
      //       title: 'Transfered Pkts',
      //       access: 98
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'ReceivedPackets',
      //       title: 'Received Pkts',
      //       access: 99
      //     }
      //   ]
      // },
      // // Manager Urgent Packet
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'side-menu-menu-layout',
      //   title: 'Urgant',
      //   access: 235,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'manager-all-urgent-packets',
      //       title: 'Internal',
      //       access: 233
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'manager-external-urgent-packets',
      //       title: 'External',
      //       access: 234
      //     }
      //   ]
      // },
      // // Manager External Receive Packets
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'manager-external-packets',
      //   title: 'External',
      //   access: 236
      // },
      // // Manager Requests
      // {
      //   icon: 'SidebarIcon',
      //   pageName: 'manager-notifications',
      //   title: 'Notification',
      //   access: 219,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'manager-rate-change',
      //       title: 'Rate Change',
      //       access: 221
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'manager-param-change',
      //       title: 'Param Change',
      //       access: 222
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'manager-receive-from-jober',
      //       title: 'Receive Packet',
      //       access: 229
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'manager-discuss',
      //       title: 'Discuss',
      //       access: 220
      //     }
      //   ]
      // },
      // // Manager Jobwork Done
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'manager-jobwork-done',
      //   title: 'OutWord',
      //   access: 238
      // },
      // // Manager Planning
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'manager-plan-packets',
      //   title: 'Plannings',
      //   access: 248
      // },
      // // Manager Lsbs
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'lsbs-packet-man',
      //   title: 'Lsbs',
      //   access: 248
      // },
      // // Manager Closing
      // {
      //   icon: 'AirplayIcon',
      //   pageName: 'manager-closing',
      //   title: 'Closing',
      //   access: 231
      // },
      // // Jobwork Packet Issue Receive Process
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'jober-packets',
      //   title: 'Process',
      //   access: 224,
      //   subMenu: [
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'jober-single-packet',
      //       title: 'Single Pkt - I/R',
      //       access: 225
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'jober-multiple-issue',
      //       title: 'Multiple Pkt - I',
      //       access: 226
      //     },
      //     {
      //       icon: 'BoxIcon',
      //       pageName: 'jober-multiple-receive',
      //       title: 'Multiple Pkt - R',
      //       access: 227
      //     }
      //   ]
      // },
      // // Jobwork Packet Requests
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'jober-packet-request',
      //   title: 'Request',
      //   access: 100
      // },
      // // Jobwork Packet Deliver Process
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'jober-deliverable',
      //   title: 'Packet Deliver',
      //   access: 228
      // },
      // // Planner Checker Packets
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'plan-packets',
      //   title: 'Packets',
      //   access: 244
      // },
      // // Lsbs Lsbs Packets
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'lsbs-plan-packets',
      //   title: 'Packets',
      //   access: 252
      // }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
