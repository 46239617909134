<template>
<div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Final Polish Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                  <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                    >Report Type</label
                  ><input
                    class="show-code form-check-switch mr-0 ml-3 h-6"
                    type="checkbox" v-model="filter.pending_packet"
                  /></div>
                    <TomSelect v-model="filter.report_type" :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report'
                    }
                  }
                }" class="w-full">
                        <option value="1">Lot  Wise</option>
                        <option value="2">Jangad Summary</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
                    <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                    <TomSelect v-model="filter.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="[1,2,8].includes(user_details.user_role)">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select Worker</label>
                    <TomSelect v-model="filter.worker_id" :options="{
                  placeholder: 'Select Worker',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Worker'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Worker</option>
                        <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.firm_name}} ( {{ item.short_name ? item.short_name : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 h-6"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="filter.issue_pending"
                class="form-control"
                placeholder=""
                v-model="filter.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-4">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select Jangad</label>
                    <TomSelect v-model="filter.jangad_no" multiple :options="{
                  placeholder: 'Select Jangad',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="">Select Jangad</option>
                        <option v-for="item in page_data.jangad_items" :key="item.id" :value="item.id">{{item.transaction_code}}</option>
                    </TomSelect>
                </div>
            </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-4">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Comparisons</label>
                    <TomSelect v-model="filter.compare_items" :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full" multiple>
                        <option value="4">PLANNING</option>
                        <option value="1">HPHT</option>
                        <option value="2">Final Polish</option>
                        <option value="3">LAB</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-4">
              <div class="mt-9 flex">

                <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                    Reset
                </button>
                <button id="tabulator-html-filter-go" type="button" class="btn btn-primary mt-2 sm:mt-0 sm:ml-2" tabindex="5" @click="getReportDetails()">
                    Generate
                </button>
                  <button id="tabulator-html-filter-go" type="button" class="btn btn-primary mt-2 sm:mt-0 sm:ml-2" tabindex="5" @click="exportReportDetails">
                      Export
                  </button>
              </div>
            </div>
        </div>

        <div>
            <!-- BEgin: Process Report Start-->
            <div id="responsive-table" class="p-5" v-if="page_data.loading">
                <div class="preview">
                    <div class="overflow-x-auto">
                        <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                            <LoadingIcon icon="bars" class="w-8 h-8" />
                            <div class="text-center text-xs mt-2">Getting Latest Price</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="scrollbar-hidden">
                    <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                        <table class="border-0" v-if="api_filter.report_type == 1">
                            <thead>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="p-2 text-xs lg:text-md border-r"></th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap"></th>
                                    <th class="p-2 text-xs lg:text-md border-r" colspan="3">Current Stock</th>
                                    <th class="p-2 text-xs lg:text-md border-r" colspan="4">ISSUE DETAILS</th>
                                    <th class="p-2 text-xs lg:text-md border-r" colspan="6">4P POLISH RECEIVE</th>
                                    <th class="p-2 text-xs lg:text-md border-r" colspan="6" v-if="filter.compare_items.includes('4')">PLANNING</th>
                                    <th class="p-2 text-xs lg:text-md border-r" colspan="8" v-if="filter.compare_items.includes('1')">HPHT POLISH</th>
                                    <th class="p-2 text-xs lg:text-md border-r" colspan="8" v-if="filter.compare_items.includes('2')">FINAL POLISH</th>
                                    <th class="p-2 text-xs lg:text-md border-r" colspan="8" v-if="filter.compare_items.includes('3')">LAB POLISH</th>
                                  </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Packet No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Rough Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Makeable Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">DLC Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Quality</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Manager</th>
                                    <th class="p-2 text-xs lg:text-md border-r" v-if="[1,2,8].includes(user_details.user_role)">Worker</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap">Issue Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Manager</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Process</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Employee</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Shape</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Color</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Purity</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Cut</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Date</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Receive Ct</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Shape</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Color</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Purity</th>
                                    <th class="p-2 text-xs lg:text-md border-r">Cut</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('4')">Date</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('4')">Polish Ct</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('4')">Shape</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('4')">Color</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('4')">Purity</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('4')">Cut</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">Date</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">Manager</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">Employee</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">HPHT Ct</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">Shape</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">Color</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">Purity</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('1')">Cut</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Date</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Manager</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Employee</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Polish Ct</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Shape</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Color</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Purity</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('2')">Cut</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">Date</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">Manager</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">Employee</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">LAB Ct</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">Shape</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">Color</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">Purity</th>
                                    <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap" v-if="filter.compare_items.includes('3')">Cut</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">

                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_issue_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_rough_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_makeable_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_dlc_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_issue_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>

                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>

                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>

                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_receive_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_receive_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>


                                    <th v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (page_data.total.total_planning_packets ? page_data.total.total_planning_packets : '-') }}</th>
                                    <th v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (page_data.total.total_planning_weight ? page_data.total.total_planning_weight : '-') }}</th>
                                    <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>

                                    <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (page_data.total.total_hpht_packets ? page_data.total.total_hpht_packets : '-') }}</th>
                                    <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></th>
                                    <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></th>
                                    <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (page_data.total.total_hpht_weight ? page_data.total.total_hpht_weight : '-') }}</th>
                                    <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>

                                    <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (page_data.total.total_final_packets ? page_data.total.total_final_packets : '-') }}</th>
                                    <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></th>
                                    <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></th>
                                    <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (page_data.total.total_final_weight ? page_data.total.total_final_weight : '-') }}</th>
                                    <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>

                                    <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (page_data.total.total_lab_packets ? page_data.total.total_lab_packets : '-') }}</th>
                                    <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></th>
                                    <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></th>
                                    <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (page_data.total.total_lab_weight ? page_data.total.total_lab_weight : '-') }}</th>
                                    <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>

                                </tr>
                            </thead>
                                <tbody>

                                  <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item,index) in page_data.items" :key="index">

                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.number }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.stock_no }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.rough_weight ? item.rough_weight : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_wt ? item.mk_wt : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.dlc_weight ? item.dlc_weight : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_weight ? item.issue_weight : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.quality_name ? item.quality_name : "" }}</td>
                                        <!-- <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_polish_weight ? item.issue_polish_weight : '-') }}</td> -->
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_manager_name ? item.issue_manager_name : "" }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.isssue_party_name ? item.isssue_party_name : "" }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_date ? item.issue_date : "" }}</td>

                                        <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.manager_short_name ? item.manager_short_name : '-') }}</th>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.process_short_code ? item.process_short_code : '-') }}</th>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.employee_short_name ? item.employee_short_name : '-') }}</th>

                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_shape_name ? item.issue_shape_name : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_color_name ? item.issue_color_name : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_purity_name ? item.issue_purity_name : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_cut_name ? item.issue_cut_name : '-') }}</td>

                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.receive_date ? item.receive_date : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.receive_weight ? item.receive_weight : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.receive_shape_name ? item.receive_shape_name : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.receive_color_name ? item.receive_color_name : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.receive_purity_name ? item.receive_purity_name : '-') }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.receive_cut_name ? item.receive_cut_name : '-') }}</td>

                                        <th v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (item.pl_date ? item.pl_date : '-') }}</th>
                                        <th v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.pl_ex_wt ? item.pl_ex_wt : '-') }}</th>
                                        <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_shape_name ? item.planning_shape_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_color_name ? item.planning_color_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_purity_name ? item.planning_purity_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_cut_name ? item.planning_cut_name : '-') }}</td>

                                        <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (item.hp_date ? item.hp_date : '-') }}</th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.hpht_manager_name ? item.hpht_manager_name : '-' }}</th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.hpht_employee_short_name ? item.hpht_employee_short_name : '-' }}</th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.hp_wt ? item.hp_wt : '-') }}</th>
                                        <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.hpht_shape_name ? item.hpht_shape_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.hpht_color_name ? item.hpht_color_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.hpht_purity_name ? item.hpht_purity_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.hpht_cut_name ? item.hpht_cut_name : '-') }}</td>

                                        <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (item.po_date ? item.po_date : '-') }}</th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_manager_name ? item.final_manager_name : '-' }}</th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_employee_short_name ? item.final_employee_short_name : '-' }}</th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_f_pw ? item.po_f_pw : '-') }}</th>
                                        <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_shape_name ? item.final_shape_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_color_name ? item.final_color_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_purity_name ? item.final_purity_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_cut_name ? item.final_cut_name : '-') }}</td>

                                        <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (item.lab_date ? item.lab_date : '-') }}</th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.lab_manager_name ? item.lab_manager_name : '-' }}</th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.lab_employee_short_name ? item.lab_employee_short_name : '-' }}</th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_wt ? item.lab_wt : '-') }}</th>
                                        <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_shape_name ? item.lab_shape_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_color_name ? item.lab_color_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_purity_name ? item.lab_purity_name : '-') }}</td>
                                        <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_cut_name ? item.lab_cut_name : '-') }}</td>
                                  </tr>

                                </tbody>

                        </table>
                        <table class="border-0 w-full" v-if="api_filter.report_type == 2">
                            <thead>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Jangad No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Manager</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Employee</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Pieces</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">RoughOrg Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Pieces</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Pending Pieces</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Pending Weight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">FInalPolish Pieces</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">FInalPolish Weight</th>
                                  </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">

                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_issue_jangad }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_issue_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_rough_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_issue_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_receive_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_receive_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_pending_final_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_pending_final_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_final_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ page_data.total.total_final_weight }}</td>
                                </tr>
                            </thead>
                            <tbody>

                                <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item,index) in page_data.items" :key="index">

                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{index + 1}}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.transaction_code }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_manager_name }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_party_name }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.jangad_issue_date }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_issue_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_rough_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_issue_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_receive_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_receive_weight }}</td>
                                    <!-- <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_hpht_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_hpht_weight }}</td> -->
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_polish_pending_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_polish_pending_weight }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_polish_received_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_polish_received_weight }}</td>
                                    <!-- <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_lab_packets }}</td>
                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.total_lab_weight }}</td> -->
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import {
    defineComponent,
    onMounted,
    reactive,
    toRef,
    watch,
    computed
} from 'vue';
import store from '@/store'
import { helper as $h } from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            total: [],
            items: [],
            manager_items: [],
            process_items: [],
            lot_items: [],
            employee_items: [],
            loading: false,
        })

        const filter = reactive({
            packet_no: '',
            jangad_no: [],
            report_type: '1',
            kapan_id: '0',
            worker_id: '0',
            compare_items: ['4', '1', '2', '3'],
            issue_pending : false,
            pending_packet : false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const api_filter = reactive({
            packet_no: '',
            jangad_no: [],
            report_type: '1',
            kapan_id: '0',
            worker_id: '0',
            issue_pending : false,
            pending_packet : false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const getReportDetails = async () => {

            api_filter.packet_no = filter.packet_no
            api_filter.jangad_no = filter.jangad_no
            api_filter.report_type = filter.report_type
            api_filter.kapan_id = filter.kapan_id
            api_filter.worker_id = filter.worker_id
            api_filter.issue_pending = filter.issue_pending
            api_filter.pending_packet = filter.pending_packet
            api_filter.issue_date = filter.issue_date
            api_filter.receive_date = filter.receive_date

            const params = {
                packet_no: window.btoa(api_filter.packet_no),
                jangad_no: api_filter.jangad_no.length == 0 ? '' : (api_filter.jangad_no),
                report_type: api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                worker_id: api_filter.worker_id == '0' ? '' : window.btoa(api_filter.worker_id),
                issue_pending : api_filter.issue_pending,
                pending_packet : api_filter.pending_packet,
                start_date : window.btoa(api_filter.issue_date),
                end_date : window.btoa(api_filter.receive_date),
            }
            page_data.loading = true

            let promise = axios({
                url: 'api/report/mtm_details',
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.items = data.items
                    page_data.total = data.total
                    page_data.loading = false
                })
                .catch(_error => {
                    page_data.items = []
                    page_data.total = []
                    page_data.loading = false
                });
        }

        const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = '0'
                    page_data.lot_items = []
                });
        }

        const getJangadDetails = async () => {
            let params = {
            }
            let api_url = 'api/report/mtm_jangad_list'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.jangad_no = []
                    page_data.jangad_items = data.items
                })
                .catch(_error => {
                    filter.jangad_no = []
                    page_data.jangad_items = []
                });
        }

        const getWorkerDetails = async () => {

            let params = {
                manager_id: user_details.value.user_role == 1 ? '' : user_details.value.account_id,
                worker_type: window.btoa('3'),
                process_id: [1,27],
            }
            let api_url = ''

            if (filter.department_id == '0') {

                api_url = 'api/master/get_worker_by_type_pr_arr'
            } else {
                api_url = 'api/master/get_worker_by_type_pr_arr'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.worker_id = '0'
                    page_data.employee_items = data.items
                })
                .catch(_error => {
                    filter.worker_id = '0'
                    page_data.employee_items = []
                });
        }

        function onResetFilter() {
            filter.packet_no = ''
            filter.jangad_no = []
            filter.report_type = '1'
            filter.kapan_id = '0'
            filter.worker_id = '0'
            filter.issue_pending = false
            filter.pending_packet = false
            filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
            filter.receive_date =moment().format('YYYY-MM-DD')
        }
        const exportReportDetails = () => {
          const params = {
            packet_no: window.btoa(api_filter.packet_no),
                jangad_no: api_filter.jangad_no.length == 0 ? '' : (api_filter.jangad_no),
                report_type: api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                worker_id: api_filter.worker_id == '0' ? '' : window.btoa(api_filter.worker_id),
                issue_pending : api_filter.issue_pending,
                pending_packet : api_filter.pending_packet,
                start_date : window.btoa(api_filter.issue_date),
                end_date : window.btoa(api_filter.receive_date),
              compare_items : filter.compare_items,
            }

            const today = new Date();
            const date = today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear();
            // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date;
            let filename = "MTM_" +dateTime + ".xlsx";

            let promise = axios({
                url: "/api/export/mtm-export",
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });

            return promise
                .then((result) => result.data)
                .then((data) => {
                    FileDownload(data, filename);
                })
                .catch((_error) => {
                    return [];
                });
        }
        onMounted(() => {
            getLotDetails()
            getReportDetails()
            getWorkerDetails()
            getJangadDetails()
        })

        return {
            page_data,
            filter,
            api_filter,
            user_details,
            getReportDetails,
            onResetFilter,
            getWorkerDetails,
            exportReportDetails
        }
    }
})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 650px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>
