<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Costing Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label"
              >Packet / Lot</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_no"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-4">
            <div class="mt-2">
                <label for="update-profile-form-6" class="form-label">Select Jangad</label>
                <TomSelect v-model="filter.jangad_no" multiple :options="{
              placeholder: 'Select Jangad',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Process'
                }
              }
            }" class="w-full">
                    <option value="">Select Jangad</option>
                    <option v-for="item in page_data.jangad_items" :key="item.id" :value="item.id">{{item.transaction_code}}</option>
                </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
                <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                <TomSelect v-model="filter.kapan_id" :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Process'
                }
              }
            }" class="w-full">
                    <option value="0">Select Kapan</option>
                    <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 h-6"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                :disabled="filter.issue_pending"
                placeholder=""
                v-model="filter.start_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.end_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Party</label
              >
              <TomSelect
                v-model="filter.party_id"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Party</option>
              <option v-for="party in page_data.parties" :key="party.id" :value="party.id">{{party.first_name}} {{ party.last_name }}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Process</label
              >
              <TomSelect
                v-model="filter.process_id"
                @change="getProcessChange"
                :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Process</option>
              <option v-for="process in page_data.processes" :key="process.id" :value="process.id">{{process.process_name}} ( {{ process.process_short_code ? process.process_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Department</label
              >
              <TomSelect
                v-model="filter.department_id"
                :options="{
                  placeholder: 'Select Department',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Department'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Department</option>
              <option v-for="department in page_data.departments" :key="department.id" :value="department.id">{{department.department_name}} ( {{ department.department_short_code ? department.department_short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Manager</label
              >
              <TomSelect
                v-model="filter.manager_id"
                @change="getEmployeeDetails"
                :options="{
                  placeholder: 'Select Manager',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Manager'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Manager</option>
              <option v-for="manager in page_data.managers" :key="manager.id" :value="manager.id">{{manager.first_name}} {{manager.last_name}} ( {{ manager.short_code ? manager.short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Employee Type</label
              >
              <TomSelect
                v-model="filter.employee_type"
                @change="getEmployeeDetails"
                :options="{
                  placeholder: 'Select Employee Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Karigar Type</option>
              <option value="1">Employee</option>
              <option value="2">Planner / Checker</option>
              <option value="3">Jobwork Party</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Employee</label
              >
              <TomSelect
                v-model="filter.employee_id"
                :options="{
                  placeholder: 'Select Employee',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Employee</option>
              <option v-for="employee in page_data.employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}} ( {{ employee.process_short_code ? employee.short_code : '-' }} )</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Type</label
              >
              <TomSelect
                v-model="filter.type"
                :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
              <option value="video">Video</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Status</label
              >
              <TomSelect
                v-model="filter.status"
                :options="{
                  placeholder: 'Select Status',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Status'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Status</option>
              <option value="1">Issue</option>
              <option value="2">Received</option>
              <option value="3">Cancelled</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Report Type</label
              >
              <TomSelect
                v-model="filter.report_type"
                :options="{
                  placeholder: 'Select Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Kapan</option>
              <option value="1">Process</option>
              <option value="2">Department</option>
              <option value="3">Manager</option>
              <option value="4">Employee</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="flex items-center mt-3">
              <label for="update-profile-form-6" class="form-label"
                >In Details</label
              >
              <input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="filter.in_details"
              />
            </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
          </div>
        </div>
      </div>

      <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="page_data.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 mt-3 overflow-auto border-2 tableFixHead">
                <table class="w-full" v-if="api_filter.in_details == false">
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">
                        <span v-if="api_filter.report_type == '0'">Lot No</span>
                        <span v-if="api_filter.report_type == '1'">Process</span>
                        <span v-if="api_filter.report_type == '2'">Department</span>
                        <span v-if="api_filter.report_type == '3'">Manager</span>
                        <span v-if="api_filter.report_type == '4'">Employee</span>
                      </th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Loss Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Assort Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">Emp Bonus</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">P Emp<br>(Bonus / Penalty)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '4'">P Man<br>(Bonus / Penalty)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">Emp Majuri</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '4'">Man Majuri</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">Emp Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '4'">Man Total</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.lose_weight ? $h.myFloat(page_data.total.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.assortment_weight ? $h.myFloat(page_data.total.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_bonus_value ? $h.myFloat(page_data.total.emp_bonus_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':page_data.total.emp_penalty_value < 0,'bg-green':page_data.total.emp_penalty_value > 0}" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_penalty_value ? $h.myFloat(page_data.total.emp_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':page_data.total.man_penalty_value < 0,'bg-green':page_data.total.man_penalty_value > 0}" v-if="api_filter.report_type != '4'">{{ page_data.total.man_penalty_value ? $h.myFloat(page_data.total.man_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri ? $h.myFloat(page_data.total.employee_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri ? $h.myFloat(page_data.total.manager_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri_total ? $h.myFloat(page_data.total.employee_majuri_total) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri_total ? $h.myFloat(page_data.total.manager_majuri_total) : '-' }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b hover:bg-gray-200 p-0" v-for="(single_item,ind) in page_data.items" :key="ind" :class="{'bg-gray-100': (ind % 2) == 0}">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">
                        <span v-if="api_filter.report_type == '0'">{{ single_item.lot_no }}</span>
                        <span v-if="api_filter.report_type == '1'">{{ single_item.process_name }} ({{ single_item.process_short_code }})</span>
                        <span v-if="api_filter.report_type == '2'">{{ single_item.department_name }} ({{ single_item.department_short_code }})</span>
                        <span v-if="api_filter.report_type == '3'">{{ single_item.first_name }} {{ single_item.last_name }} ({{ single_item.short_code }})</span>
                        <span v-if="api_filter.report_type == '4'">
                          <span v-if="single_item.employee_role == 7">{{ single_item.employee_first_name }} {{ single_item.employee_last_name }} ({{ single_item.short_code }})</span>
                          <span v-if="single_item.employee_role == 5 || single_item.employee_role == 6">{{ single_item.planner_first_name }} {{ single_item.planner_last_name }} ({{ single_item.short_code }})</span>
                          <span v-if="single_item.employee_role == 4">{{ single_item.jober_first_name }} {{ single_item.jober_last_name }} ({{ single_item.short_code }})</span>
                        </span>
                      </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.packets ? single_item.packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.receive_weight ? $h.myFloat(single_item.receive_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.lose_weight ? $h.myFloat(single_item.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.assortment_weight ? $h.myFloat(single_item.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_bonus_value ? $h.myFloat(page_data.total.emp_bonus_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':single_itememp_penalty_value < 0,'bg-green':single_item.emp_penalty_value > 0}" v-if="api_filter.report_type != '3'">{{ single_item.emp_penalty_value ? $h.myFloat(single_item.emp_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':single_itemman_penalty_value < 0,'bg-green':single_item.man_penalty_value > 0}" v-if="api_filter.report_type != '4'">{{ single_item.man_penalty_value ? $h.myFloat(single_item.man_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ single_item.employee_majuri ? $h.myFloat(single_item.employee_majuri)  : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ single_item.manager_majuri ? $h.myFloat(single_item.manager_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ single_item.employee_majuri_total ? $h.myFloat(single_item.employee_majuri_total)  : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ single_item.manager_majuri_total ? $h.myFloat(single_item.manager_majuri_total) : '-' }}</td>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.lose_weight ? $h.myFloat(page_data.total.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.assortment_weight ? $h.myFloat(page_data.total.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_bonus_value ? $h.myFloat(page_data.total.emp_bonus_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':page_data.total.emp_penalty_value < 0,'bg-green':page_data.total.emp_penalty_value > 0}" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_penalty_value ? $h.myFloat(page_data.total.emp_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :class="{'bg-red':page_data.total.man_penalty_value < 0,'bg-green':page_data.total.man_penalty_value > 0}" v-if="api_filter.report_type != '4'">{{ page_data.total.man_penalty_value ? $h.myFloat(page_data.total.man_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri ? $h.myFloat(page_data.total.employee_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri ? $h.myFloat(page_data.total.manager_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri_total ? $h.myFloat(page_data.total.employee_majuri_total) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri_total ? $h.myFloat(page_data.total.manager_majuri_total) : '-' }}</td>
                    </tr>
                  </tbody>

                </table>
                <table v-else class="w-full">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">
                        <span v-if="api_filter.report_type == '0'">Lot No</span>
                        <span v-if="api_filter.report_type == '1'">Process</span>
                        <span v-if="api_filter.report_type == '2'">Department</span>
                        <span v-if="api_filter.report_type == '3'">Manager</span>
                        <span v-if="api_filter.report_type == '4'">Employee</span>
                      </th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Packets</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Quality</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '1'">Process</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Oriznal Weight</th> -->
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Issue Weight <br>Receive Weight</th>
                      <!-- <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Receive Weight</th> -->
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Loss Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Assort Weight</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">Emp Bhav <br>Emp Majuri</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '4'">Man Bhav <br>Man Majuri</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">Bonus (%) <br>Bonus Value</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">P Emp Penalty <br> (Bhav)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '4'">P Man Penalty <br> (Bhav)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '3'">Emp Total</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.report_type != '4'">Man Total</th>
                    </tr>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '1'"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }} <br> {{ page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) + ' Ct' : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.lose_weight ? $h.myFloat(page_data.total.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.assortment_weight ? $h.myFloat(page_data.total.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri ? $h.myFloat(page_data.total.employee_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri ? $h.myFloat(page_data.total.manager_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_bonus_value ? $h.myFloat(page_data.total.emp_bonus_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_penalty_value ? $h.myFloat(page_data.total.emp_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.man_penalty_value ? $h.myFloat(page_data.total.man_penalty_value) : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri_total ? $h.myFloat(page_data.total.employee_majuri_total) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri_total ? $h.myFloat(page_data.total.manager_majuri_total) : '-' }}</td>
                    </tr>
                  </thead>
                    <tbody v-for="(main_item,index) in page_data.items" :key="index">
                    <tr v-for="(item,ind) in main_item.packet_details" :key="ind" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (ind % 2) == 0}">
                      <td class="py-2 text-xs lg:text-md text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                      <td class="py-2 text-xs lg:text-md text-center border-r" :rowspan="item.rows ? item.rows : 1" v-if="item.rows || api_filter.report_type == '1'">
                        <span v-if="api_filter.report_type == '0' && ind == 0">{{ item.lot_no }}</span>
                        <span v-if="api_filter.report_type == '1' && ind == 0">{{ item.process_name }} ({{ item.process_short_code }})</span>
                        <span v-if="api_filter.report_type == '2' && ind == 0">{{ item.department_name }} ({{ item.department_short_code }})</span>
                        <span v-if="api_filter.report_type == '3' && ind == 0">{{ item.first_name }} {{ item.last_name }} ({{ item.short_code }})</span>
                        <span v-if="api_filter.report_type == '4' && ind == 0">
                          <span v-if="main_item.employee_role == 7">{{ item.employee_first_name }} {{ item.employee_last_name }} ({{ item.short_code }})</span>
                          <span v-if="main_item.employee_role == 5 || main_item.employee_role == 6">{{ item.planner_first_name }} {{ item.planner_last_name }} ({{ item.short_code }})</span>
                          <span v-if="main_item.employee_role == 4">{{ item.jober_first_name }} {{ item.jober_last_name }} ({{ item.short_code }})</span>
                        </span>
                      </td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :rowspan="item.rows ? item.rows : 1" v-if="item.rows || api_filter.report_type == '1'">{{ item.number ? item.number : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :rowspan="item.rows ? item.rows : 1" v-if="item.rows || api_filter.report_type == '1'">{{ item.vepari_no ? item.vepari_no : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" :rowspan="item.rows ? item.rows : 1" v-if="item.rows || api_filter.report_type == '1'">{{ item.quality_name ? item.quality_name : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '1'">{{ item.process_name }} ({{ item.process_short_code }})</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.oriznal_weight ? $h.myFloat(item.oriznal_weight) + ' Ct' : '-' }}</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) + ' Ct' : '-' }} <br> {{ item.receive_weight ? $h.myFloat(item.receive_weight) + ' Ct' : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.lose_weight ? $h.myFloat(item.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.assortment_weight ? $h.myFloat(item.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ item.emp_majuri_rate ? $h.myFloat(item.emp_majuri_rate) : '-' }} <br> {{ item.employee_majuri ? $h.myFloat(item.employee_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ item.man_majuri_rate ? $h.myFloat(item.man_majuri_rate) : '-' }} <br> {{ item.manager_majuri ? $h.myFloat(item.manager_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ item.bonus_percent ? item.bonus_percent : '-' }} <br> {{ item.emp_bonus_value ? $h.myFloat(item.emp_bonus_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ item.employee_pb ? item.employee_pb + ' ' +(item.penalty_type == 2 ? '%': '') : '-' }} <br> {{ item.emp_penalty_value ? $h.myFloat(item.emp_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ item.manager_pb ? item.manager_pb + ' ' +(item.penalty_type == 2 ? '%': '') : '-' }} <br> {{ item.man_penalty_value ? $h.myFloat(item.man_penalty_value) : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ item.man_majuri_rate ? $h.myFloat(item.man_majuri_rate) : '-' }}</td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ item.employee_majuri_total ? $h.myFloat(item.employee_majuri_total) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ item.manager_majuri_total ? $h.myFloat(item.manager_majuri_total) : '-' }}</td>
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ main_item.total_packets ? main_item.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '1'"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ main_item.issue_weight ? $h.myFloat(main_item.issue_weight) + ' Ct' : '-' }} <br> {{ main_item.receive_weight ? $h.myFloat(main_item.receive_weight) + ' Ct' : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ main_item.lose_weight ? $h.myFloat(main_item.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ main_item.assortment_weight ? $h.myFloat(main_item.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ main_item.employee_majuri ? $h.myFloat(main_item.employee_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ main_item.manager_majuri ? $h.myFloat(main_item.manager_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ main_item.emp_bonus_value ? $h.myFloat(main_item.emp_bonus_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ main_item.emp_penalty_value ? $h.myFloat(main_item.emp_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ main_item.man_penalty_value ? $h.myFloat(main_item.man_penalty_value) : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ main_item.employee_majuri_total ? $h.myFloat(main_item.employee_majuri_total) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ main_item.manager_majuri_total ? $h.myFloat(main_item.manager_majuri_total) : '-' }}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '1'"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }} <br> {{ page_data.total.receive_weight ? $h.myFloat(page_data.total.receive_weight) + ' Ct' : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.lose_weight ? $h.myFloat(page_data.total.lose_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.assortment_weight ? $h.myFloat(page_data.total.assortment_weight) + ' Ct' : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri ? $h.myFloat(page_data.total.employee_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri ? $h.myFloat(page_data.total.manager_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_bonus_value ? $h.myFloat(page_data.total.emp_bonus_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.emp_penalty_value ? $h.myFloat(page_data.total.emp_penalty_value) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.man_penalty_value ? $h.myFloat(page_data.total.man_penalty_value) : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'"></td> -->
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '3'">{{ page_data.total.employee_majuri_total ? $h.myFloat(page_data.total.employee_majuri_total) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type != '4'">{{ page_data.total.manager_majuri_total ? $h.myFloat(page_data.total.manager_majuri_total) : '-' }}</td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
    </div>
      <!-- END: HTML Table Data -->

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';

export default defineComponent({
  setup() {
    const page_data = reactive({
      total : [],
      items : [],
      parties : [],
      lot_items : [],
      managers : [],
      processes : [],
      departments : [],
      employees : [],
      jangad_items : [],
      loading : false
    })
    const filter = reactive({
      start_date : moment().startOf('month').format('YYYY-MM-DD'),
      end_date : moment().endOf('month').format('YYYY-MM-DD'),
      packet_no : '',
      party_id : '0',
      kapan_id : '0',
      jangad_no : [],
      process_id : '0',
      department_id : '0',
      manager_id : '0',
      employee_id : '0',
      employee_type : '0',
      status : '0',
      report_type : '0',
      in_details : false,
      issue_pending : false,
      type : '0',
    })
    const api_filter = reactive({
      start_date : '',
      end_date : '',
      packet_no : '',
      party_id : '0',
      kapan_id : '0',
      jangad_no : [],
      process_id : '0',
      department_id : '0',
      manager_id : '0',
      employee_id : '0',
      employee_type : '0',
      status : '0',
      report_type : '0',
      in_details : false,
      issue_pending : false,
      type : '0',
    })
    const getReportDetails = () => {
      page_data.loading = true
      api_filter.start_date = filter.start_date
      api_filter.end_date = filter.end_date
      api_filter.packet_no = filter.packet_no
      api_filter.party_id = filter.party_id
      api_filter.kapan_id = filter.kapan_id
      api_filter.jangad_no = filter.jangad_no
      api_filter.process_id = filter.process_id
      api_filter.department_id = filter.department_id
      api_filter.manager_id = filter.manager_id
      api_filter.employee_id = filter.employee_id
      api_filter.employee_type = filter.employee_type
      api_filter.status = filter.status
      api_filter.report_type = filter.report_type
      api_filter.in_details = filter.in_details
      api_filter.issue_pending = filter.issue_pending
      api_filter.type = filter.type

      let params = {
        start_date : api_filter.start_date,
        end_date : api_filter.end_date,
        issue_pending : api_filter.issue_pending,
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id) ,
        kapan_id : api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id) ,
        jangad_no : api_filter.jangad_no.length == 0 ? '' : api_filter.jangad_no ,
        process_id : api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id) ,
        department_id : api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id) ,
        manager_id : api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id) ,
        employee_id : api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id) ,
        employee_type : api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type) ,
        status : api_filter.status == '0' ? '' : window.btoa(api_filter.status) ,
        type : api_filter.type == '0' ? '' : window.btoa(api_filter.type),
      }

      let api_url = ''

      if(api_filter.in_details == true) {
        if(api_filter.report_type == '0') {
          api_url = 'api/report/costing_process_report_details_by_kapan'
        } else if(api_filter.report_type == '1') {
          api_url = 'api/report/costing_process_report_details_by_process'

        } else if(api_filter.report_type == '2') {
          api_url = 'api/report/costing_process_report_details_by_department'

        } else if(api_filter.report_type == '3') {
          api_url = 'api/report/costing_process_report_details_by_manager'

        }else if(api_filter.report_type == '4') {
          api_url = 'api/report/costing_process_report_details_by_employee'

        }
      } else {
        if(api_filter.report_type == '0') {
          api_url = 'api/report/costing_process_report_by_kapan'
        } else if(api_filter.report_type == '1') {
          api_url = 'api/report/costing_process_report_by_process'
        } else if(api_filter.report_type == '2') {
          api_url = 'api/report/costing_process_report_by_department'
        } else if(api_filter.report_type == '3') {
          api_url = 'api/report/costing_process_report_by_manager'
        }else if(api_filter.report_type == '4') {
          api_url = 'api/report/costing_process_report_by_employee'
        }
      }

      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.items = data.items
          page_data.total = data.total
          page_data.loading = false
        })
        .catch(_error => {
          page_data.items = []
          page_data.total = []
          page_data.loading = false
        });
    }
    const getPartyDetails = () => {

        let params = {
          // department_id : window.btoa(filter.department_id)
        }
        let api_url = 'api/master/dp_parties'
        let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            page_data.parties = data.items
          })
          .catch(_error => {
            page_data.parties = []
          });
    }
    const getDepartmentDetails = () => {
      let params = {
        // department_id : window.btoa(filter.department_id)
      }
      let api_url = 'api/master/dp_department'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.departments = data.items
        })
        .catch(_error => {
          page_data.departments = []
        });
    }
    const getManagerDetails = () => {
      let params = {
        department_id : filter.department_id == '0' ? '' : window.btoa(filter.department_id),
        process_id : filter.process_id == '0' ? '' : window.btoa(filter.process_id)
      }
      let api_url = ''

      if(filter.department_id == '0' && filter.process_id == '0') {

        api_url = 'api/master/dp_managers'
      } else {
        api_url = 'api/master/get_manager_by_department'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.manager_id = '0'
          page_data.managers = data.items
        })
        .catch(_error => {
          filter.manager_id = '0'
          page_data.managers = []
        });
      }
    const getProcessDetails = () => {
      let params = {
        id : window.btoa(filter.manager_id)
      }
      let api_url = ''

      if(filter.manager_id == '0') {

        api_url = 'api/master/dp_process'
      } else {
        api_url = 'api/master/dp_process'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.process_id = '0'
          page_data.processes = data.items
        })
        .catch(_error => {
          filter.process_id = '0'
          page_data.processes = []
        });
    }
    const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = '0'
                    page_data.lot_items = []
                });
        }
    const getJangadDetails = async () => {
        let params = {
        }
        let api_url = 'api/report/rtp_jangad_list'

        let promise = axios({
            url: api_url,
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
                filter.jangad_no = []
                page_data.jangad_items = data.items
            })
            .catch(_error => {
                filter.jangad_no = []
                page_data.jangad_items = []
            });
    }
    const getEmployeeDetails = () => {

      let params = {
        manager_id : filter.manager_id == '0' ? '' : window.btoa(filter.manager_id),
        process_id : filter.process_id == '0' ? '' : window.btoa(filter.process_id),
        employee_type : filter.employee_type == '0' ? '': window.btoa(filter.employee_type),
      }
      let api_url = ''

      if(filter.department_id == '0') {

        api_url = 'api/master/get_employees_by_manager_employee_type'
      } else {
        api_url = 'api/master/get_employees_by_manager_employee_type'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.employee_id = '0'
          page_data.employees = data.items
        })
        .catch(_error => {
          filter.employee_id = '0'
          page_data.employees = []
        });
    }
    function onResetFilter() {
      filter.start_date = moment().startOf('month').format('YYYY-MM-DD')
      filter.end_date = moment().endOf('month').format('YYYY-MM-DD')
      filter.packet_no = ''
      filter.party_id = '0'
      filter.kapan_id = '0'
      filter.jangad_no = []
      filter.process_id = '0'
      filter.department_id = '0'
      filter.manager_id = '0'
      filter.employee_id = '0'
      filter.employee_type = '0'
      filter.status = '0'
      filter.report_type = '0'
      filter.in_details = false
      filter.issue_pending = false
      filter.type = '0'
    }
    function generateReport() {
      getReportDetails()
    }
    function getProcessChange() {
      getManagerDetails()
    }
    onMounted(() => {
      getLotDetails()
      getPartyDetails()
      getDepartmentDetails()
      getManagerDetails()
      getProcessDetails()
      generateReport()
      getJangadDetails()
    })
    return {
      page_data,
      filter,api_filter,generateReport,
      getProcessDetails,
      getManagerDetails,
      onResetFilter,
      getEmployeeDetails,
      getProcessChange
    }
  }
})
</script>

<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 630px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>
