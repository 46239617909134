<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Size Wise - Group Summary</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0"></div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <span tabindex="1"></span>
      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="">
            <label for="update-profile-form-8" class="form-label py-1"
              >Report Type</label
            >
            <select
              id="select-type"
              ref="selectType"
              tabindex="2"
              class="form-select"
              v-model="filter.report_type"
            >
              <option value="1">Planning Stock</option>
              <option value="2">Polish Stock</option
              >>
            </select>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="">
            <label for="update-profile-form-8" class="form-label py-1"
              >Data Type</label
            >
            <select
              id="select-type"
              ref="selectType"
              tabindex="2"
              class="form-select"
              v-model="filter.data_type"
            >
              <option value="1">Current Pieces</option>
              <option value="2">Current Weight</option>
              <option value="3">Pieces + Weight</option
              >>
            </select>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4 md:col-span-3">
          <div class="">
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Color</label
            >
            <TomSelect
              v-model="filter.color_ids"
              :options="{
                placeholder: 'Select Color',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Color'
                  }
                }
              }"
              multiple
              class="w-full"
            >
              <option value="">Select Color</option>
              <option
                v-for="item in page_data.color_list"
                :key="item.id"
                :value="item.id"
                >{{ item.color_name }}</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-4 md:col-span-3">
          <div class="">
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Purity</label
            >
            <TomSelect
              v-model="filter.purity_ids"
              :options="{
                placeholder: 'Select Purity',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Purity'
                  }
                }
              }"
              multiple
              class="w-full"
            >
              <option value="">Select Purity</option>
              <option
                v-for="item in page_data.purity_list"
                :key="item.id"
                :value="item.id"
                >{{ item.purity_name }}</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="">
            <label for="update-profile-form-6" class="form-label py-1"
              >Stone Type</label
            >
            <TomSelect
              v-model="filter.stone_type"
              :options="{
                placeholder: 'Stone  Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Type'
                  }
                }
              }"
              multiple
              class="w-full"
            >
              <option value="">Select Type</option>
              <option
                v-for="item in page_data.stone_list"
                :key="item.id"
                :value="item.id"
                >{{ item.stone_name }}</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-4 md:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Lab</label
            >
            <TomSelect
              v-model="filter.lab"
              :options="{
                placeholder: 'Select Lab',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Lab'
                  }
                }
              }"
              class="w-full"
            >
              <option value="all">All</option>
              <option value="1">Certified</option>
              <option value="2">Non-Certified</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-4 md:col-span-3">
          <div class="">
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Departments</label
            >
            <TomSelect
              v-model="filter.department_ids"
              :options="{
                placeholder: 'Select Department',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Department'
                  }
                }
              }"
              multiple
              class="w-full"
            >
              <option value="">Select Color</option>
              <option
                v-for="item in page_data.department_list"
                :key="item.id"
                :value="item.id"
                >{{ item.department_name }}</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-4 md:col-span-3">
          <div class="mt-9">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20 mx-2"
              tabindex="5"
              @click="generateReport()"
            >
              Generate
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1 mx-2"
              tabindex="6"
              @click="onResetFilter"
            >
              Reset
            </button>
            <button
            v-if="
            page_data.generate == 'gen' && page_data.charni_header.length > 0
          "
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20 ml-4 mx-2"
              @click="exportReportDetails"
            >
              Export
            </button>
          </div>
        </div>
      </div>

      <div id="responsive-table" class="p-5" v-if="loading">
        <div class="preview">
          <div class="overflow-x-auto">
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Latest Price</div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          class="overflow-x-auto scrollbar-hidden"
          v-if="page_data.generate == 'gen'"
        >
          <div id="generate-pdf" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"></th>
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        :colspan="export_filter.data_type == 3 ? (page_data.charni_header_2.length - 1) : (page_data.charni_header.length - 1)"
                      >
                        Size
                      </th>
                    </tr>
                    <tr>
                      <th :colspan="((index > 1 && export_filter.data_type == 3)  ? 2 : 1)"
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        v-for="(record, index) in page_data.charni_header"
                      >
                        {{record}}
                      </th>
                    </tr>
                    <tr v-if="export_filter.data_type == 3">
                      <th
                        class="border-t-2 border-b-2 border-l-2 border-r-2 text-center bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                        v-for="(record, index) in page_data.charni_header_2"
                      >
                        {{record}}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="record in page_data.return_data" :key="record" class="hover:bg-gray-200">
                      <td
                        class="border-b border-l border-r text-center whitespace-nowrap"
                        v-for="(value, index) in record"
                        :key="value"
                      >
                        <span class="">{{ value }}</span>
                        <!-- <span v-else>{{value > 0 ? $h.myFloat(value) : '0'}}</span> -->
                      </td>
                    </tr>
                    <tr
                      class="bg-gray-200  dark:border-dark-5 whitespace-nowrap"
                    >
                      <td
                        class="border-b border-l border-r text-center whitespace-nowrap font-bold"
                        v-for="(record, index) in page_data.footer_arr"
                        :key="index"
                      >
                        <span class="font-bold">{{ record }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, inject, ref, reactive, onMounted } from 'vue'
import { BASE_URL } from '@/constants/config.js'
import FileDownload from 'js-file-download'

export default defineComponent({
  setup() {
    const loading = ref(false)
    const swal = inject('$swal')
    const filter = reactive({
      report_type: 1,
      data_type: 1,
      color_ids: [],
      stone_type: [],
      purity_ids: [],
      lab: 'all',
      department_ids: []
    })
    const export_filter = reactive({
      report_type: 1,
      data_type: 1,
      color_ids: [],
      stone_type: [],
      purity_ids: [],
      lab: 'all',
      department_ids: []
    })
    const page_data = reactive({
      generate: 'no',
      color_list: [],
      stone_list: [],
      purity_list: [],
      department_list: [],
      charni_header: [],
      charni_header_2: [],
      footer_arr: [],
      return_data: []
    })

    const getParameters = () => {
      getColorList()
      getPurityList()
      getStoneList()
      getDepartmentList()
    }

    // Get Color List
    const getColorList = () => {
      let _this = this
      const params = {}
      let promise = axios({
        url: 'api/master/dp_color',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.color_list = data.items
        })
        .catch(_error => {
          page_data.color_list = []
        })
    }
    // Get Purity List
    const getPurityList = () => {
      let _this = this
      const params = {}
      let promise = axios({
        url: 'api/master/dp_purity',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.purity_list = data.items
        })
        .catch(_error => {
          page_data.purity_list = []
        })
    }
    // Get Purity List
    const getStoneList = () => {
      let _this = this
      const params = {}
      let promise = axios({
        url: 'api/master/dp_stone',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.stone_list = data.items
        })
        .catch(_error => {
          page_data.stone_list = []
        })
    }
    // Get Department List
    const getDepartmentList = () => {
      let _this = this
      const params = {}
      let promise = axios({
        url: 'api/master/dp_department',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.department_list = data.items
        })
        .catch(_error => {
          page_data.department_list = []
        })
    }

    // Generate Report
    const generateReport = () => {
      export_filter.report_type = filter.report_type
      export_filter.data_type = filter.data_type
      export_filter.color_ids = (filter.color_ids.length == 0 ? "" : filter.color_ids)
      export_filter.purity_ids = (filter.purity_ids.length == 0 ? "" : filter.purity_ids)
      export_filter.stone_type = (filter.stone_type.length == 0 ? "" : filter.stone_type)
      export_filter.lab = filter.lab
      export_filter.department_ids = (filter.department_ids.length == 0 ? "" : filter.department_ids)

      loading.value = true
      page_data.generate = 'no'

      page_data.charni_header = []
      page_data.charni_header_2 = []
      page_data.footer_arr = []
      page_data.return_data = []

      let promise = axios({
        url: 'api/report/charni-report',
        method: 'POST',
        data: export_filter,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          loading.value = false
          if (data.status == false) {
            swal('Warning', data.message, 'warning')

            page_data.charni_header = []
            page_data.charni_header_2 = []
            page_data.footer_arr = []
            page_data.return_data = []
          } else {
            page_data.generate = 'gen'
            page_data.charni_header = data.charni_header
            page_data.charni_header_2 = data.charni_header_2
            page_data.footer_arr = data.footer_arr
            page_data.return_data = data.return_data
          }
        })
        .catch(_error => {
          loading.value = false
          page_data.generate = 'no'
        })
    }

    function exportReportDetails() {
      const today = new Date()
      const date =
        today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear()
      // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date
      let filename = 'SIZE_WISE_STOCK_' + dateTime + '.xlsx'

      let promise = axios({
        url: '/api/report/charni-report-export',
        method: 'post',
        data: {
          charni_header: page_data.charni_header,
          charni_header_2: page_data.charni_header_2,
          footer_arr: page_data.footer_arr,
          return_data: page_data.return_data,
          export_filter: export_filter,
        },
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        },
        responseType: 'blob'
      })

      return promise
        .then(result => result.data)
        .then(data => {
          FileDownload(data, filename)
        })
        .catch(_error => {
          return []
        })
    }

    const onResetFilter = () => {
      filter.report_type = 1
      filter.data_type = 1
      filter.color_ids = []
      filter.purity_ids = []
      filter.stone_type = []
      filter.lab = 'all'
      filter.department_ids = []
    }

    onMounted(() => {
      getParameters()
    })

    return {
      loading,
      filter,
      export_filter,
      page_data,
      generateReport,
      exportReportDetails,
      onResetFilter
    }
  }
})
</script>
