<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">

          <div class="p-5 border-b">
            <h2 class="text-lg font-medium mr-auto">Packet Receive</h2>
            <div class="grid grid-cols-12 gap-x-5 align-bottom">
              <div class="col-span-12 sm:col-span-3 md:col-span-3 xl:col-span-2">

                <div class="">
                  <div>
                    <div v-if="rowData.value.length > 0">

                      <TomSelect v-model="process.type" :options="{
                        placeholder: 'Select Packet Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Packet Type'
                          }
                        }
                      }" disabled class="w-full">
                        <option value="makeable">Makeable</option>
                        <option value="rough">Rough</option>
                        <!-- <option value="video">Video</option> -->
                      </TomSelect>
                    </div>
                    <div v-else>

                      <TomSelect v-model="process.type" :options="{
                        placeholder: 'Select Packet Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Packet Type'
                          }
                        }
                      }" class="w-full">
                        <option value="makeable">Makeable</option>
                        <option value="rough">Rough</option>
                        <!-- <option value="video">Video</option> -->
                      </TomSelect>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-6 md:col-span-5 xl:col-span-3">

                <div class="">
                  <div class="input-group">

                    <input id="scan_lot_no" type="text" tabindex="1" placeholder="Scan Barcode" @input="correctString()"
                      v-model="screenData.scan_lot_no" :class="{ 'form-control': true, 'loading': screenData.scanload }"
                      autocomplete="off" @change="scanAndAddPack" />

                    <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack"
                      style="border-radius: 0!important">
                      <SearchIcon class="w-5 h-5 mr-2" /> Search
                    </button>
                  </div>
                </div>

              </div>
              <div class="col-span-12 sm:col-span-3 md:col-span-4 xl:col-span-7">

                <div class="w-full flex justify-end  align-bottom mt-4 sm:mt-0">
                  <button class="btn btn-primary rounded-none shadow-md mr-2" @click="issue_to_manager"
                    :disabled="screenData.return_progress">
                    Receive Packets
                    <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress" />
                  </button>
                  <button class="btn btn-primary rounded-none shadow-md mr-2" @click="openMeasurementModal">
                    Add Measurements
                  </button>
                </div>

              </div>

            </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12">
              <div class="intro-y col-span-12 lg:col-span-12">
                <!-- BEGIN: Input -->
                <div class="">
                  <div class="grid grid-cols-12 gap-x-5">

                    <div class="col-span-4 xl:col-span-4">

                      <div>
                        <label for="update-profile-form-6" class="form-label font-bold">
                          Total Scanned Pcs. :&nbsp;</label>
                        <label for="update-profile-form-6" class="form-label">{{ screenData.total_scan_pack }} </label>
                      </div>

                    </div>
                    <div class="col-span-4 xl:col-span-4">
                      <div>
                        <label for="update-profile-form-6" class="form-label font-bold">
                          Total Pcs Weight. :&nbsp;</label>
                        <label for="update-profile-form-6" class="form-label">{{ $h.myFloat(screenData.total_scan_pack_wt)
                        }} </label>
                      </div>
                    </div>
                    <div class="col-span-4 xl:col-span-4 text-right">
                      <div>
                        <button class="btn btn-sm btn-primary rounded-none shadow-md mr-2 mb-2"
                          @click="removeGridRowSelect" :disabled="screenData.delet_row">
                          Remove Selected Row
                        </button>
                      </div>
                    </div>
                    <div class="col-span-12 ">
                      <ag-grid-vue class="ag-theme-alpine" style="height: 500px" :columnDefs="columnDefs.value"
                        :rowData="rowData.value" :defaultColDef="defaultColDef" rowSelection="multiple"
                        :rowMultiSelectWithClick="true" animateRows="true" @grid-ready="onGridReady"
                        @selection-changed="onGridRowSelect" :overlayNoRowsTemplate="overlayNoRowsTemplate">
                      </ag-grid-vue>
                    </div>

                  </div>
                </div>
                <!-- END: Input -->
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <div id="receive-items-modal" ref="receive-items-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Receive Details</h2>
          </div>
          <div class="modal-body">


            <table class="w-full border">
              <thead>
                <tr>
                  <th class="text-center">No</th>

                  <th class="text-center">Packet No</th>
                  <th class="text-center">Stock No</th>
                  <th class="text-center">Process</th>
                  <th class="text-center">Issue Weight</th>
                  <th class="text-center">Receive Weight</th>
                  <th class="text-center">Rejection Weight</th>
                  <th class="text-center">Net Rejection Weight</th>
                  <th class="text-center">Shape</th>
                  <th class="text-center">Color</th>
                  <th class="text-center">Purity</th>
                  <th class="text-center">Cut</th>
                  <th class="text-center">Polish</th>
                  <th class="text-center">Symmetry</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Message</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border" v-for="(plan, index) in screenData.receive_data" :key="index">
                  <td class="text-center">
                    <p>{{ index + 1 }}</p>
                  </td>
                  <td class="text-center">{{ (plan.packet_no ? plan.packet_no : '-') }}</td>
                  <td class="text-center">{{ (plan.vepari_no ? plan.vepari_no : '-') }}</td>
                  <td class="text-center">{{ (plan.process ? plan.process : '-') }}</td>
                  <td class="text-center">{{ (plan.issue_weight ? plan.issue_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.receive_weight ? plan.receive_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.rejection_weight ? plan.rejection_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.net_rejection_weight ? plan.net_rejection_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.shape ? plan.shape : '-') }}</td>
                  <td class="text-center">{{ (plan.color ? plan.color : '-') }}</td>
                  <td class="text-center">{{ (plan.purity ? plan.purity : '-') }}</td>
                  <td class="text-center">{{ (plan.cut ? plan.cut : '-') }}</td>
                  <td class="text-center">{{ (plan.polish ? plan.polish : '-') }}</td>
                  <td class="text-center">{{ (plan.symmetry ? plan.symmetry : '-') }}</td>
                  <td class="text-center"
                    :class="{ 'text-red': plan.status_color == 'secondary', 'text-green': plan.status_color == 'success' }">
                    {{ (plan.status ? plan.status : '-') }}</td>
                  <td class="text-center"
                    :class="{ 'text-red': plan.status_color == 'secondary', 'text-green': plan.status_color == 'success' }">
                    {{ (plan.message ? plan.message : '-') }}</td>
                </tr>
              </tbody>

            </table>

          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: new Process Modal -->
    <div id="new-process-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <div class="intro-y">
              <!-- <div id="vertical-form"> -->
              <div class="flex flex-col justify-between">
                <!-- BEGIN: Data List -->
                <div class="intro-y overflow-auto">
                  <div class="flex">
                  </div>
                </div>
                <div class="p-5">
                  <div>
                    <div class="text-left">Transaction Type</div>
                    <TomSelect v-model="process.transaction_type" :options="{
                      placeholder: 'Select Transaction Type'
                    }" class="w-full">
                      <option value="0">Process Assign</option>
                      <option value="1">Manager Transfer</option>
                      <option value="2">Direct Receive</option>
                    </TomSelect>
                  </div>
                  <div class="mt-3" v-if="process.transaction_type == '1'">
                    <div class="text-left">Select Manager</div>
                    <TomSelect v-model="process.manager_id" @change="validateManagerId" :options="{
                      placeholder: 'Select Manager'
                    }" class="w-full">
                      <option value="0">Select Manager</option>
                      <option v-for="(manager, ind) in page_data.managers" :key="ind" :value="manager.id">{{
                        manager.first_name + ' ' + manager.last_name }}</option>
                    </TomSelect>
                    <!-- <span class="text-primary-3 mb-2" v-if="errors.manager_id">{{ errors.manager_id[0] }}</span> -->
                  </div>
                  <div class="mt-3" v-if="process.transaction_type == '0'">
                    <div class="text-left">Select Process</div>
                    <TomSelect v-model="process.process_id" @change="getEmployees(process.process_id)" :options="{
                      placeholder: 'Select Process'
                    }" class="w-full">
                      <option value="0">Select Process</option>
                      <option v-for="(process, ind) in page_data.processes" :key="ind" :value="process.id">{{
                        process.process_name }}</option>
                    </TomSelect>
                    <!-- <span class="text-primary-3 mb-2" v-if="errors.process_id">{{ errors.process_id[0] }}</span> -->

                  </div>
                  <div class="flex items-center mt-3" v-if="process.process_id != '0' && process.transaction_type == '0'">
                    <div class="form-check">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
                        value="0" type="radio" name="singleinternal" id="singleinternal" v-model="process.eternals">
                      <label class="form-check-label inline-block text-black text-lg" for="singleinternal">
                        Internal Employee
                      </label>
                    </div>
                    <div class="form-check ml-6">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
                        value="1" type="radio" name="singleexternal" id="singleexternal" v-model="process.eternals">
                      <label class="form-check-label inline-block text-black text-lg" for="singleexternal">
                        Job Party
                      </label>
                    </div>
                  </div>
                  <div class="mt-3"
                    v-if="process.eternals == '0' && process.process_id != '0' && process.transaction_type == '0'">
                    <div class="text-left">Select Employee</div>
                    <TomSelect v-model="process.employee_id" :options="{
                      placeholder: 'Select Employee'
                    }" class="w-full">
                      <option value="0">Select Employee</option>
                      <option v-for="(emp, ind) in page_data.employees" :key="ind" :value="emp.id">{{ emp.first_name + ' '
                        + emp.last_name }}</option>
                    </TomSelect>
                    <!-- <span class="text-primary-3 mb-2" v-if="errors.employee_id">{{ errors.employee[0] }}</span> -->
                  </div>
                  <div class="mt-3"
                    v-else-if="process.eternals == '1' && process.process_id != '0' && process.transaction_type == '0'">
                    <div class="text-left">Select Job Party</div>
                    <TomSelect v-model="process.party_id" :options="{
                      placeholder: 'Select Party'
                    }" class="w-full">
                      <option value="0">Select Party</option>
                      <option v-for="(party, ind) in page_data.parties" :key="ind" :value="party.id">{{ party.firm_name }}
                      </option>
                    </TomSelect>
                    <!-- <span class="text-primary-3 mb-2" v-if="errors.party_id">{{ errors.party_id[0] }}</span> -->
                  </div>
                </div>
                <button type="button" @click="issueLabSubmit" :disabled="page_data.submit"
                  class="btn btn-primary w-24 m-auto mt-5">Submit
                  <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: new Process Modal -->
    <div id="upload-image-modal" class="modal" tabindex="-1" aria-hidden="true" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Upload Image</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Dropzone -->
                  <Dropzone
                ref-key="dropzoneSingleRef"
                :options="{
                  url: screenData.base_url + 'api/processUploadImage',
                   paramName: 'image',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  maxFiles: 1,
                  headers: { Authorization: 'Bearer ' + screenData.token },
                }"
                class="dropzone mb-4">
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  Upload file with latest standared rate with provided formate. (.csv, .xlsx, .xls)
                </div>
              </Dropzone>
                  <!-- END: Dropzone -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <div id="preview-image-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Preview Image</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Image Show -->
                  <img :src="screenData.url" alt="Preview Image" class="w-full h-auto">

                  <!-- <img src="http://127.0.0.1:8000/storage/images/saeTx4dgg4TeIcoGmLzB.jpg" alt="Preview Image" class="w-full h-auto"> -->

                  <!-- END: Image Show -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="upload-measurement-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Upload Measurements</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Dropzone -->
                  <div class="dropzone" id="stock-dropzone"></div>
                  <!-- END: Dropzone -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {
  defineComponent,
    ref,
    reactive,
    onMounted,
    inject,
    provide,
    computed
} from 'vue'
import {
  AgGridVue
} from "ag-grid-vue3";
import {
  useRouter,
  useRoute
} from 'vue-router'
import {
  BASE_URL
} from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import {
  stringify
} from 'json5';
import store from '@/store'
import { helper as $h } from '@/utils/helper';

export default defineComponent({
  components: {
    AgGridVue,
    ShapeRenderer
  },
  setup() {
    const stockDropzone = ref(null)
    const user_details = computed(() => store.state.user.currentUser)
    const gridApi = ref(null);
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })

    const page_data = reactive({
      managers: [],
      processes: [],
      employees: [],
      parties: [],
    })

    const process = reactive({
      eternals: '0',
      manager_id: '0',
      process_id: '0',
      employee_id: '0',
      party_id: '0',
      transaction_type: '2',
      type: 'rough'
    })

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }
    const screenData = reactive({
      base_url : BASE_URL,
      scanload: false,
      modalId: 0,
      packet_details: [],
      url: "",
      color_arr: [],
      receive_data: [],
      purity_arr: [],
      shape_arr: [],
      polish_arr: [],
      symmetry_arr: [],
      fluerocent_arr: [],
      tension_arr: [],
      cut_arr: [],

      return_progress: false,
      scan_lot_no: '',
      lab_type: '',
      total_scan_pack_wt: 0,
      selected_party: '',
      total_scan_pack: 0

    })

    const dropzoneSingleRef = ref()
        provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })
    const onGridReady = (params) => {
      gridApi.value = params.api;
    };

    const rowData = reactive({
      value: []
    });

    const overlayNoRowsTemplate = 'Scan packets for Lab Issue!'

    const columnDefs = reactive({
      value: [{
        headerName: "#",
        field: 'sr_no',
        maxWidth: 60,
      },
      {
        headerName: "Packet No",
        field: 'lot_no',
        minWidth: 140
      },
      {
        headerName: "Stock No",
        field: 'stock_no',
        minWidth: 140
      },
      {
        headerName: "Quality",
        field: 'quality',
        minWidth: 100
      },
      {
        headerName: "Issue Wt (Ct)",
        field: 'issue_weight',
        editable: false,
        minWidth: 140,
      },
      {
        headerName: "Receive Wt (Ct)",
        field: 'receive_weight',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.color_name = ''
            swal('Warning!', "Weight Can't be Empty!", "warning")
            return false;
          } else {
            if ($h.myFloat(params.newValue) > 0) {

            } else {
              swal('Warning!', "Invalid Receive Weight" + " Row!", "warning")
              return false;

            }
            console.log(params.data.cr_weight)
            console.log(params.newValue)
            if (parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
              swal('Warning!', "Receive Weight Must be less then Issue Weight" + " Row!", "warning")
              return false;

            }

            let total_value = parseFloat(params.newValue) + parseFloat(params.data.assort_weight)

            if (total_value > params.data.cr_weight) {

              swal('Warning!', "Assort Weight Must be less then Issue Weight" + " Row!", "warning")
              return false;
            }
            params.data.receive_weight = params.newValue;

            // swal('Warning!', "Invalid Color!", "warning")
            // setTimeout(focusScan, 50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Rejection Wt (Ct)",
        field: 'assort_weight',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.color_name = ''
            // swal('Warning!', "Weight Can't be Empty!", "warning")
            return false;
          } else {
            if ($h.myFloat(params.newValue) || params.newValue == 0) {

            } else {
              swal('Warning!', "Invalid Assort Weight" + " Row!", "warning")
              return false;

            }
            if (parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
              swal('Warning!', "Assort Weight Must be less then Issue Weight" + " Row!", "warning")
              return false;

            }

            let total_value = parseFloat(params.newValue) + parseFloat(params.data.receive_weight)

            if (total_value > params.data.cr_weight) {

              swal('Warning!', "Assort Weight Must be less then Issue Weight" + " Row!", "warning")
              return false;
            }
            params.data.assort_weight = params.newValue;

            // swal('Warning!', "Invalid Color!", "warning")
            // setTimeout(focusScan, 50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Net Rejection Wt",
        field: 'net_rejection_weight',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.color_name = ''
            // swal('Warning!', "Weight Can't be Empty!", "warning")
            return false;
          } else {
            if ($h.myFloat(params.newValue) || params.newValue == 0) {

            } else {
              swal('Warning!', "Invalid Net Rejection Weight" + " Row!", "warning")
              return false;

            }
            if (parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
              swal('Warning!', "Net Rejection Weight Must be less then Issue Weight" + " Row!", "warning")
              return false;

            }

            let total_value = parseFloat(params.newValue) + parseFloat(params.data.receive_weight)

            if (total_value > params.data.cr_weight) {

              swal('Warning!', "Net Rejection Weight Must be less then Issue Weight" + " Row!", "warning")
              return false;
            }
            params.data.net_rejection_weight = params.newValue;

            // swal('Warning!', "Invalid Color!", "warning")
            // setTimeout(focusScan, 50);
            // return valueChanged;

          }
        },
      },
      {headerName: "Image Upload",field: 'image',minWidth: 140,field: 'receive_image',
          cellRenderer: function(params) {
            var button = document.createElement('button');
            button.type = "button";
            button.id = params.node.data.lot_no + '_for_p';
            button.innerText = "Upload Image";
            button.style.padding = "0px 0px";
            button.style.border = "1px solid #ccc";
            button.style.backgroundColor = "#ffffff00";
            button.addEventListener('click', function (event) {
              params.node.data.receive_image = params.value;
              cash('#upload-image-modal').modal('show')
              screenData.modalId = params.data.id;
            });
            return button;
          }
        },
        {headerName: "Image Preview",field: 'image',minWidth: 140,
          cellRenderer: function(params) {
            var button = document.createElement('button');
            button.type = "button";
            button.id = params.node.data.lot_no + '_for_p';
            button.innerText = "Image Preview";
            button.style.padding = "0px 0px";
            button.style.border = "1px solid #ccc";
            button.style.backgroundColor = "#ffffff00";
            button.addEventListener('click', function (event) {
              cash('#preview-image-modal').modal('show')
              screenData.url = params.data.url;
              });
            return button;
          }
        },
      {
        headerName: "Comment",
        field: 'comment',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.color_name = ''
            // swal('Warning!', "Weight Can't be Empty!", "warning")
            return false;
          } else {
            //   if($h.myFloat(params.newValue) || params.newValue == 0) {

            //   } else {
            //     swal('Warning!',"Invalid Net Rejection Weight" +" Row!","warning")
            //     return false;

            //   }
            //   if(parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
            //     swal('Warning!',"Net Rejection Weight Must be less then Issue Weight" +" Row!","warning")
            //     return false;

            //   }

            //   let total_value = parseFloat(params.newValue) + parseFloat(params.data.receive_weight)

            //   if(total_value > params.data.cr_weight) {

            //     swal('Warning!',"Net Rejection Weight Must be less then Issue Weight" +" Row!","warning")
            //     return false;
            // }
            params.data.comment = params.newValue;

            // swal('Warning!', "Invalid Color!", "warning")
            setTimeout(focusScan, 50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "4P",
        field: "is_for_p",
        minWidth: 100,
        cellRenderer: function (params) {
          var input = document.createElement('input');
          input.type = "checkbox";
          input.id = params.node.data.lot_no + '_for_p';
          input.checked = params.value;
          input.addEventListener('click', function (event) {
            params.value = !params.value;
            params.node.data.is_for_p = params.value;

            document.getElementById(params.node.data.lot_no + '_semi_polish').checked = false
            params.node.data.is_semi_polish = false
          });
          return input;
        }
      },
      {
        headerName: "Semi Polish",
        field: "is_semi_polish",
        minWidth: 150,
        cellRenderer: function (params) {
          var input = document.createElement('input');
          input.type = "checkbox";
          input.id = params.node.data.lot_no + '_semi_polish';
          input.checked = params.value;
          input.addEventListener('click', function (event) {
            params.value = !params.value;
            params.node.data.is_semi_polish = params.value;


            document.getElementById(params.node.data.lot_no + '_for_p').checked = false
            params.node.data.is_for_p = false
          });
          return input;
        }
      },
      {
        headerName: "Polish Wt (Ct)",
        field: 'cr_polish_weight',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.color_name = ''
            // swal('Warning!', "Weight Can't be Empty!", "warning")
            return false;
          } else {
            if ($h.myFloat(params.newValue) || params.newValue == 0) {

            } else {
              swal('Warning!', "Invalid Polish Weight" + " Row!", "warning")
              return false;

            }
            if (parseFloat(params.newValue) > parseFloat(params.data.receive_weight)) {
              swal('Warning!', "Polish Weight Must be less then Receive Weight" + " Row!", "warning")
              return false;

            }
          }
          params.data.cr_polish_weight = params.newValue;

          // swal('Warning!', "Invalid Color!", "warning")
          // setTimeout(focusScan, 50);
          // return valueChanged;

        }
      },
      {
        headerName: "Process",
        field: 'process_name',
        minWidth: 140
      },
      {
        headerName: "Shape",
        field: 'shape_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.shape_name = ''
            swal('Warning!', "Shape Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.shape_arr.includes(params.newValue)) {

              params.data.shape_name = params.newValue;
              return;
            } else if (params.data.shape_name == params.newValue) {

            } else {

              swal('Warning!', "Invalid Shape!", "warning")
            }
            setTimeout(focusScan, 50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Color",
        field: 'color_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.color_name = ''
            swal('Warning!', "Color Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.color_arr.includes(params.newValue)) {

              params.data.color_name = params.newValue;
              return;
            } else if (params.data.color_name == params.newValue) {

            } else {
              swal('Warning!', "Invalid Color!", "warning")
            }
            // setTimeout(focusScan,50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Purity",
        field: 'purity_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.purity_name = ''
            swal('Warning!', "Purity Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.purity_arr.includes(params.newValue)) {

              params.data.purity_name = params.newValue;
              return;
            } else if (params.data.purity_name == params.newValue) {

            } else {
              swal('Warning!', "Invalid Purity!", "warning")
            }
            // setTimeout(focusScan,50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Cut",
        field: 'cut_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.purity_name = ''
            swal('Warning!', "Cut Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.cut_arr.includes(params.newValue)) {

              params.data.cut_name = params.newValue;
              return;
            } else if (params.data.cut_name == params.newValue) {

            } else {
              swal('Warning!', "Invalid Cut!", "warning")
            }
            // setTimeout(focusScan,50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Polish",
        field: 'polish_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.purity_name = ''
            swal('Warning!', "Polish Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.polish_arr.includes(params.newValue)) {

              params.data.polish_name = params.newValue;
              return;
            } else if (params.data.polish_name == params.newValue) {

            } else {
              swal('Warning!', "Invalid Polish!", "warning")
            }
            // setTimeout(focusScan,50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Symmetry",
        field: 'symmetry_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.purity_name = ''
            swal('Warning!', "Symmetry Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.symmetry_arr.includes(params.newValue)) {

              params.data.symmetry_name = params.newValue;
              return;
            } else if (params.data.symmetry_name == params.newValue) {

            } else {
              swal('Warning!', "Invalid Symmetry!", "warning")
            }
            // setTimeout(focusScan,50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Fluorescent",
        field: 'fluerocent_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.purity_name = ''
            swal('Warning!', "Fluorescent Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.fluerocent_arr.includes(params.newValue)) {

              params.data.fluerocent_name = params.newValue;
              return;
            }
            else if (params.data.fluerocent_name == params.newValue) {

            } else {
              swal('Warning!', "Invalid Fluorescent!", "warning")
            }
            // setTimeout(focusScan,50);
            // return valueChanged;

          }
        },
      },
      {
        headerName: "Tension",
        field: 'tension_name',
        editable: true,
        minWidth: 140,
        valueSetter: (params) => {
          if (params.newValue == '') {
            // params.data.purity_name = ''
            swal('Warning!', "Tension Can't be Empty!", "warning")
            return false;
          } else {

            if (screenData.tension_arr.includes(params.newValue)) {

              params.data.tension_name = params.newValue;
              return;
            }
            else if (params.data.tension_name == params.newValue) {

            } else {
              swal('Warning!', "Invalid Tension!", "warning")
            }
            // setTimeout(focusScan,50);
            // return valueChanged;

          }
        },
      },
      ],
    });

    const defaultColDef = {
      sortable: true,
      filter: true,
      flex: 1
    };
    const get_all_details = () => {
      let params = {

      }
      let promise = axios({
        url: "/api/master/get_all_parameters",
        method: "post",
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          data.color.forEach(element => {
            screenData.color_arr.push(element.color_name)
          });
          data.cut.forEach(element => {
            screenData.cut_arr.push(element.cut_name)
          });
          data.purity.forEach(element => {
            screenData.purity_arr.push(element.purity_name)
          });
          data.polish.forEach(element => {
            screenData.polish_arr.push(element.polish_name)
          });
          data.shape.forEach(element => {
            screenData.shape_arr.push(element.shape_name)
          });
          data.symmetry.forEach(element => {
            screenData.symmetry_arr.push(element.symmetry_name)
          });
          data.fluerocent.forEach(element => {
            screenData.fluerocent_arr.push(element.shape_name)
          });
          data.tension.forEach(element => {
            screenData.tension_arr.push(element.tension_name)
          });

          // screenData.shape_arr = data.items

        })
        .catch(_error => {
          screenData.color_arr = []
          screenData.purity_arr = []
          screenData.shape_arr = []
          screenData.polish_arr = []
          screenData.symmetry_arr = []
          screenData.fluerocent_arr = []
          screenData.tension_arr = []
          screenData.cut_arr = []

        });
    }

    //Edit user
    const scanAndAddPack = () => {
      let codelength = screenData.scan_lot_no.trim().length;
      if (codelength > 8) {
        screenData.scanload = true;
        //Check already in array
        var i = rowData.value.length;
        while (i--) {
          if (rowData.value[i]['barcode'] == screenData.scan_lot_no || rowData.value[i]['lot_no'] == screenData.scan_lot_no) {
            screenData.scan_lot_no = "";
            swal("Warning!", "Already in list, Scan other packet!", "warning");
            screenData.scanload = false;
            return;
          }
        }

        //get lot details
        const data = {
          barcode: screenData.scan_lot_no,
          process_type: window.btoa(process.type)
        };
        let url_post = '';
        if (screenData.scan_lot_no != '') {
          url_post = "/api/full-process/get_lab_scanned_packet_details_party"
        } else {
          url_post = "/api/full-process/get_lab_scanned_packet_details_party"
        }
        let promise = axios({
          url: url_post,
          method: "post",
          data: data,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
            headers: {
              Accept: "application/json"
            }
          },
        });
        return promise
          .then((result) => result.data)
          .then((data) => {
            console.log(data);
            if (data.packet_details == 'No') {
              swal("Warning!", data.error_msg, "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot_no"]').focus();
            } else if (data.packet_details == 'process') {
              swal("Warning!", "Scanned barcode is already in process", "warning");
              screenData.scanload = false;
              document.querySelector('[id="scan_lot_no"]').focus();
            } else {
              let pack_details = data.packet_details;

              // if(screenData.lab_type == '') {

              //   screenData.lab_type = pack_details.lab
              // }
              // console.log('Hii data');
              // let party_length = screenData.party_ar.length

              // for(let i = 0; i< party_length; i++) {
              //   if(screenData.party_ar[i].first_name == screenData.lab_type) {

              //     screenData.selected_party = stringify(screenData.party_ar[i].id)
              //     console.log(screenData.selected_party)
              //     break;
              //   }
              // }
              pack_details.forEach(element => {

                screenData.total_scan_pack = screenData.total_scan_pack + 1;
                console.log('Push data');
                rowData.value.push({
                  "id": element.id,
                  "sr_no": screenData.total_scan_pack,
                  "lot_no": element.number,
                  "quality": element.quality_name,
                  "receive_image": "",
                  "url": "",
                  "stock_no": element.vepari_no,
                  "cr_weight": element.cr_weight,
                  "cr_polish_weight": element.cr_polish_weight,
                  "shape_name": element.shape_name,
                  "color_name": element.color_name,
                  "purity_name": element.purity_name,
                  "cut_name": element.cut_name,
                  "polish_name": element.polish_name,
                  "purity_name": element.purity_name,
                  "symmetry_name": element.symmetry_name,
                  "fluerocent_name": element.fluerocent_name,
                  "tension_name": element.tension_name,
                  "barcode": element.barnum,
                  "packet_id": element.id,
                  "process_name": element.process_name,
                  "process_code": element.process_code,
                  "process_short_code": element.process_short_code,
                  "issue_weight": element.issue_weight,
                  "receive_weight": (element.receive_weight ? element.receive_weight : ''),
                  "assort_weight": (element.assort_weight ? element.assort_weight : ''),
                  "net_rejection_weight": (element.net_rejection_weight ? element.net_rejection_weight : ''),
                  "comment": '',
                  "is_for_p": false,
                  "is_semi_polish": false,
                  "process_id": element.process_id,
                });
                screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(element.issue_weight);
              });
              gridApi.value.setRowData(rowData.value);


              screenData.scan_lot_no = "";
              screenData.scanload = false;
              if (rowData.value.length == 1) {
                setTimeout(focusLstRow, 50);
              } else {
                focusLstRow();
              }
              console.log(rowData.value);
            }
          })
          .catch((_error) => {
            screenData.scanload = false;
            return [];
          });
      }
    }

    //       const focusLstRow = () => {
    //         gridApi.value.ensureIndexVisible(rowData.value.length - 1);

    //         if(rowData.value[rowData.value.length - 1]['receive_weight'] == '' || rowData.value[rowData.value.length - 1]['receive_weight'] == null) {

    //           gridApi.value.setFocusedCell(rowData.value.length - 1,'receive_weight');
    //         } else {
    //           setTimeout(focusScan,100);
    //         }
    //         }
    //         gridApi.value.startEditingCell(
    //           {
    //             rowIndex: rowData.value.length - 1,
    //             colKey: 'receive_weight',
    //           }
    // );
    //       }

    const focusLstRow = () => {
      gridApi.value.ensureIndexVisible(rowData.value.length - 1);
      gridApi.value.setFocusedCell(rowData.value.length - 1, 'receive_weight');
      gridApi.value.startEditingCell(
        {
          rowIndex: rowData.value.length - 1,
          colKey: 'receive_weight',
        }
      );
    }

    const onGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows().length);
      if (gridApi.value.getSelectedRows().length == 0) {
        screenData.delet_row = true;
      } else {
        screenData.delet_row = false;
      }
    }

    const issueLabSubmit = () => {

      if (rowData.value.length == 0) {
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }


      const data = {
        packet_details: rowData.value,
        externals: window.btoa(process.eternals),
        process_id: process.process_id == '0' ? '' : window.btoa(process.process_id),
        employee_id: process.employee_id == '0' ? '' : window.btoa(process.employee_id),
        manager_id: process.manager_id == '0' ? '' : window.btoa(process.manager_id),
        party_id: process.party_id == '0' ? '' : window.btoa(process.party_id),
        packet_type: process.type == '0' ? '' : window.btoa(process.type),
        transaction_type: window.btoa(process.transaction_type),
      };

      console.log(data);

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to Receive packets!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Receive",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/full-process/receive_full_process_rough_packets",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if (data.status == "success") {
                // swal("Success!", "Item Received.", "success");
                //screenData.return_progress = false;
                // router.go();
                screenData.receive_data = data.data
                cash('#receive-items-modal').modal('show')
                rowData.value = []
                screenData.lab_type = ''
                screenData.total_scan_pack_wt = 0
                screenData.total_scan_pack = 0

              } else if (data.status == "error") {
                screenData.receive_data = data.data
                cash('#receive-items-modal').modal('show')
                // rowData.value = []
                screenData.lab_type = ''
                screenData.total_scan_pack_wt = 0
                screenData.total_scan_pack = 0
                // swal("Error!", data.message, "error");
                // screenData.receive_data = []
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              swal("Warning!", _error.response.data.error, "warning");
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;

        }
      });
    }

    const removeGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows());
      const sel = gridApi.value.getSelectedRows();
      console.log(sel);

      let remove_rows = [];
      var i = sel.length;
      while (i--) {
        remove_rows.push(sel[i]['barcode']);
      }
      gridApi.value.applyTransaction({
        remove: sel
      });
      console.log('Removeing row');
      console.log(remove_rows);
      var j = rowData.value.length;
      while (j--) {
        if (remove_rows.includes(rowData.value[j]['barcode'])) {
          rowData.value.splice(j, 1);
        }
      }

      setTimeout(calculateTotal(), 50);
    }

    function calculateTotal() {
      screenData.total_scan_pack = rowData.value.length
      screenData.total_scan_pack_wt = 0;

      rowData.value.forEach(element => {
        screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(element.issue_weight);
      });
    }

    const getEmployees = () => {
      const params = {
        manager_id: window.btoa(user_details.value.account_id),
        process_id: window.btoa(process.process_id),
        process_type: window.btoa(process.type),
      }
      if (process.process_id != '0') {

        axios({
          url: 'api/master/get_employees_by_manager_process',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          page_data.employees = res.data.items
          process.employee_id = '0'
        })
        axios({
          url: 'api/master/get_jober_details_by_process',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          page_data.parties = res.data.items
          process.party_id = '0'
        })
      } else {
        page_data.employees = []
        page_data.parties = []
        process.party_id = '0'
        process.employee_id = '0'
      }
    }

    const getProcessData = () => {
      const params = {
        user_id: user_details.value.account_id,
        process_type: window.btoa(process.type)
      }
      axios({
        url: 'api/master/manager_processes',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        page_data.processes = res.data.items
        process.process_id = '0'
      })
    }

    const getAllManager = () => {
      axios({
        url: 'api/master/dp_managers',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        page_data.managers = res.data.items
        process.manager_id = '0'
      })
    }

    const issue_to_manager = () => {

      getProcessData()
      getAllManager()
      cash('#new-process-modal').modal('show')
    }

    const validateManagerId = () => {
            if (user_details.value.account_id == process.manager_id) {
                process.manager_id = '0'
                swal("Warning!", "Self Transfre is Not Allowed !", "warning");
            }
        }

    const focusScan = () => {

      document.querySelector('[id="scan_lot_no"]').focus();
    }

    onMounted(() => {
      document.querySelector('[id="scan_lot_no"]').focus();
      rowData.value = []
      get_all_details()

      const eldropzoneSingleRefF = dropzoneSingleRef.value
      eldropzoneSingleRefF.dropzone.on('success', (file, response) => {
          const packetIndex = screenData.modalId;

          for (let index = 0; index < rowData.value.length; index++) {
            if(rowData.value[index].id == screenData.modalId) {
              rowData.value[index].receive_image = response.path;
              rowData.value[index].url = response.path;
            }
          }
          cash('#upload-image-modal').modal('hide')
          Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Image Uploaded',
        });
            clearDropzone();
      })

      eldropzoneSingleRefF.dropzone.on('error', () => {
        Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No more files please!',
        });
      })

      stockDropzone.value = new Dropzone('#stock-dropzone', {
        url:
          `${BASE_URL}api/checkUploadMeasurementExcel`,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        maxFiles: 1,
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token')
        },
        init: function () {
          this.on('error', function (file, errorMessage) {
            this.removeFile(file)
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'No more files please!'
            })
          })

          this.on('success', function (file, response) {

            if(response.success == true) {

              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.message
              })
              this.removeFile(file)
            } else {
              this.removeFile(file)
              Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.message
            })
            }

          })
        }
      })
    })
    const correctString = () => {
                screenData.scan_lot_no = screenData.scan_lot_no.replace(/\n/g, ',');
            }
    const clearDropzone = () => {
      const eldropzoneSingleRefF = dropzoneSingleRef.value;
      eldropzoneSingleRefF.dropzone.removeAllFiles();
    };

    const openMeasurementModal = () => {
      cash('#upload-measurement-modal').modal('show')
    }

    return {
      clearDropzone,
      dropzoneSingleRef,
      filter,
      process,
      page_data,
      screenData,
      columnDefs,
      rowData,
      defaultColDef,
      overlayNoRowsTemplate,
      scanAndAddPack,
      onGridReady,
      issueLabSubmit,
      getRowId,
      removeGridRowSelect,
      onGridRowSelect,
      issue_to_manager,
      getEmployees,
      validateManagerId,
      correctString,
      stockDropzone,
      openMeasurementModal,
      deselectRows: () => {
        gridApi.value.deselectAll()
      }
    }

  }
})
</script>
