<template>
  <div>
    <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
      <div class="flex items-center justify-around" v-if="pagedata.success">
          <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
          <div class="font-medium text-black">{{ pagedata.success }}</div>
        </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Create Jober Rate</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-8">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div class="sm:grid grid-cols-2 gap-2">
                <div>
                  <TomSelect
                        v-model="rateData.process_type" @change="getProcessData" class="w-full"
                        >
                      <option value="makeable">Makeable</option>
                      <option value="rough">Rough</option>
                      <option value="bunch">Bunch</option>
                    </TomSelect>
                </div>
                <div>
                  <div>
                    <div v-if="pagedata.endis">
                      <TomSelect
                        v-model="rateData.process"
                            class="w-full" disabled
                          >
                        <option value="0">Select Process</option>
                        <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                      </TomSelect>
                    </div>
                    <div v-else>
                      <TomSelect @change="getProcessType"
                        v-model="rateData.process" :disabled="pagedata.endis"
                            class="w-full"
                          >
                        <option value="0">Select Process</option>
                        <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                      </TomSelect>
                    </div>
                  </div>
                  <div class="text-primary-3" v-if="pagedata.errors.process">{{ pagedata.errors.process[0] }}</div>
                </div>
              </div>
              <div class="flex items-center mt-3">
                <div class="form-check">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="rateData.type">
                  <label class="form-check-label inline-block text-lg" for="flexRadioDefault1">
                    Standard Rates
                  </label>
                </div>
                <div class="form-check ml-6">
                  <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="rateData.type">
                  <label class="form-check-label inline-block text-lg" for="flexRadioDefault2">
                    Fixed Per Piece
                  </label>
                </div>
              </div>
              <div class="mt-3">
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">From</div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="rateData.from"
                        placeholder="From"
                        aria-describedby="input-group-3" @keypress="$h.preventInvalidInput"
                      />
                      <!-- <div class="flex-col">
                        <button class="btn input-group-text-custom px-3" @click="IncrementWeight('increment','From')"><ChevronUpIcon class="w-4 h-4" /></button>
                        <button class="btn input-group-text-custom px-3" :disabled="pagedata.fromdis" @click="IncrementWeight('decrement','From')"><ChevronDownIcon class="w-4 h-4" /></button>
                      </div> -->
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.from">{{ pagedata.errors.from[0] }}</div>
                  </div>
                  <div>
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">To</div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="rateData.to"
                          placeholder="To"
                          aria-describedby="input-group-3"
                          @keypress="$h.preventInvalidInput"
                        />
                        <!-- <div class="flex-col">
                        <button class="btn input-group-text-custom px-3" @click="IncrementWeight('increment','To')"><ChevronUpIcon class="w-4 h-4" /></button>
                        <button class="btn input-group-text-custom px-3" :disabled="pagedata.todis" @click="IncrementWeight('decrement','To')"><ChevronDownIcon class="w-4 h-4" /></button>
                      </div> -->
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.to">{{ pagedata.errors.to[0] }}</div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-32 text-center">Type</div>
                      <TomSelect
                        v-model="rateData.rate_type"
                        id="crud-form-2" disabled
                        :options="{
                          placeholder: 'Select your Labour'
                        }"
                        class="w-full"
                      >
                        <option value="1">Issue Weight</option>
                        <option value="2">Fixed Piece</option>
                        <option value="3">Weight + Weight</option>
                        <option value="4">Piece + Piece</option>
                        <option value="5">Weight Loss</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.rate_type">{{ pagedata.errors.rate_type[0] }}</div>
                  </div>
                  <div>
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Rate</div>
                      <input
                        type="number"
                        class="form-control"
                        v-model="rateData.rate"
                        placeholder="Rate"
                        aria-describedby="input-group-4"
                      />
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.rate">{{ pagedata.errors.rate[0] }}</div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Joining Date & End Date</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36">Joining</div>
                      <Litepicker
                          v-model="rateData.s_date"
                          :options="{
                            autoApply: true,
                            showWeekNumbers: true,
                            dropdowns: {
                              minYear: new Date().getFullYear(),
                              maxYear: ((new Date().getFullYear()) + 4),
                              months: true,
                              years: true,
                            },
                          }"
                          class="form-control block mx-auto"
                        />
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.s_date">{{ pagedata.errors.s_date[0] }}</div>
                  </div>
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36">End</div>
                      <Litepicker
                          v-model="rateData.e_date"
                          :options="{
                            autoApply: true,
                            showWeekNumbers: true,
                            dropdowns: {
                              minYear: new Date().getFullYear(),
                              maxYear: ((new Date().getFullYear()) + 4),
                              months: true,
                              years: true,
                            },
                          }"
                          class="form-control block mx-auto"
                        />
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.e_date">{{ pagedata.errors.e_date[0] }}</div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mt-3">
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3" v-if="!['rough', 'bunch'].includes(rateData.process_type)">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                      <TomSelect
                        v-model="rateData.color"
                            :options="{
                              placeholder: 'Select Colors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.color">{{ pagedata.errors.color[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="!['rough', 'bunch'].includes(rateData.process_type)">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                        <TomSelect
                          v-model="rateData.purity"
                            :options="{
                              placeholder: 'Select Purity'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
                      </TomSelect>
                    </div>
                  <div class="text-primary-3" v-if="pagedata.errors.purity">{{ pagedata.errors.purity[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="!['rough', 'bunch'].includes(rateData.process_type)">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                      <TomSelect
                        v-model="rateData.shape"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.shape">{{ pagedata.errors.shape[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Stone</div>
                      <TomSelect
                        v-model="rateData.stone"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.stone">{{ pagedata.errors.stone[0] }}</div>
                  </div>
                </div>
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3" v-if="!['rough', 'bunch'].includes(rateData.process_type)">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                      <TomSelect
                        v-model="rateData.cut"
                            :options="{
                              placeholder: 'Select Cut'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.cut">{{ pagedata.errors.cut[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="!['rough', 'bunch'].includes(rateData.process_type)">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                      <TomSelect
                        v-model="rateData.polish"
                            :options="{
                              placeholder: 'Select your favorite actors'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.polish">{{ pagedata.errors.polish[0] }}</div>
                  </div>
                  <div class="mt-3" v-if="!['rough', 'bunch'].includes(rateData.process_type)">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                      <TomSelect
                        v-model="rateData.symmetry"
                            :options="{
                              placeholder: 'Select Symmetry'
                            }"
                            class="w-full"
                          multiple
                          >
                        <option value="All">All</option>
                        <option v-for="col in pagedata.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
                      </TomSelect>
                    </div>
                    <div class="text-primary-3" v-if="pagedata.errors.symmetry">{{ pagedata.errors.symmetry[0] }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Processing</div>
                      <select
                        class="form-select"
                        v-model="rateData.processing"
                        aria-label="Default select example"
                      >
                        <option value="all">All</option>
                        <option value="normal">Normal</option>
                        <option value="special">Special</option>
                        <option value="lab">LAB</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right mt-3">
                    <router-link :to="{ path: '/master/rates/jober/' + this.$route.params.id }" class="btn btn-outline-secondary w-24 mr-1">
                      Cancel
                    </router-link>
                    <button type="button" @click="SaveJoberRate" :disabled="pagedata.submit" class="btn btn-primary w-24">Save
                      <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                  </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <div id="superlarge-modal-size-preview2"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
              <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10 text-center">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
            </div>
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box">
              <!-- <div id="vertical-form"> -->
                <div class="flex flex-col justify-between mt-5">
                  <!-- BEGIN: Data List -->
                  <div class="intro-y col-span-12 overflow-auto lg:overflow-visible" v-if="pagedata.errors.prevEmpRate">
                    <table class="w-full">
                      <thead>
                        <tr>
                          <th class="px-1 md:px-1 py-3 text-center">From</th>
                          <th class="px-1 md:px-1 py-3 text-center">To</th>
                          <th class="px-1 md:px-1 py-3 text-center">Rates</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rateData.process_type)">Color</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rateData.process_type)">Cut</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rateData.process_type)">Purity</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rateData.process_type)">Polish</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rateData.process_type)">Shape</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="!['rough', 'bunch'].includes(rateData.process_type)">Symmetry</th>
                          <th class="px-1 md:px-1 py-3 text-center" v-if="rateData.process_type == 'makeable'">Tension</th>
                          <th class="px-1 md:px-1 py-3 text-center">Stone</th>
                          <th class="px-1 md:px-1 py-3 text-center">Start_Date</th>
                          <th class="px-1 md:px-1 py-3 text-center">End_Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="px-1 md:px-1 py-3 text-center font-medium">{{ pagedata.errors.prevEmpRate.from }}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium">{{ pagedata.errors.prevEmpRate.to }}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium">{{ pagedata.errors.prevEmpRate.rates }}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rateData.process_type)"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.color" :key="typ"><span v-if="pagedata.errors.parameters.colors.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rateData.process_type)"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.cut" :key="typ"><span v-if="pagedata.errors.parameters.cuts.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rateData.process_type)"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.purity" :key="typ"><span v-if="pagedata.errors.parameters.purities.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rateData.process_type)"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.polish" :key="typ"><span v-if="pagedata.errors.parameters.polishs.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rateData.process_type)"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.shape" :key="typ"><span v-if="pagedata.errors.parameters.shapes.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="!['rough', 'bunch'].includes(rateData.process_type)"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.symmetry" :key="typ"><span v-if="pagedata.errors.parameters.symmetries.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium" v-if="rateData.process_type == 'makeable'"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.tension" :key="typ"><span v-if="pagedata.errors.parameters.tensions.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium"><div class="flex-col items-start" v-for="typ in pagedata.errors.prevEmpRate.stone" :key="typ"><span v-if="pagedata.errors.parameters.stones.includes(typ)" class="text-primary-3 px-2 py-1">{{typ}}</span><span class="px-2 py-1" v-else>{{ typ }}</span></div></td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium whitespace-nowrap">{{ pagedata.errors.prevEmpRate.start_time}}</td>
                          <td class="px-1 md:px-1 py-3 text-center font-medium whitespace-nowrap">{{ pagedata.errors.prevEmpRate.end_time}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <!-- END: Data List -->
                </div>
              <!-- </div> -->
            </div>
            <div class="px-5 pb-8 text-right flex items-end">
              <button type="button" class="btn btn-primary w-24 mr-1" :disabled="pagedata.submit" @click="updateData"><SaveIcon class="w-4 h-4 mr-1" />
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              Update</button>
              <a
                    class="btn btn-primary w-24 mr-1"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#success-modal-preview"
                  >
                    <Edit2Icon class="w-4 h-4 mr-1" /> Edit
                  </a>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
            </div>
            <!-- END: Vertical Form -->
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Modal Content -->
      <div
        id="success-modal-preview"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <CheckCircleIcon
                  class="w-16 h-16 text-theme-9 mx-auto mt-3"
                />
                <div class="text-3xl mt-5">Good job!</div>
                <div class="text-gray-600 mt-2">
                  You clicked the button!
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-primary w-24"
                >Ok
                </button>
                <button
                  type="button"
                  @click="updateExistingData"
                  data-dismiss="modal"
                  class="btn w-24"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Modal Content -->
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import Toastify from 'toastify-js'
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue'
import moment from 'moment'
import cash from 'cash-dom'
import { stringify } from 'json5'
export default defineComponent({
  setup() {
    const pagedata = reactive({
      submit: false,
      endis: false,
      processes: [],
      errors: [],
      success: '',
      colors: [],
      cuts: [],
      purities: [],
      polish: [],
      shapes: [],
      stones: [],
      tensions: [],
      fluerocents: [],
      symmetry: [],
      fromdis: true,
      todis: true
    })
    const route = useRoute()
    const rateData = reactive({
      id: '',
      process: '0',
      user_type: 'Jober',
      from: 0,
      to: 0.01,
      rate: '',
      rate_type: '1',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      stone: '',
      tension: '',
      fluerocent: '',
      s_date: '',
      e_date: '',
      processing: 'all',
      process_type: 'makeable',
      type: '1'
    })
    onMounted(() => {
      getColorDetails()
      getProcessData()
        // getProcessRates()
      if(route.params.id) {
        rateData.id = route.params.id
      }
    })
    function getColorDetails() {
      axios({
          url: 'api/master/get_all_parameters',
          method: 'post',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.cuts = res.data.cut
          pagedata.colors = res.data.color
          pagedata.purities = res.data.purity
          pagedata.polish = res.data.polish
          pagedata.shapes = res.data.shape
          pagedata.symmetry = res.data.symmetry
          pagedata.fluerocents = res.data.fluerocent
          pagedata.tensions = res.data.tension
          pagedata.stones = res.data.stone
        })
    }
    function getProcessData() {
      if(rateData.process_type == 'video') {
        rateData.tension = []
      } else if(['rough', 'bunch'].includes(rateData.process_type)) {
        rateData.color = []
        rateData.cut = []
        rateData.purity = []
        rateData.polish = []
        rateData.shape = []
        rateData.symmetry = []
        rateData.tension = []
        rateData.fluerocent = []
      }
      const params = {
        process_type : rateData.process_type
      }
      rateData.process = '0'
      axios({
        url: 'api/master/get_process_by_type',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.processes = res.data.items
      })
    }
    function SaveJoberRate() {
      if(rateData.process == '0') {

      } else if(rateData.process_type) {

        rateData.s_date = moment(rateData.s_date).format('YYYY-MM-DD');
        rateData.e_date = moment(rateData.e_date).format('YYYY-MM-DD');
          let url = ''
          if(rateData.process_type == 'makeable') {
            url = 'api/master/add_makeable_process_rate_jober'
          } else if(['rough', 'bunch'].includes(rateData.process_type)) {
            url = 'api/master/add_rough_process_rate_jober'
          } else if(rateData.process_type == 'video' || rateData.process_type == 'lab') {
              url = 'api/master/add_video_lab_process_rate_jober'
          }
          pagedata.submit = true
          axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: rateData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.submit = false
            pagedata.success = res.data.success
              setTimeout(() => {
                basicNonStickyNotificationPacket()
              }, 100);
              setTimeout(() => {
                pagedata.success = ''
              }, 5000);
            router.push({ path: '/master/rates/jober/' + route.params.id })
          }).catch((err) => {
            pagedata.submit = false
            if(err.response.data.error) {
              pagedata.errors.error = err.response.data.error
              pagedata.errors.parameters = err.response.data.parameters
              pagedata.errors.prevEmpRate = err.response.data.prevEmpRate
              cash('#superlarge-modal-size-preview2').modal('show')
            } else {
              pagedata.errors = err.response.data.errors
              setTimeout(() => {
                pagedata.errors = []
              }, 5000);
            }
          })

      }
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    function IncrementWeight(type,name) {
      if(name == 'From') {
        if(type == 'increment') {
          rateData.from = parseFloat((rateData.from + 0.01).toFixed(2))
         }else {
           rateData.from = parseFloat((rateData.from - 0.01).toFixed(2))
         }
      } else if(name == 'To') {
        if(type == 'increment') {
          rateData.to = parseFloat((rateData.to + 0.01).toFixed(2))
         }else {
           rateData.to = parseFloat((rateData.to - 0.01).toFixed(2))
         }
      }
    }
    function updateData() {
      axios({
        url: 'api/master/add_update_new_process_rate_jober',
        method: 'post',
        baseURL: BASE_URL,
        data: rateData,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.submit = false
        pagedata.errors.error = ''
        cash('#superlarge-modal-size-preview2').modal('hide')
        cash('#success-modal-preview').modal('hide')
        pagedata.success = res.data.success
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          }, 100);

          setTimeout(() => {
            pagedata.success = ''
          }, 5000);
        router.push({ path: '/master/rates/jober/' + route.params.id })
      }).catch(() => {
        pagedata.submit = false
      })
    }
    function updateExistingData() {
      router.push({ path: '/master/rates/jober/update/' + window.btoa(pagedata.errors.prevEmpRate.id) })
    }
    function getProcessType() {
      const params = {
        process_id : rateData.process
      }
      if(rateData.process != '0') {

        axios({
          url: 'api/master/get_process_type',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          rateData.rate_type = stringify(res.data.process_type)
        })
      }
    }
    watch(() => rateData.color , (CurrentValue, OldValue) => {
      if(rateData.color.includes('All')) {
        rateData.color.splice(0,rateData.color.length - 1)
        rateData.color[0] = 'All'
      }
    })
    watch(() => rateData.cut , (CurrentValue, OldValue) => {
      if(rateData.cut.includes('All')) {
        rateData.cut.splice(0,rateData.cut.length - 1)
        rateData.cut[0] = 'All'
      }
    })
    watch(() => rateData.purity , (CurrentValue, OldValue) => {
      if(rateData.purity.includes('All')) {
        rateData.purity.splice(0,rateData.purity.length - 1)
        rateData.purity[0] = 'All'
      }
    })
    watch(() => rateData.polish , (CurrentValue, OldValue) => {
      if(rateData.polish.includes('All')) {
        rateData.polish.splice(0,rateData.polish.length - 1)
        rateData.polish[0] = 'All'
      }
    })
    watch(() => rateData.shape , (CurrentValue, OldValue) => {
      if(rateData.shape.includes('All')) {
        rateData.shape.splice(0,rateData.shape.length - 1)
        rateData.shape[0] = 'All'
      }
    })
    watch(() => rateData.symmetry , (CurrentValue, OldValue) => {
      if(rateData.symmetry.includes('All')) {
        rateData.symmetry.splice(0,rateData.symmetry.length - 1)
        rateData.symmetry[0] = 'All'
      }
    })
    watch(() => rateData.stone , (CurrentValue, OldValue) => {
      if(rateData.stone.includes('All')) {
        rateData.stone.splice(0,rateData.stone.length - 1)
        rateData.stone[0] = 'All'
      }
    })
    // watch(() => rateData.tension , (CurrentValue, OldValue) => {
    //   if(rateData.tension.includes('All')) {
    //     rateData.tension.splice(0,rateData.tension.length - 1)
    //     rateData.tension[0] = 'All'
    //   }
    // })
    // watch(() => rateData.fluerocent , (CurrentValue, OldValue) => {
    //   if(rateData.fluerocent.includes('All')) {
    //     rateData.fluerocent.splice(0,rateData.fluerocent.length - 1)
    //     rateData.fluerocent[0] = 'All'
    //   }
    // })
    watch(() => rateData.from , (CurrentValue, OldValue) => {
      if(rateData.from <= 0) {
        pagedata.fromdis = true
      } else {
        pagedata.fromdis = false
      }
      if(parseFloat(rateData.to ? rateData.to : 0) <= parseFloat(rateData.from ? rateData.from : 0)) {
        rateData.to = parseFloat(rateData.from ? rateData.from : 0).toFixed(2)
      }
    })
    watch(() => rateData.to , (CurrentValue, OldValue) => {
      if(parseFloat(rateData.to ? rateData.to : 0) <= parseFloat(rateData.from ? rateData.from : 0)) {
        rateData.to = parseFloat(rateData.to ? rateData.to : 0).toFixed(2)
        pagedata.todis = true
      } else {
        pagedata.todis = false
      }
    })
    return {
      pagedata,
      rateData,
      getProcessData,
      basicNonStickyNotificationPacket,
      SaveJoberRate,
      IncrementWeight,
      updateData,
      updateExistingData,
      getProcessType
    }
  }
})
</script>
<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>
