<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Company Rates (Non Certified)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
    </div>
    </div>
    <div class="intro-y box p-5 mt-5 w-full">
        <div id="single-file-upload" class="p-5">
            <div class="preview  col-span-6" v-if="page_data.price_cut == 'ROUND'">
              <h3>ROUND</h3>
              <Dropzone
                ref-key="dropzoneSingleRef"
                :options="{
                  url: page_data.base_url + 'api/master/upload-company-rate?cut='+page_data.price_cut,
                  thumbnailWidth: 150,
                  maxFilesize: 10,
                  maxFiles: 1,
                  headers: { Authorization: 'Bearer ' + page_data.token }
                }"
                class="dropzone mb-4"
              >
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  Upload Excel file with latest standared rate with provided formate. (.xlsx, .xls)
                </div>
              </Dropzone>
              <span class="mt-5">Latest Rates upadted on : {{page_data.last_sync_date}}</span>
            </div>

            <div class="preview  col-span-6 mt-5">
              <h3 class="font-20">FANCY</h3>
              <Dropzone
                ref-key="dropzoneSingleRefF"
                :options="{
                  url: page_data.base_url + 'api/master/upload-company-rate?cut=FANCY',
                  thumbnailWidth: 150,
                  maxFilesize: 10,
                  maxFiles: 1,
                  headers: { Authorization: 'Bearer ' + page_data.token }
                }"
                class="dropzone mb-4"
              >
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  Upload Excel file with latest standared rate with provided formate. (.xlsx, .xls)
                </div>
              </Dropzone>
              <span class="mt-5">Latest Rates upadted on : {{page_data.last_sync_date}}</span>
            </div>
          </div>
    </div>
    <div class="intro-y box p-5 mt-5">

       <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2 mt-2"
              >Select Shape</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="page_data.price_cut"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              @change="getOldRates()"
            >
              <option value="ROUND" selected>ROUND</option>
              <option value="FANCY">FANCY</option>
            </select>

      <div id="responsive-table" class="p-5" v-if="page_data.load_price">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
      >
        <LoadingIcon icon="bars" class="w-8 h-8" />
        <div class="text-center text-xs mt-2">Getting Latest Price</div>
      </div>
              </div>
            </div>
      </div>
      <div id="responsive-table" class="p-5" v-else>
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                       <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Shape
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Purity
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Color
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        From
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        To
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(arrayresult ,key ,id) in page_data.rate_round" :key="key" :id="id" >
                      <td class="border-b whitespace-nowrap" v-if="key >= 0">{{key + 1}}</td>
                      <td class="border-b whitespace-nowrap" v-if="key >= 0 && arrayresult[0] != null">{{arrayresult[0]}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key >= 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key >= 0 && arrayresult[1] != null">{{arrayresult[1]}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key >= 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key >= 0 && arrayresult[2] != null">{{arrayresult[2]}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key >= 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key >= 0 && arrayresult[3] != null">{{arrayresult[3]}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key >= 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key >= 0 && arrayresult[4] != null">{{arrayresult[4]}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key >= 0">N/A</span></td>
                      <td class="border-b whitespace-nowrap" v-if="key >= 0 && arrayresult[5] != null">{{arrayresult[5].toFixed(2)}}</td>
                      <td class="border-b whitespace-nowrap" v-else><span v-if="key >= 0">N/A</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
      </div>
    <div>
    </div>

    </div>

  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, provide, reactive, onMounted } from 'vue'
import { BASE_URL } from "@/constants/config";
import axios from 'axios';

export default defineComponent({
  setup() {
    const dropzoneSingleRef = ref()
    const dropzoneSingleRefF = ref()

    const page_data = reactive({
      base_url : BASE_URL,
      last_sync_date : '',
      rate_round : [],
      load_price:false,
      price_cut:'ROUND',
      rate_fancy : [],
      token: localStorage.getItem("token"),
      showRoundPrice:true,
      showFancyPrice:false,
    })

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })

    provide('bind[dropzoneSingleRefF]', el => {
      dropzoneSingleRefF.value = el
    })

    const getOldRates = () => {
      page_data.load_price = true;
      const params = {
        "rate_id":  'TY736488',
        "cut": page_data.price_cut
      };

      let promise = axios({
        url: "api/master/get-company-rate",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

        page_data.last_sync_date = data.round_rate.created_at;
        page_data.rate_round = data.round_rate.price_all;
        page_data.load_price = false;
        console.log(page_data.load_price);

        })
        .catch(_error => {

        });
    }
    const changeTab = (type) => {
      if(type == 1){
        page_data.showRoundPrice = true;
        page_data.showFancyPrice = false;
      }else {
        page_data.showRoundPrice = false;
        page_data.showFancyPrice = true;
      }

    }


    onMounted(() => {
      const elDropzoneSingleRef = dropzoneSingleRef.value
      elDropzoneSingleRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneSingleRef.dropzone.on('error', () => {
        alert('No more files please!')
      })

      const eldropzoneSingleRefF = dropzoneSingleRefF.value
      eldropzoneSingleRefF.dropzone.on('success', () => {
        alert('Added file.')
      })
      eldropzoneSingleRefF.dropzone.on('error', () => {
        alert('No more files please!')
      })
      getOldRates();
    })
   return {
      page_data,
      changeTab,
      getOldRates
    }
  }
})
</script>

