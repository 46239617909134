<template>
  <div>
    <div class="intro-y flex items-center my-3">
      <h2 class="text-lg font-medium mr-auto">New Purchase</h2>
    </div>
    <!-- <div class="grid grid-cols-12 gap-6 mt-5"> -->
    <div class="grid grid-cols-12 gap-4">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box p-5">
          <div id="input" class="">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-center">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Purchase Date</label
                    >
                  </div>
                  <div class="relative">
                    <div
                      class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                    >
                      <CalendarIcon class="w-4 h-4" />
                    </div>
                    <Litepicker
                      v-model="purchase.date"
                      :options="{
                        autoApply: false,
                        showWeekNumbers: true,
                        dropdowns: {
                          minYear: 1990,
                          maxYear: null,
                          months: true,
                          years: true
                        }
                      }"
                      disabled
                      class="form-control pl-12"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-0">Purchase Type</label></div>
                                <select class="form-select" v-model="purchase.type" aria-label="Default select example">
                                        <option value="makeable">Makeable</option>
                                        <option value="rough">Rough</option>
                                        <option value="video">Video</option>
                                        <option value="lab">Lab</option>
                                    </select>
                            </div>
                        </div> -->
              <div
                class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2"
                v-if="user_details.user_role != 3"
              >
                <div>
                  <div class="flex items-center">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Party</label
                    >
                  </div>
                  <TomSelect
                    v-model="purchase.party"
                    @change="openPiece(purchase.party)"
                    :options="{
                      placeholder: 'Select your favorite actors'
                    }"
                    class="w-full"
                  >
                    <!-- <option value="0">Select Party</option> -->
                    <option
                      v-for="(party, index) in screenData.parties"
                      :key="index"
                      :value="party.id"
                      >{{ party.first_name }} {{ party.last_name }}</option
                    >
                  </TomSelect>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Kapan No</label
                    >
                    <span class="text-primary-3"> &nbsp; *</span>
                  </div>
                  <div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="purchase.voucher"
                        placeholder="Kapan No"
                        @input="updateStockName"
                        aria-describedby="input-group-4"
                      />
                    </div>
                    <span
                      class="text-primary-3"
                      v-if="pagedata.errors.voucher"
                      >{{ pagedata.errors.voucher[0] }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Stone</label
                    >
                  </div>
                  <select
                    class="form-select"
                    v-model="purchase.stone_id"
                    @change="loadValueByStone"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="item in screenData.stones"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.stone_name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Duration</label
                    >
                  </div>
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <input
                        type="text"
                        @keypress="$h.preventInvalidInput"
                        class="form-control"
                        v-model="purchase.due"
                        placeholder="Duration"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span
                      class="text-primary-3"
                      v-if="pagedata.errors.stone_id"
                      >{{ pagedata.errors.stone_id[0] }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Est Value</label
                    >
                  </div>
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <input
                        type="text"
                        @keypress="$h.preventInvalidInput"
                        v-model="purchase.est_majuri_total"
                        disabled
                        class="form-control"
                        placeholder="Est Value"
                        aria-describedby="input-group-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2"
                v-if="pagedata.home_party == true"
              >
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Per Ct Rate</label
                    >
                  </div>
                  <div>
                    <div class="input-group">
                      <input
                        @input="getWeight"
                        type="text"
                        class="form-control"
                        @keypress="$h.preventInvalidInput"
                        v-model="purchase.per_ct_rate"
                        placeholder="Per Carat Rate ($)"
                        aria-describedby="input-group-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2"
                v-if="pagedata.home_party == true"
              >
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Majuri Type</label
                    >
                  </div>
                  <div>
                    <div class="input-group">
                      <select
                        class="form-select"
                        v-model="purchase.majuri_type"
                        @change="getMarginCosting"
                        aria-label="Default select example"
                      >
                        <option value="1">Percent(%)</option>
                        <option value="2">Fixed</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2"
                v-if="pagedata.home_party == true"
              >
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Estimate Majuri</label
                    >
                  </div>
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <input
                        type="text"
                        class="form-control"
                        @keypress="$h.preventInvalidInput"
                        v-model="purchase.majuri_rate"
                        placeholder="Est Majuri"
                        aria-describedby="input-group-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2"
                v-if="pagedata.home_party == true"
              >
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Estimate Margin (%)</label
                    >
                  </div>
                  <div>
                    <div class="input-group mt-2 sm:mt-0">
                      <input
                        type="text"
                        class="form-control"
                        @keypress="$h.preventInvalidInput"
                        v-model="purchase.margin_percent"
                        placeholder="Margin (%)"
                        aria-describedby="input-group-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >No. of Pieces</label
                    >
                  </div>
                  <div>
                    <div v-if="purchase.party == '0'">
                      <div class="input-group">
                        <input
                          type="number"
                          class="form-control"
                          @change="getPaketDetail"
                          v-model="purchase.piece"
                          placeholder="No of Pieces"
                          aria-describedby="input-group-3"
                          disabled
                        />
                      </div>
                    </div>
                    <div v-else>
                      <div class="input-group">
                        <input
                          type="number"
                          disabled
                          class="form-control"
                          @input="getPaketDetail"
                          v-model="purchase.piece"
                          placeholder="No of Pieces"
                          aria-describedby="input-group-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-top">
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Total Weight(Ct)</label
                    >
                  </div>
                  <div>
                    <div class="input-group">
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        v-model="purchase.weight"
                        placeholder="Total Weight"
                        aria-describedby="input-group-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->

        <!-- Begin:Input Table -->
        <div class="intro-y box my-5 p-5 overflow-auto">
          <h2 class="text-lg font-medium mr-auto">Stock Details</h2>

          <table class="table border-2 pt-5" v-if="purchase.type == 'rough'">
            <thead>
              <tr class="border">
                <th class="whitespace-nowrap">
                  Image Upload <span class="text-primary-3"> &nbsp; *</span>
                </th>
                <th class="whitespace-nowrap">Packet No</th>
                <th class="whitespace-nowrap">Stock No</th>
                <th class="whitespace-nowrap">
                  Stock Weight(Ct) <span class="text-primary-3"> &nbsp; *</span>
                </th>
                <th class="whitespace-nowrap">Quality</th>
                <th class="whitespace-nowrap">Length</th>
                <th class="whitespace-nowrap">Width</th>
                <th class="whitespace-nowrap">Height</th>
                <th class="whitespace-nowrap">Comment</th>
                <!-- <th>Notes</th> -->
                <!-- <th>Estimate Value</th>
                      <th>Purchase Rate</th> -->
              </tr>
            </thead>
            <tbody class="border">
              <tr class="" v-for="(item, index) in purchase.packet_details" :key="index">
              <td class="whitespace-nowrap border-b">
                <div class="flex">
                  <a class="flex items-center text-theme-1 mr-4" href="javascript:;" title="Upload Image" data-toggle="modal" data-target="#upload-image-modal"  @click="pagedata.modelIndex = index">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-upload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M14 13v-8l-5 5m10 0l-5-5" />
                      <path d="M12 21v-8h4a2 2 0 0 0 2 -2v-5m-8 10h-4l4 -4m-4 4l4 4" />
                    </svg>
                  </a>
                  <div v-if="item.url !== ''" class="flex items-center">
                  <!-- <a class="flex items-center text-theme-1" href="javascript:;" title="Preview Image" data-toggle="modal" data-target="#preview-image-modal">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-image" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <rect x="3" y="3" width="18" height="18" rx="3" />
                          <circle cx="8.5" cy="8.5" r="1" />
                          <polyline points="9.879 11.572 11.243 13.364 13.5 10.5" />
                          <path d="M7 20h10a2 2 0 0 0 2 -2v-14l-4 4h-12" />

                      </svg>
                  </a> -->
                  <a class="flex items-center text-theme-1" href="javascript:;" title="Preview Image" data-toggle="modal" data-target="#preview-image-modal" @click="pagedata.url = item.url">
                    <img  :src="item.url" v-if="item.url" alt="Please Select Image" class="w-10 h-10 shadow-md"></a>
                  <button @click="clearPreviewImage(index)" v-if="item.url">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                  </button>
              </div>
                </div>
              </td>


                <td class="whitespace-nowrap border-b">
                  <div v-if="this.$route.params.id">
                    <div class="" v-if="item.number">{{ item.number }}</div>
                    <div class="" v-else>
                      <div v-if="index < 9">
                        {{ purchase.lotno }}-0{{ index + 1 }}
                      </div>
                      <div v-else>{{ purchase.lotno }}-{{ index + 1 }}</div>
                    </div>
                  </div>
                  <div v-else>
                    <div class=" " v-if="index < 9">XX-XX-0{{ index + 1 }}</div>
                    <div class="" v-else>XX-XX-{{ index + 1 }}</div>
                  </div>
                </td>
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  {{ item.id ? item.paket_no : item.paket_no + (index + 1) }}
                </td>
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  <input
                    type="text"
                    @input="loadRoughMajuriValue(item)"
                    v-model="item.weight"
                    class="form-control rounded-none py-1"
                    @keypress="$h.preventInvalidInput"
                    placeholder="Weight(Ct)"
                    aria-describedby="input-group-3"
                  />
                </td>
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  <select
                    class="form-select rounded-none py-1"
                    v-model="item.quality_id"
                    aria-label="Select Quality"
                  >
                    <option
                      v-for="item in screenData.qualities"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.quality_name }}</option
                    >
                  </select>
                </td>
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  <input
                    type="text"
                    v-model="item.length"
                    class="form-control rounded-none py-1"
                    @keypress="$h.preventInvalidInput"
                    placeholder="Length"
                    aria-describedby="input-group-3"
                  />
                </td>
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  <input
                    type="text"
                    v-model="item.width"
                    class="form-control rounded-none py-1"
                    @keypress="$h.preventInvalidInput"
                    placeholder="Width"
                    aria-describedby="input-group-3"
                  />
                </td>
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  <input
                    type="text"
                    v-model="item.height"
                    class="form-control rounded-none py-1"
                    @keypress="$h.preventInvalidInput"
                    placeholder="Height"
                    aria-describedby="input-group-3"
                  />
                </td>
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  <input
                    type="text"
                    v-model="item.comment"
                    class="form-control rounded-none py-1"
                    placeholder="Comment"
                    aria-describedby="input-group-3"
                  />
                </td>

                <!-- <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.est_majuri" disabled class="form-control rounded-none" placeholder="Est Value" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.owner_value" disabled class="form-control rounded-none" placeholder="Purchase rate" aria-describedby="input-group-3" /></td> -->
                <td class="whitespace-nowrap border-b" style="padding: 2px;">
                  <button
                    type="button"
                    class="text-theme-6 flex items-center py-1"
                    v-if="!item.id"
                    tabindex="-1"
                    @click="removeField(item.id, index)"
                  >
                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div id="upload-image-modal" class="modal" tabindex="-1" aria-hidden="true" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Upload Image</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Dropzone -->
                  <Dropzone
                ref-key="dropzoneSingleRef"
                :options="{
                  url: screenData.base_url + 'api/uploadImage',
                   paramName: 'image',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  maxFiles: 1,
                  headers: { Authorization: 'Bearer ' + screenData.token },

                }"
                class="dropzone mb-4"

              >
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  Upload Image file with latest standared rate with provided formate. (.png, .jpg)
                </div>
              </Dropzone>
                  <!-- END: Dropzone -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
<!-- Preview Image Modal -->
<div id="preview-image-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Preview Image</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Image Show -->
                  <img :src="pagedata.url" alt="Preview Image" class="w-full h-auto">

                  <!-- <img src="http://127.0.0.1:8000/storage/images/saeTx4dgg4TeIcoGmLzB.jpg" alt="Preview Image" class="w-full h-auto"> -->

                  <!-- END: Image Show -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
<!-- End of Preview Image Modal -->

          <div class="flex justify-between">
            <div>
              <div class="text-left">
                <button
                  type="button"
                  @click="AddPiece"
                  v-if="purchase.piece < purchase.packet_details.length"
                  class="btn btn-primary w-24"
                >
                  Add Piece
                </button>
              </div>
            </div>
            <div>
              <div
                class="flex justify-end align-items-center mt-5"
                v-if="purchase.type == 'rough'"
              >
                <router-link
                  :to="{ name: 'purchase-view' }"
                  class="btn btn-outline-secondary w-24 mr-1"
                >
                  Cancel
                </router-link>
                <div class="text-left">
                  <button
                    type="button"
                    @click="SavePurchase"
                    class="btn btn-primary w-24"
                  >
                    Save
                    <LoadingIcon
                      v-if="pagedata.submit"
                      icon="oval"
                      color="white"
                      class="w-4 h-4 ml-2"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End:Input Table -->
      </div>
      <div
        class="intro-y col-span-12 lg:col-span-7 mb-8 hidden"
        v-if="purchase.piece > 0"
      >
        <!-- BEGIN: Vertical Form -->
        <div class="intro-y box" v-if="purchase.party != '0'">
          <div id="vertical-form" class="p-5" v-if="purchase.type != 'rough'">
            <div v-if="this.$route.params.id">
              <h2
                class="text-lg font-medium mx-5"
                v-if="purchase.packet_details[pagedata.paket_num].number"
              >
                {{ purchase.packet_details[pagedata.paket_num].number }}
              </h2>
              <h2 class="text-lg font-medium mx-5" v-else>
                <div v-if="pagedata.paket_num < 9">
                  {{ purchase.lotno }}-0{{ pagedata.paket_num + 1 }}
                </div>
                <div v-else>
                  {{ purchase.lotno }}-{{ pagedata.paket_num + 1 }}
                </div>
              </h2>
            </div>
            <div v-else>
              <h2
                class="text-lg font-medium mx-5"
                v-if="pagedata.paket_num < 9"
              >
                XX-XX-0{{ pagedata.paket_num + 1 }}
              </h2>
              <h2 class="text-lg font-medium mx-5" v-else>
                XX-XX-{{ pagedata.paket_num + 1 }}
              </h2>
            </div>
            <div class="grid grid-cols-12 gap-6 mt-5">
              <div class="intro-y col-span-12 md:col-span-6">
                <div class="mt-3">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Number
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].paket_no
                      "
                      class="form-control"
                      disabled
                      placeholder="Paket No"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Weight
                    </div>
                    <input
                      type="text"
                      @keypress="$h.preventInvalidInput"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].weight
                      "
                      @input="getWeight"
                      class="form-control"
                      placeholder="Weight"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'makeable'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      PWT
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num]
                          .polish_weight
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      class="form-control"
                      placeholder="Polsh Weight"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Color
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].color
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.colors"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.color_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Purity
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].purity
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.purities"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.purity_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Shape
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].shape
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.shapes"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.shape_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Cut
                    </div>
                    <select
                      class="form-select"
                      v-model="purchase.packet_details[pagedata.paket_num].cut"
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.cuts"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.cut_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Polish
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].polish
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.polish"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.polish_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Est Value
                    </div>
                    <input
                      type="number"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].est_majuri
                      "
                      disabled
                      class="form-control"
                      placeholder="Est Value"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
              </div>
              <div class="intro-y col-span-12 md:col-span-6">
                <div class="mt-3">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Symmerty
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].symmetry
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.symmetry"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.symmetry_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Flurocent
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].fluerocent
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.fluerocents"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.fluerocent_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'makeable'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Tension
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].tension
                      "
                      @change="
                        loadPlanningValues(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.tensions"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.tension_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'makeable'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Discount
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].discount
                      "
                      @change="
                        calculateValue(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      class="form-control"
                      placeholder="Discount"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'makeable'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Rapo Rate
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].rap_value
                      "
                      @change="
                        calculateValue(
                          purchase.packet_details[pagedata.paket_num]
                        )
                      "
                      class="form-control"
                      placeholder="Rapo rate"
                      disabled
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'makeable'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Rate
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].value
                      "
                      class="form-control"
                      placeholder="Rate"
                      disabled
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div
                  class="mt-3"
                  v-if="
                    purchase.type == 'makeable' && pagedata.home_party == true
                  "
                >
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Purchase
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].owner_value
                      "
                      class="form-control"
                      placeholder="Purchase Rate"
                      disabled
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'makeable'">
                  <div class="input-group sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Processing
                    </div>
                    <select
                      class="form-select"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].processing
                      "
                      aria-label="Default select example"
                    >
                      <option value="normal">Normal</option>
                      <option value="special">Special</option>
                      <option value="lab">LAB</option>
                    </select>
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'video'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Lab
                    </div>
                    <select
                      class="form-select"
                      v-model="purchase.packet_details[pagedata.paket_num].lab"
                      aria-label="Default select example"
                    >
                      <option
                        v-for="item in screenData.labs"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.lab_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'video'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Cert. No
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num]
                          .certificate_no
                      "
                      class="form-control"
                      placeholder="Certificate No"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'video'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Depth (%)
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num]
                          .depth_percent
                      "
                      class="form-control"
                      placeholder="Depth"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'video'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Min Dia
                    </div>
                    <div class="flex">
                      <input
                        type="number"
                        v-model="
                          purchase.packet_details[pagedata.paket_num].min_dia1
                        "
                        class="form-control"
                        placeholder="Min Dia"
                        aria-describedby="input-group-3"
                      />
                      <input
                        type="number"
                        v-model="
                          purchase.packet_details[pagedata.paket_num].min_dia2
                        "
                        class="form-control"
                        placeholder="Min Dia"
                        aria-describedby="input-group-3"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'video'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Total Depth
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num].total_depth
                      "
                      class="form-control"
                      placeholder="Total Depth"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3" v-if="purchase.type == 'video'">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Table (%)
                    </div>
                    <input
                      type="text"
                      v-model="
                        purchase.packet_details[pagedata.paket_num]
                          .table_percent
                      "
                      class="form-control"
                      placeholder="Table"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between mt-5">
              <router-link
                :to="{ name: 'purchase-view' }"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </router-link>
              <div class="text-right">
                <button
                  class="btn btn-outline-secondary w-24 mr-1"
                  @click="prevnext('prev')"
                  :disabled="pagedata.btnPrev"
                >
                  Previous
                </button>
                <button
                  type="button"
                  v-if="!pagedata.btnDis"
                  @click="prevnext('next')"
                  class="btn btn-primary w-24"
                >
                  Next
                </button>
                <button
                  type="button"
                  :disabled="purchase.party == '0' || pagedata.submit"
                  v-else
                  @click="SavePurchase"
                  class="btn btn-primary w-24"
                >
                  Save
                  <LoadingIcon
                    v-if="pagedata.submit"
                    icon="oval"
                    color="white"
                    class="w-4 h-4 ml-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div id="vertical-form" class="p-5" v-else>
            <div
              class="grid grid-cols-12 gap-2 mt-5 items-center"
              v-for="(item, index) in purchase.packet_details"
              :key="index"
            >
              <div class="intro-y col-span-12 md:col-span-2">
                <div v-if="this.$route.params.id">
                  <h2 class="text-lg font-medium" v-if="item.number">
                    {{ item.number }}
                  </h2>
                  <h2 class="text-lg font-medium" v-else>
                    <div v-if="index < 9">
                      {{ purchase.lotno }}-0{{ index + 1 }}
                    </div>
                    <div v-else>{{ purchase.lotno }}-{{ index + 1 }}</div>
                  </h2>
                </div>
                <div v-else>
                  <h2 class="text-lg font-medium " v-if="index < 9">
                    XX-XX-0{{ index + 1 }}
                  </h2>
                  <h2 class="text-lg font-medium" v-else>
                    XX-XX-{{ index + 1 }}
                  </h2>
                </div>
              </div>
              <div class="intro-y col-span-12 md:col-span-5">
                <div class="mt-3">
                  <div class="input-group">
                    <div
                      id="input-group-3"
                      class="input-group-text w-24 text-center"
                    >
                      Number
                    </div>
                    <input
                      type="text"
                      v-model="item.paket_no"
                      disabled
                      class="form-control"
                      placeholder="Paket No"
                      aria-describedby="input-group-3"
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-36 text-center"
                    >
                      Est Value
                    </div>
                    <input
                      type="number"
                      v-model="item.est_majuri"
                      disabled
                      class="form-control"
                      placeholder="Est Value"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
              </div>
              <div class="intro-y col-span-12 md:col-span-5">
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-24 text-center"
                    >
                      Weight
                    </div>
                    <input
                      type="text"
                      v-model="item.weight"
                      @keypress="$h.preventInvalidInput"
                      @input="loadRoughMajuriValue(item)"
                      class="form-control"
                      placeholder="Weight"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <div class="input-group mt-2 sm:mt-0">
                    <div
                      id="input-group-3"
                      class="input-group-text w-24 text-center"
                    >
                      Purchase
                    </div>
                    <input
                      type="number"
                      v-model="item.owner_value"
                      disabled
                      class="form-control"
                      placeholder="Purchase Rate"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex items-center justify-between mt-5"
              v-if="purchase.type != 'rough'"
            >
              <router-link
                :to="{ name: 'purchase-view' }"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </router-link>
              <div class="text-right">
                <button
                  type="button"
                  @click="SavePurchase"
                  class="btn btn-primary w-24"
                >
                  Save
                  <LoadingIcon
                    v-if="pagedata.submit"
                    icon="oval"
                    color="white"
                    class="w-4 h-4 ml-2"
                  />
                </button>
              </div>
            </div>
            <div class="flex items-center mt-5" v-else>
              <router-link
                :to="{ name: 'purchase-view' }"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </router-link>
              <div class="text-left">
                <button
                  type="button"
                  @click="SavePurchase"
                  class="btn btn-primary w-24"
                >
                  Save
                  <LoadingIcon
                    v-if="pagedata.submit"
                    icon="oval"
                    color="white"
                    class="w-4 h-4 ml-2"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  provide,
  ref,
  watch
} from 'vue'
import moment from 'moment'
import { useRoute } from 'vue-router'
import store from '@/store'
import { stringify } from 'json5'
import { helper as $h } from '@/utils/helper'
import Swal from 'sweetalert2';

export default defineComponent({
  setup() {
    const route = useRoute()
    const dropzoneSingleRef = ref()

    const screenData = reactive({
      base_url : BASE_URL,
      parties: [],
      colors: [],
      cuts: [],
      image:[],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      qualities: [],
      shapes: [],
      labs: []
    })
    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })

    const swal = inject('$swal')
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      paket_num: 0,
      btnDis: false,
      modelIndex: 0,
      btnPrev: true,
      submit: false,
      url: "",
      home_party: user_details.value.home_party == 1 ? true : false,
      errors: []
    })
    const purchase = reactive({
      id: '',
      date: '',
      type: 'rough',
      party: '0',
      stone_id: 2,
      piece: 1,
      weight: 0,
      due: 15,
      voucher: '',
      lotno: '',
      per_ct_rate: '',
      est_majuri_total: '',
      majuri_type: '2',
      majuri_rate: '',
      margin_percent: '',
      packet_details: [
        {
          paket_no: '',
          id: '',
          weight: '',
          polish_weight: '',
          color: '',
          image: '',
          url: '',
          purity: '',
          shape: '',
          cut: '',
          polish: '',
          symmetry: '',
          fluerocent: '',
          tension: '',
          stone: '',
          lab: '',
          certificate_no: '',
          rap_value: '',
          value: '',
          owner_value: '',
          min_dia1: '',
          min_dia2: '',
          total_depth: '',
          depth_percent: '',
          table_percent: '',
          length: '',
          width: '',
          height: '',
          comment: '',
          discount: 30,
          processing: 'normal',
          number: '',
          est_majuri: ''
        }
      ]
    })
    // const getPreviewImageUrl = computed(() => {
    //   if (
    //     Array.isArray(purchase.packet_details) &&
    //     purchase.packet_details.length > 0 &&
    //     pagedata.modelIndex >= 0 &&
    //     pagedata.modelIndex < purchase.packet_details.length
    //   ) {
    //     return purchase.packet_details[pagedata.modelIndex].image || '';
    //   }
    //   return '';
    // });


    onMounted(() => {
      getPaketDetail()
      if (user_details.value.user_role == 3) {
        purchase.party = user_details.value.account_id
      }
      if (route.params.id) {
        purchase.id = route.params.id
        getPurchaseData()
      } else {
        getMarginCosting()
      }
      setTimeout(() => {
        get_all_parameters()
        getParties()
      }, 200)
      const eldropzoneSingleRefF = dropzoneSingleRef.value
        eldropzoneSingleRefF.dropzone.on('success', (file, response) => {
        const packetIndex = pagedata.modelIndex;
        console.log('Selected packet index:', pagedata.modelIndex);

        purchase.packet_details[packetIndex].image = response.path;
        purchase.packet_details[packetIndex].url = response.path;
        cash('#upload-image-modal').modal('hide')
        Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Image Uploaded',
  });
  clearDropzone();

  // closeModal();
      })

      eldropzoneSingleRefF.dropzone.on('error', () => {
        Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No more files please!',
  });      })

    })
    const clearDropzone = () => {
    const eldropzoneSingleRefF = dropzoneSingleRef.value;
    eldropzoneSingleRefF.dropzone.removeAllFiles();
  };
    const showModal = ref(false)
    function openModal() {
      showModal.value = true
    }
    const closeModal = () => {
    const modal = document.getElementById('upload-image-modal');
    if (modal) {
      modal.classList.remove('show'); // Remove the 'show' class to hide the modal
      modal.setAttribute('aria-hidden', 'true'); // Set aria-hidden attribute to true to hide from screen readers
    }
  };
    function getParties() {
      axios({
        url: 'api/master/dp_parties',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        screenData.parties = res.data.items

        if (route.params.id) {
          setTimeout(() => {
            openPiece(purchase.party)
          }, 200)
        } else {
          purchase.party = String(screenData.parties[0].id)
          openPiece(purchase.party)
        }
      })
    }

    function getMarginCosting() {
      const params = {
        type: window.btoa(purchase.majuri_type)
      }
      axios({
        url: 'api/majuri_costing/get_party_majuri_costing',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        purchase.majuri_rate = res.data.majuri_value.value
        purchase.margin_percent = res.data.margin_value.value
      })
    }

    function get_all_parameters() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        screenData.colors = res.data.color
        screenData.cuts = res.data.cut
        screenData.purities = res.data.purity
        screenData.polish = res.data.polish
        screenData.shapes = res.data.shape
        screenData.symmetry = res.data.symmetry
        screenData.fluerocents = res.data.fluerocent
        screenData.tensions = res.data.tension
        screenData.stones = res.data.stone
        screenData.qualities = res.data.quality
        screenData.labs = res.data.lab
      })
    }



    function SavePurchase() {
      if (purchase.party != '0') {
        let submit = true
        if (purchase.type == 'makeable') {
          purchase.packet_details.forEach(plan => {
            if (
              !plan.weight ||
              !plan.polish_weight ||
              !plan.color ||
              !plan.cut ||
              !plan.purity ||
              !plan.polish ||
              !plan.shape ||
              !plan.symmetry ||
              !plan.fluerocent
            ) {
              swal('Warning!', 'Fields are Empty !', 'warning')
              submit = false
            }
          })
        } else if (purchase.type == 'video') {
          purchase.packet_details.forEach(plan => {
            if (
              !plan.weight ||
              !plan.color ||
              !plan.cut ||
              !plan.purity ||
              !plan.polish ||
              !plan.shape ||
              !plan.symmetry ||
              !plan.fluerocent
            ) {
              swal('Warning!', 'Fields are Empty !', 'warning')
              submit = false
            }
          })
        } else {
          purchase.packet_details.forEach(plan => {
            if (!plan.weight) {
              // if (!plan.weight || !plan.length || !plan.width || !plan.height) {
              swal('Warning!', 'Fields are Empty !', 'warning')
              submit = false
            }
          })
        }

        getWeight()

        if (submit == true) {
          // if(validateData()) {
          pagedata.submit = true
          purchase.date = moment(purchase.date).format('YYYY-MM-DD')
          if (route.params.id) {
            axios({
              url: '/api/purchase/add_lot_socks_by_purchase',
              method: 'post',
              baseURL: BASE_URL,
              data: purchase,
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              config: {
                headers: {
                  Accept: 'application/json'
                }
              }
            })
              .then(() => {
                pagedata.submit = false
                router.push({
                  name: 'purchase-view'
                })
              })
              .catch(err => {
                swal('Warning!', err.response.data.error, 'warning')
                pagedata.submit = false
              })
          } else {
            axios({
              url: '/api/purchase/add_new_purchase',
              method: 'post',
              baseURL: BASE_URL,
              data: purchase,
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              config: {
                headers: {
                  Accept: 'application/json'
                }
              }
            })
              .then(() => {
                pagedata.submit = false
                router.push({
                  name: 'purchase-view'
                })
              })
              .catch(err => {
                pagedata.errors = err.response.data.errors
                setTimeout(() => {
                  pagedata.errors = []
                }, 3000)
                pagedata.submit = false
              })
          }
          // }
        }
      }
    }

    function getPurchaseData() {
      purchase.piece = 0
      axios({
        url: 'api/purchase/show_purchase',
        method: 'post',
        baseURL: BASE_URL,
        data: {
          id: purchase.id
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
        .then(res => {
          purchase.piece = res.data.purchase.pieces
          purchase.date = res.data.purchase.date
          purchase.party = stringify(res.data.purchase.party_id)
          purchase.type = res.data.purchase.type
          purchase.stone_id = res.data.purchase.stone_type
          purchase.weight = res.data.purchase.weight
          purchase.est_majuri_total = res.data.purchase.est_value
          purchase.due = res.data.purchase.due
          purchase.voucher = res.data.purchase.voucher_no
          purchase.lotno = res.data.purchase.lot_no
          purchase.per_ct_rate = res.data.purchase.per_ct_rate
          purchase.majuri_rate = res.data.purchase.majuri_rate
          purchase.margin_percent = res.data.purchase.margin_percent

          console.log(purchase.packet_details)
          setTimeout(() => {
            let ind = 0
            if (res.data.packets.length > 0) {
              res.data.packets.forEach(pkt => {
                ;(purchase.packet_details[ind].id = pkt.id),
                  (purchase.packet_details[ind].number = pkt.number),
                  (purchase.packet_details[ind].paket_no = pkt.vepari_no),
                  (purchase.packet_details[ind].quality_id = pkt.quality_id),
                  (purchase.packet_details[ind].weight = pkt.weight),
                  (purchase.packet_details[ind].polish_weight =
                    pkt.cr_polish_weight),
                  (purchase.packet_details[ind].image = pkt.image),
                  (purchase.packet_details[ind].url = pkt.url),
                  (purchase.packet_details[ind].color = pkt.color),
                  (purchase.packet_details[ind].purity = pkt.purity),
                  (purchase.packet_details[ind].shape = pkt.shape),
                  (purchase.packet_details[ind].cut = pkt.cut),
                  (purchase.packet_details[ind].polish = pkt.polish),
                  (purchase.packet_details[ind].symmetry = pkt.symmetry),
                  (purchase.packet_details[ind].fluerocent = pkt.fluerocent),
                  (purchase.packet_details[ind].tension = pkt.tension),
                  (purchase.packet_details[ind].stone = pkt.stone),
                  (purchase.packet_details[ind].discount = pkt.discount),
                  (purchase.packet_details[ind].processing = pkt.processing),
                  (purchase.packet_details[ind].lab = pkt.lab),
                  (purchase.packet_details[ind].certificate_no = pkt.cert_no),
                  (purchase.packet_details[ind].rap_value = pkt.rap_value),
                  (purchase.packet_details[ind].value = pkt.value),
                  (purchase.packet_details[ind].min_dia1 = pkt.min_dia1),
                  (purchase.packet_details[ind].min_dia2 = pkt.min_dia2),
                  (purchase.packet_details[ind].total_depth = pkt.total_depth),
                  (purchase.packet_details[ind].depth_percent =
                    pkt.depth_percent),
                  (purchase.packet_details[ind].table_percent =
                    pkt.table_percent),
                  (purchase.packet_details[ind].length = pkt.length),
                  (purchase.packet_details[ind].width = pkt.width),
                  (purchase.packet_details[ind].height = pkt.height),
                  (purchase.packet_details[ind].comment = pkt.comment),
                  (purchase.packet_details[ind].owner_value = pkt.owner_value),
                  (purchase.packet_details[ind].est_majuri = pkt.est_value),
                  (ind = ind + 1)
                console.log(purchase.packet_details)
              })
            }
            getWeight()
          }, 20)
          console.log(stringify(purchase.stone_id))
        })
        .catch(() => {
          router.push({
            name: 'purchase-view'
          })
        })
    }

    function removeField(id, index) {
      //   swal({
      //   title: "Are you sure?",
      //   text: "You are about to delete a item!",
      //   type: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Delete",
      //   showLoaderOnConfirm: true,
      //   cancelButtonText: "Cancel",

      //   allowOutsideClick: () => !$Swal.isLoading()
      // }).then(result => {
      //     if(result.isConfirmed){
      var i = purchase.packet_details.length
      while (i--) {
        if (i == index) {
          purchase.packet_details.splice(i, 1)
        }
      }
      calculateEstMajuriDetails()
      //     }
      // });
    }

    const AddPiece = () => {
      purchase.packet_details.push({
        paket_no: purchase.voucher ? purchase.voucher + '-' : '',
        number: '',
        weight: '',
        est_majuri: '',
        polish_weight: '',
        color: '',
        purity: '',
        shape: '',
        cut: '',
        polish: '',
        symmetry: '',
        fluerocent: '',
        tension: '',
        stone: '',
        lab: '',
        image: '',
        url: '',
        quality_id: '',
        certificate_no: '',
        rap_value: '',
        min_dia1: '',
        min_dia2: '',
        total_depth: '',
        depth_percent: '',
        table_percent: '',
        length: '',
        width: '',
        height: '',
        comment: '',
        owner_value: '',
        value: '',
        discount: 30,
        processing: 'normal'
      })
    }

    function openPiece(id) {
      let length = screenData.parties.length

      for (let i = 0; i < length; i++) {
        if (screenData.parties[i].id == id) {
          if (screenData.parties[i].home_party == 1) {
            pagedata.home_party = true
          } else {
            pagedata.home_party = false
          }
        }
      }
      getPaketDetail()
    }

    function getPaketDetail() {
      if (pagedata.paket_num == 0 && purchase.piece == 1) {
        pagedata.btnDis = true
        pagedata.btnPrev = true
      } else if (pagedata.paket_num == 0 && purchase.piece > 1) {
        pagedata.btnDis = false
        pagedata.btnPrev = true
      } else if (
        pagedata.paket_num != 0 &&
        purchase.piece == pagedata.paket_num + 1
      ) {
        pagedata.btnPrev = false
        pagedata.btnDis = true
      } else {
        pagedata.btnDis = false
        pagedata.btnPrev = false
      }
      getWeight()
    }

    function prevnext(type) {
      if (type == 'next') {
        // if(validateData()) {
        pagedata.paket_num = pagedata.paket_num + 1
        // }
      } else if (type == 'prev') {
        pagedata.paket_num = pagedata.paket_num - 1
      }
      getPaketDetail()
    }

    function getWeight() {
      purchase.weight = 0
      purchase.packet_details.forEach(element => {
        console.log(parseFloat(element.weight))
        console.log(parseFloat(purchase.weight))
        purchase.weight =
          parseFloat(purchase.weight) +
          parseFloat(element.weight ? element.weight : 0)
      })
      purchase.weight = $h.myFloat(purchase.weight)

      if (pagedata.home_party == true) {
        let rate = $h.myFloat(purchase.per_ct_rate)

        if (rate) {
          let length = purchase.packet_details.length
          for (let i = 0; i < length; i++) {
            if (purchase.packet_details[i].weight) {
              purchase.packet_details[i].owner_value = $h.myFloat(
                $h.myFloat(purchase.packet_details[i].weight) * rate
              )
            }
          }
        }
      }
    }

    function loadPlanningValues(plan) {
      if (plan.polish_weight > plan.weight) {
        plan.polish_weight = ''
        swal(
          'Warning!',
          'Polish Weight Should Be Less then Issue Weight!',
          'warning'
        )
      }
      if (
        plan.polish_weight != '' &&
        plan.color != '' &&
        plan.purity != '' &&
        plan.shape != ''
      ) {
        var i = screenData.shapes.length
        let plan_shape = ''
        while (i--) {
          if (screenData.shapes[i]['id'] == plan.shape) {
            plan_shape = screenData.shapes[i]['shape_group']
          }
        }
        let params = {
          color: plan.color,
          purity: plan.purity,
          shape: plan.shape,
          shape_group: plan_shape,
          polish_weight: plan.polish_weight
        }
        axios({
          url: 'api/plan/get_plan_rapo',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              Accept: 'application/json'
            }
          }
        }).then(res => {
          plan.rap_value = $h.myFloat(res.data.rape_value * plan.polish_weight)
          calculateValue(plan)
        })
      }
      if (
        plan.polish_weight != '' &&
        plan.color != '' &&
        plan.purity != '' &&
        plan.shape != '' &&
        plan.cut != '' &&
        plan.polish != '' &&
        plan.symmetry != '' &&
        plan.fluerocent != '' &&
        plan.tension != '' &&
        purchase.stone_id != ''
      ) {
        let value_params = {
          polish_weight: plan.polish_weight,
          color: plan.color,
          cut: plan.cut,
          purity: plan.purity,
          polish: plan.polish,
          shape: plan.shape,
          symmetry: plan.symmetry,
          fluerocent: plan.fluerocent,
          tension: plan.tension,
          stone: purchase.stone_id,
          type: purchase.type,
          party_id: purchase.party
        }

        axios({
          url: 'api/plan/get_makeable_purchase_value',
          method: 'post',
          baseURL: BASE_URL,
          data: value_params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              Accept: 'application/json'
            }
          }
        }).then(res => {
          plan.est_majuri = res.data.majuri_value * plan.weight
          calculateEstMajuriDetails()
        })
      }
    }

    function calculateEstMajuriDetails() {
      purchase.est_majuri_total = 0
      purchase.packet_details.forEach(element => {
        purchase.est_majuri_total =
          $h.myFloat(purchase.est_majuri_total) + $h.myFloat(element.est_majuri)
      })
      purchase.est_majuri_total = $h.myFloat(purchase.est_majuri_total)
    }

    function loadRoughMajuriValue(plan) {
      getWeight()

      if (plan.weight != '') {
        let value_params = {
          weight: plan.weight,
          stone: purchase.stone_id,
          type: purchase.type,
          party_id: purchase.party
        }

        axios({
          url: 'api/plan/get_rough_purchase_value',
          method: 'post',
          baseURL: BASE_URL,
          data: value_params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              Accept: 'application/json'
            }
          }
        }).then(res => {
          plan.est_majuri = res.data.majuri_value * plan.weight
          calculateEstMajuriDetails()
        })
      }
    }

    function loadValueByStone() {
      let value_params = {
        packet_details: purchase.packet_details,
        stone: purchase.stone_id,
        type: purchase.type,
        party_id: purchase.party
      }

      axios({
        url: 'api/plan/get_stone_change_purchase_value',
        method: 'post',
        baseURL: BASE_URL,
        data: value_params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      }).then(res => {
        let packet_rates = res.data.items

        let length = purchase.packet_details.length

        for (let i = 0; i < length; i++) {
          purchase.packet_details[i].est_majuri =
            packet_rates[i] * purchase.packet_details[i].weight
        }
        calculateEstMajuriDetails()
      })
    }

    function calculateValue(plan) {
      let rap_value = plan.rap_value
      let discount = plan.discount

      if (rap_value && discount) {
        let value = (parseFloat(rap_value) * parseFloat(100 - discount)) / 100
        plan.value = value
      }
    }
    const updateStockName = () => {
      if (purchase.piece > 0 && purchase.voucher) {
        purchase.packet_details.forEach(function callback(value, index) {
          value.paket_no = purchase.voucher + '-' + (index + 1)
        })
      } else if (purchase.piece > 0 && !purchase.voucher) {
        purchase.packet_details.forEach(function callback(value, index) {
          value.paket_no = ''
        })
      }
    }
    const clearPreviewImage = (index) => {
    Swal.fire({
        title: 'Are you sure?',
        text: 'This will remove the image. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, clear it!'
    }).then((result) => {
        if (result.isConfirmed) {
          purchase.packet_details[index].image = '';
          purchase.packet_details[index].url = '';
        }
    });
};
    watch(
      () => purchase.piece,
      (currentValue, oldValue) => {
        let i = oldValue
        let c = currentValue
        if (currentValue > oldValue) {
          for (i; i < c; i++) {
            purchase.packet_details.push({
              paket_no: purchase.voucher ? purchase.voucher + '-' : '',
              number: '',
              weight: '',
              est_majuri: '',
              polish_weight: '',
              color: '',
              image: '',
              url: '',
              quality_id: '',
              purity: '',
              shape: '',
              cut: '',
              polish: '',
              symmetry: '',
              fluerocent: '',
              tension: '',
              stone: '',
              lab: '',
              certificate_no: '',
              rap_value: '',
              min_dia1: '',
              min_dia2: '',
              total_depth: '',
              depth_percent: '',
              table_percent: '',
              length: '',
              width: '',
              height: '',
              comment: '',
              owner_value: '',
              value: '',
              discount: 30,
              processing: 'normal'
            })
          }
        } else {
          purchase.packet_details.splice(c, purchase.packet_details.length)
        }
      }
    )
    return {
      // uploadedImageName,
      // getImageUrl,
      // getImageUrl,
      clearPreviewImage,
      clearDropzone,
      // getPreviewImageUrl,
      showModal,
      closeModal,
      openModal,
      screenData,
      dropzoneSingleRef,
      pagedata,
      purchase,
      user_details,
      loadPlanningValues,
      SavePurchase,
      openPiece,
      getPaketDetail,
      prevnext,
      getWeight,
      loadRoughMajuriValue,
      calculateValue,
      getMarginCosting,
      loadValueByStone,
      updateStockName,
      removeField,
      AddPiece
    }
  }
})
</script>

<!--

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style> -->
