<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">LAB Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0"></div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Report Type</label
            >
            <TomSelect
              v-model="filter.report_type"
              :options="{
                placeholder: 'Report Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Report'
                  }
                }
              }"
              class="w-full"
            >
              <option value="1">LAB Result Received</option>
              <option value="4">LAB Receive Pending</option>
              <option value="3">LAB Received</option>
              <option value="2">Lab Result Pending</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet / Lot</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_no"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Kapan</label
            >
            <TomSelect
              v-model="filter.kapan_id"
              :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Process'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">Select Kapan</option>
              <option
                v-for="item in page_data.lot_items"
                :key="item.id"
                :value="item.id"
                >{{ item.lot_no }} (
                {{ item.voucher_no ? item.voucher_no : '-' }} )</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Worker</label
            >
            <TomSelect
              v-model="filter.worker_id"
              :options="{
                placeholder: 'Select Worker',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Worker'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">Select Worker</option>
              <option
                v-for="item in page_data.employee_items"
                :key="item.id"
                :value="item.id"
                >{{ item.company_name }} (
                {{ item.process_short_code ? item.short_code : '-' }} )</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <div class="flex items-center">
              <label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox"
                v-model="filter.issue_pending"
              />
            </div>
            <input
              type="date"
              id="update-profile-form-13"
              :disabled="filter.issue_pending"
              class="form-control"
              placeholder=""
              v-model="filter.issue_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type="date"
              id="update-profile-form-13"
              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary btn-sm w-full ml-2"
              tabindex="5"
              @click="getReportDetails()"
            >
              Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary btn-sm w-full ml-2"
              tabindex="5"
              @click="exportFilterData()"
            >
              Export
            </button>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 xl:col-span-6">
          <div class="mt-7 flex pt-3">
            <div class="flex flex-wrap">

                <div class="text-sm font-bold mr-6">Issue Pieces: {{ page_data.issue_pieces ? page_data.issue_pieces : 0 }}</div>
                <div class="text-sm font-bold mr-6">Issue Weight: {{ page_data.issue_weight ? page_data.issue_weight : 0 }} Ct</div>
                <div class="text-sm font-bold mr-6">Final Weight: {{ page_data.final_weight ? page_data.final_weight : 0 }} Ct</div>

            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- BEgin: Process Report Start-->
        <div id="responsive-table" class="p-5" v-if="page_data.loading">
          <div class="preview">
            <div class="overflow-x-auto">
              <div
                class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="scrollbar-hidden">
            <div class="intro-y col-span-12 overflow-auto">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 600px"
                :columnDefs="columnDefs.value"
                :rowData="rowData.value"
                :defaultColDef="defaultColDef"
                rowSelection="multiple"
                animateRows="true"
                @grid-ready="onGridReady"
                @filterChanged="calculate_total"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script setup>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import moment from 'moment'
import {
  defineComponent,
  onMounted,
  reactive,
  toRef, ref,
  watch,
  computed
} from 'vue'
import store from '@/store'
import { helper as $h } from '@/utils/helper'

import { AgGridVue } from 'ag-grid-vue3'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import { GridApi, ModuleRegistry, createGrid } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'

const user_details = computed(() => store.state.user.currentUser)
const page_data = reactive({
  sr_no: 1,
  issue_pieces: 0,
  issue_weight: 0,
  final_weight: 0,
  total: [],
  items: [],
  manager_items: [],
  process_items: [],
  lot_items: [],
  employee_items: [],
  loading: false,
  json_fields: {
    'Packet No': 'number',
    'Stock No': 'vepari_no',
    Worker: 'company_name',
    'Issue Date': 'issue_date',
    'Receive Date': 'receive_date',
    'Issue Wight': 'issue_weight',
    'Final Wight': 'final_weight',
    'Final Shape': 'final_shape_name',
    Shape: 'shape_name',
    'Color (Long)': 'color_long',
    Clarity: 'clarity',
    'CUT-PROP': 'cut_prop',
    'POL or Pol/Sym': 'polish',
    SYM: 'sym',
    'Report Number': 'report_number'
  },
  pending_json_fields: {
    'Packet No': 'number',
    'Stock No': 'vepari_no',
    Worker: 'company_name',
    'Issue Date': 'final_shape_name',
    'Receive Date': 'issue_date',
    'Issue Wight': 'issue_weight'
  }
})

const filter = reactive({
  packet_no: '',
  report_type: '1',
  kapan_id: '0',
  worker_id: '0',
  issue_pending: false,
  issue_date: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  receive_date: moment().format('YYYY-MM-DD')
})

const api_filter = reactive({
  packet_no: '',
  report_type: '1',
  kapan_id: '0',
  worker_id: '0',
  issue_pending: false,
  issue_date: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  receive_date: moment().format('YYYY-MM-DD')
})

const gridApi = ref()

const rowData = reactive({
  value: []
})

const onGridReady = params => {
  gridApi.value = params.api
}

const defaultColDef = {
  sortable: true,
  filter: true,
  flex: 1,
  minWidth: 100,
  floatingFilter: true,
  menuTabs: ['filterMenuTab']
}

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ClipboardModule,
  MenuModule,
  MultiFilterModule,
  SetFilterModule
])

const overlayNoRowsTemplate = 'No Records Found!'

const columnDefs = reactive({
  value: [
    { headerName: '#', field: 'sr_no', minWidth: 100 },
    { headerName: 'Packet No', field: 'number', minWidth: 150 },
    { headerName: 'Stock No', field: 'vepari_no', minWidth: 180 },
    { headerName: 'Worker', field: 'company_name', minWidth: 150 },
    {
      headerName: 'Polish Party',
      field: 'polish_party_company_name',
      minWidth: 150
    },
    { headerName: 'Issue Wight', field: 'issue_weight', minWidth: 150 },
    { headerName: 'Final Wight', field: 'final_weight', minWidth: 150 },
    { headerName: 'Final Shape', field: 'final_shape_name', minWidth: 150 },
    { headerName: 'Shape', field: 'shape_name', minWidth: 150 },
    { headerName: 'Color (Long)', field: 'color_long', minWidth: 150 },
    { headerName: 'Clarity', field: 'clarity', minWidth: 150 },
    { headerName: 'CUT-PROP', field: 'cut_prop', minWidth: 150 },
    { headerName: 'POL or Pol/Sym', field: 'polish', minWidth: 150 },
    { headerName: 'SYM', field: 'sym', minWidth: 150 },
    { headerName: 'Report Number', field: 'report_number', minWidth: 150 },
    { headerName: 'Issue Date', field: 'issue_date', minWidth: 200 },
    { headerName: 'Receive Date', field: 'receive_date', minWidth: 200 },
  ]
})

const getReportDetails = async () => {
  api_filter.packet_no = filter.packet_no
  api_filter.report_type = filter.report_type
  api_filter.kapan_id = filter.kapan_id
  api_filter.worker_id = filter.worker_id
  api_filter.issue_pending = filter.issue_pending
  api_filter.issue_date = filter.issue_date
  api_filter.receive_date = filter.receive_date

  rowData.value = []
    gridApi.value.setRowData(rowData.value)

  const params = {
    packet_no: window.btoa(api_filter.packet_no),
    report_type:
      api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
    kapan_id:
      api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
    worker_id:
      api_filter.worker_id == '0' ? '' : window.btoa(api_filter.worker_id),
    issue_pending: api_filter.issue_pending,
    issue_date: window.btoa(api_filter.issue_date),
    receive_date: window.btoa(api_filter.receive_date)
  }
  page_data.loading = true

  let promise = axios({
    url: 'api/report/lab_report',
    method: 'post',
    data: params,
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    config: {
      headers: {
        Accept: 'application/json'
      }
    }
  })
  return promise
    .then(result => result.data)
    .then(data => {
      // page_data.items = data.items
      page_data.loading = false
      let items = data.items
      items.map(item => {
        rowData.value.push({
          number: item.number,
          vepari_no: item.vepari_no,
          company_name: item.company_name,
          polish_party_company_name: item.polish_party_company_name,
          issue_date: item.issue_date,
          receive_date: item.receive_date,
          issue_weight: item.issue_weight,
          final_weight: item.final_weight,
          final_shape_name: item.final_shape_name,
          shape_name: item.shape_name,
          color_long: item.color_long,
          clarity: item.clarity,
          cut_prop: item.cut_prop,
          polish: item.polish,
          sym: item.sym,
          report_number: item.report_number,
          sr_no: page_data.sr_no
        })
        page_data.sr_no += 1
      })

      gridApi.value.setRowData(rowData.value)
      calculate_total()
    })
    .catch(_error => {
      page_data.items = []
      page_data.total = []
      page_data.loading = false
    })
}

const calculate_total = () => {

  console.log("Gii")
  let issue_pieces = 0
  let issue_weight = 0
  let final_weight = 0
  let items = gridApi.value.getModel().rowsToDisplay
  items.forEach(element => {
    issue_pieces += 1
    issue_weight =
      parseFloat(issue_weight) +
      parseFloat(element.data.issue_weight ? element.data.issue_weight : 0);
      final_weight =
      parseFloat(final_weight) +
      parseFloat(element.data.final_weight ? element.data.final_weight : 0);
  })
  page_data.issue_pieces = $h.myFloat(issue_pieces)
  page_data.issue_weight = $h.myFloat(issue_weight)
  page_data.final_weight = $h.myFloat(final_weight)
  // page_data.progress = page_data.total_records
  //   ? parseFloat(
  //       (page_data.issue_pieces * 100) / page_data.total_records
  //     ).toFixed(2)
  //   : 0
}

const getLotDetails = async () => {
  let params = {}
  let api_url = 'api/master/dp_lot_all_details'

  let promise = axios({
    url: api_url,
    method: 'post',
    data: params,
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    config: {
      headers: {
        Accept: 'application/json'
      }
    }
  })
  return promise
    .then(result => result.data)
    .then(data => {
      filter.kapan_id = '0'
      page_data.lot_items = data.items
    })
    .catch(_error => {
      filter.kapan_id = '0'
      page_data.lot_items = []
    })
}

const getWorkerDetails = async () => {
  let params = {
    manager_id:
      user_details.value.user_role == 1 ? '' : user_details.value.account_id,
    employee_type: window.btoa('3')
  }
  let api_url = ''

  if (filter.department_id == '0') {
    api_url = 'api/master/get_employees_by_manager_employee_type'
  } else {
    api_url = 'api/master/get_employees_by_manager_employee_type'
  }
  let promise = axios({
    url: api_url,
    method: 'post',
    data: params,
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    config: {
      headers: {
        Accept: 'application/json'
      }
    }
  })
  return promise
    .then(result => result.data)
    .then(data => {
      filter.worker_id = '0'
      page_data.employee_items = data.items
    })
    .catch(_error => {
      filter.worker_id = '0'
      page_data.employee_items = []
    })
}

function onResetFilter() {
  filter.packet_no = ''
  filter.report_type = '1'
  filter.kapan_id = '0'
  filter.worker_id = '0'
  filter.issue_pending = false
  filter.issue_date = moment()
    .startOf('month')
    .format('YYYY-MM-DD')
  filter.receive_date = moment().format('YYYY-MM-DD')

  getReportDetails();
}

const exportFilterData = () => {
  gridApi.value.exportDataAsCsv()
}
onMounted(() => {
  setTimeout(() => {

    getReportDetails()
  }, 200);
  getLotDetails()
  getWorkerDetails()
})
</script>
