<template>
<div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Process Stock Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-1"
                    >Report Type</label
                  ><input
                    class="show-code form-check-switch mr-0 ml-3"
                    type="checkbox" v-model="filter.in_details"
                  /></div>
                    <TomSelect v-model="filter.report_type" :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report'
                    }
                  }
                }" class="w-full">
                        <option value="4">Manager Summary</option>
                        <option value="1">Kapan Wise</option>
                        <option value="5">Process Summary</option>
                        <option value="2">Process Kapan Wise</option>
                        <option value="6">Party Summary</option>
                        <option value="3">Party Kapan Wise</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
                    <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="filter.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Process</label>
                    <TomSelect v-model="filter.process_id" @change="getProcessChange" :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Process</option>
                        <option value="stock">In Stock</option>
                        <option v-for="item in page_data.process_items" :key="item.id" :value="item.id">{{item.process_name}} ( {{ item.process_short_code ? item.process_short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Manager</label>
                    <TomSelect v-model="filter.manager_id" @change="getEmployeeDetails" :options="{
                  placeholder: 'Select Manager',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Manager'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Manager</option>
                        <option v-for="item in page_data.manager_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.short_code ? item.short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Karigar Type</label>
                    <TomSelect v-model="filter.employee_type" @change="getEmployeeDetails" :options="{
                  placeholder: 'Select Karigar Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Karigar Type'
                    }
                  }
                }" class="w-full">
                        <option value="0">Karigar Type</option>
                        <option value="1">Employee</option>
                        <option value="2">Planner / Checker</option>
                        <option value="3">Job Party</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Employee</label>
                    <TomSelect v-model="filter.employee_id" :options="{
                  placeholder: 'Select Employee',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Employee'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Employee</option>
                        <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Type</label>
                    <TomSelect v-model="filter.packet_type" :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Type'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Type</option>
                        <option value="makeable">Makeable</option>
                        <option value="rough">Rough</option>
                        <option value="video">Video</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Stock Type</label>
                    <TomSelect v-model="filter.stock_type" :options="{
                  placeholder: 'Stock Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Stock Type'
                    }
                  }
                }" class="w-full">
                        <option value="0">Stock Type</option>
                        <option value="1">Hold</option>
                        <option value="2">Active</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="filter.issue_pending"
                class="form-control"
                placeholder=""
                v-model="filter.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                        Generate
                    </button>
                </div>
            </div>
        </div>

        <div>
            <!-- BEgin: Process Report Start-->
            <div id="responsive-table" class="p-5" v-if="page_data.loading">
                <div class="preview">
                    <div class="overflow-x-auto">
                        <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                            <LoadingIcon icon="bars" class="w-8 h-8" />
                            <div class="text-center text-xs mt-2">Getting Latest Price</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="scrollbar-hidden">
                    <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3">
                        <table class="w-full border-2" v-if="(api_filter.report_type == 1 || api_filter.report_type == 4) && api_filter.in_details == false">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="api_filter.report_type == 1">Kapan</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Manager/Employee/Party</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Pieces</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Weight (Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 1">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>
                            </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                <tr v-for="(single_item,ind) in item.sub_details" :key="ind">
                                  <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                  <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 1">{{ ind == 0 ? (single_item.lot_no ? single_item.lot_no : '') : '' }} {{ ind == 0 ? (single_item.kapan_name ? ' ( ' + single_item.kapan_name + ' ) ' : '') : '' }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.process_name ? single_item.process_name : 'Manager') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ single_item.process_id != null ?(single_item.employee_first_name + ' ' + (single_item.employee_last_name ? single_item.employee_last_name : '')) : (single_item.manager_first_name + ' ' + single_item.manager_last_name) }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_pieces ? single_item.issue_pieces : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">
                                    <span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                    <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">Total</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 1"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_pieces ? item.issue_pieces : '0' }}</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                </tr>
                              </tbody>
                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 1">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>

                        </table>
                        <table class="w-full mt-3 border-2" v-if="(api_filter.report_type == 2 || api_filter.report_type == 5) && api_filter.in_details == false">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="api_filter.report_type == 2">Kapan</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Manager/Employee/Party</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Pieces</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Weight (Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 2"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>
                            </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                <tr v-for="(single_item,ind) in item.sub_details" :key="ind" class="border-b hover:bg-gray-200 p-0">
                                  <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                  <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? (single_item.process_name ? single_item.process_name : 'Manager') : '' }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r" v-if="api_filter.report_type == 2">{{ (single_item.lot_no ? single_item.lot_no : '') }} {{ (single_item.kapan_name ? ' ( ' + single_item.kapan_name + ' ) ' : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ single_item.process_id != null ?(single_item.employee_first_name + ' ' + (single_item.employee_last_name ? single_item.employee_last_name : '')) : (single_item.manager_first_name + ' ' + single_item.manager_last_name) }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_pieces ? single_item.issue_pieces : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r"><span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                    <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">Total</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 2"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_pieces ? item.issue_pieces : '0' }}</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                </tr>
                              </tbody>
                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 2"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>

                        </table>
                        <table class="w-full mt-3 border-2" v-if="(api_filter.report_type == 3 || api_filter.report_type == 6) && api_filter.in_details == false">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Party</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="api_filter.report_type == 3">Kapan</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Pieces</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Weight (Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 3"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>
                            </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                <tr v-for="(single_item,ind) in item.sub_details" :key="ind" class="border-b hover:bg-gray-200 p-0">
                                  <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                  <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? (single_item.process_id != null ?(single_item.employee_first_name + ' ' + (single_item.employee_last_name ? single_item.employee_last_name : '')) : (single_item.manager_first_name + ' ' + single_item.manager_last_name)) : '' }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r" v-if="api_filter.report_type == 3">{{ (single_item.lot_no ? single_item.lot_no : '') }} {{ (single_item.kapan_name ? ' ( ' + single_item.kapan_name + ' ) ' : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.process_name ? single_item.process_name : 'Manager') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_pieces ? single_item.issue_pieces : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r"><span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                    <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">Total</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 3"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_pieces ? item.issue_pieces : '0' }}</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                </tr>
                              </tbody>
                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items ? page_data.total.total_items : '' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 3"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>

                        </table>
                        <table class="w-full mt-3 border-2" v-if="(api_filter.report_type == 1 || api_filter.report_type == 4) && api_filter.in_details == true">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="api_filter.report_type == 1">Kapan</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Manager/Employee/Party</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Weight (Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process List</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Purchase Comment</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Comment</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 1">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>
                            </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                <tr v-for="(single_item,ind) in item.sub_details" :key="ind" class="border-b hover:bg-gray-200 p-0">
                                  <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                  <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 1">{{ ind == 0 ? (single_item.lot_no ? single_item.lot_no : '') : '' }} {{ ind == 0 ? (single_item.kapan_name ? ' ( ' + single_item.kapan_name + ' ) ' : '') : '' }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.process_name ? single_item.process_name : 'Manager') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ single_item.process_id != null ?(single_item.employee_first_name + ' ' + (single_item.employee_last_name ? single_item.employee_last_name : '')) : (single_item.manager_first_name + ' ' + single_item.manager_last_name) }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.packet_code ? single_item.packet_code : '') }} {{ (single_item.vepari_no ? ' ( ' + single_item.vepari_no + ' ) ' : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.process_list ? single_item.process_list : '-') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.comment ? single_item.comment : '-') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_comment ? single_item.issue_comment : '-') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r"><span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                    <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_date ? single_item.issue_date : '-') }}</td>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">Total</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 1"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_pieces ? item.issue_pieces : '0' }}</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                </tr>
                              </tbody>
                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 1">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>

                        </table>
                        <table class="w-full mt-3 border-2" v-if="(api_filter.report_type == 2 || api_filter.report_type == 5) && api_filter.in_details == true">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="api_filter.report_type == 2">Kapan</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Manager/Employee/Party</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Weight (Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process List</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Purchase Comment</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Comment</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 2"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>
                            </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                <tr v-for="(single_item,ind) in item.sub_details" :key="ind" class="border-b hover:bg-gray-200 p-0">
                                  <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                  <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? (single_item.process_name ? single_item.process_name : 'Manager') : '' }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r" v-if="api_filter.report_type == 2">{{ (single_item.lot_no ? single_item.lot_no : '') }} {{ (single_item.kapan_name ? ' ( ' + single_item.kapan_name + ' ) ' : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ single_item.process_id != null ?(single_item.employee_first_name + ' ' + (single_item.employee_last_name ? single_item.employee_last_name : '')) : (single_item.manager_first_name + ' ' + single_item.manager_last_name) }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.packet_code ? single_item.packet_code : '') }} {{ (single_item.vepari_no ? ' ( ' + single_item.vepari_no + ' ) ' : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.process_list ? single_item.process_list : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.comment ? single_item.comment : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_comment ? single_item.issue_comment : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r"><span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                    <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_date ? single_item.issue_date : '') }}</td>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">Total</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 2"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_pieces ? item.issue_pieces : '0' }}</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                </tr>
                              </tbody>
                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 2"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>

                        </table>
                        <table class="w-full mt-3 border-2" v-if="(api_filter.report_type == 3 || api_filter.report_type == 6) && api_filter.in_details == true">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Party</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="api_filter.report_type == 3">Kapan</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Weight (Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Process List</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Purchase Comment</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Comment</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 3"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>
                            </thead>
                                <tbody v-for="(item,index) in page_data.items" :key="index">
                                <tr v-for="(single_item,ind) in item.sub_details" :key="ind" class="border-b hover:bg-gray-200 p-0">
                                  <td class="py-2 text-xs lg:text-md whitespace-nowrap text-center border-r">{{ ind == 0 ? index + 1 : '' }}</td>
                                  <td class="py-2 text-xs whitespace-nowrap lg:text-md text-center border-r">{{ ind == 0 ? (single_item.process_id != null ?(single_item.employee_first_name + ' ' + (single_item.employee_last_name ? single_item.employee_last_name : '')) : (single_item.manager_first_name + ' ' + single_item.manager_last_name)) : '' }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r" v-if="api_filter.report_type == 3">{{ (single_item.process_name ? single_item.process_name : 'Manager') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.lot_no ? single_item.lot_no : '') }} {{ (single_item.kapan_name ? ' ( ' + single_item.kapan_name + ' ) ' : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.packet_code ? single_item.packet_code : '') }} {{ (single_item.vepari_no ? ' ( ' + single_item.vepari_no + ' ) ' : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_weight ? $h.myFloat(single_item.issue_weight) : '0') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.process_list ? single_item.process_list : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.comment ? single_item.comment : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_comment ? single_item.issue_comment : '') }}</td>
                                  <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r"><span v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                                    <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span></td>
                                    <td class="py-2 border-b text-xs lg:text-md whitespace-nowrap text-center border-r">{{ (single_item.issue_date ? single_item.issue_date : '') }}</td>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">Total</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="api_filter.report_type == 3"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_pieces ? item.issue_pieces : '0' }}</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '0' }} Ct</td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                </tr>
                              </tbody>
                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_items ? page_data.total.total_items : '' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="api_filter.report_type == 3"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_pieces ? page_data.total.issue_pieces : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>

                        </table>
                        <!-- END: Pagination -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import {
    defineComponent,
    onMounted,
    reactive,
    toRef,
    watch,
    computed
} from 'vue';
import store from '@/store'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            total: [],
            items: [],
            manager_items: [],
            process_items: [],
            lot_items: [],
            employee_items: [],
            loading: false,
        })

        const filter = reactive({
            packet_no: '',
            report_type: '4',
            process_id: '0',
            kapan_id: '0',
            manager_id: '0',
            employee_id: '0',
            employee_type: '0',
            packet_type: '0',
            stock_type: '0',
            in_details: false,
            issue_pending : true,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const api_filter = reactive({
            packet_no: '',
            report_type: '4',
            process_id: '0',
            kapan_id: '0',
            manager_id: '0',
            employee_id: '0',
            employee_type: '0',
            packet_type: '0',
            stock_type: '0',
            in_details: false,
            issue_pending : true,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const getReportDetails = async () => {

            api_filter.packet_no = filter.packet_no
            api_filter.report_type = filter.report_type
            api_filter.process_id = filter.process_id
            api_filter.kapan_id = filter.kapan_id
            api_filter.manager_id = filter.manager_id
            api_filter.employee_id = filter.employee_id
            api_filter.employee_type = filter.employee_type
            api_filter.packet_type = filter.packet_type
            api_filter.stock_type = filter.stock_type
            api_filter.in_details = filter.in_details
            api_filter.issue_pending = filter.issue_pending
            api_filter.issue_date = filter.issue_date
            api_filter.receive_date = filter.receive_date

            const params = {
                packet_no: window.btoa(api_filter.packet_no),
                report_type: api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
                process_id: api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                manager_id: api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id),
                employee_id: api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id),
                employee_type: api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type),
                packet_type: api_filter.packet_type == '0' ? '' : window.btoa(api_filter.packet_type),
                stock_type: api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type),
                in_details: api_filter.in_details,
                issue_pending : api_filter.issue_pending,
                issue_date : api_filter.issue_date,
                receive_date : api_filter.receive_date,
            }
            page_data.loading = true

            let promise = axios({
                url: 'api/report/get_process_stock',
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.items = data.items
                    page_data.total = data.total
                    page_data.loading = false
                })
                .catch(_error => {
                    page_data.items = []
                    page_data.total = []
                    page_data.loading = false
                });
        }

        const getManagerDetails = async () => {
            let params = {
                process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id)
            }
            let api_url = ''

            if (filter.process_id == '0') {

                api_url = 'api/master/dp_managers'
            } else {
                api_url = 'api/master/get_manager_by_department'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.manager_id = '0'
                    page_data.manager_items = data.items
                })
                .catch(_error => {
                    filter.manager_id = '0'
                    page_data.manager_items = []
                });
        }

        const getProcessDetails = async () => {
            let params = {
                id: window.btoa(filter.manager_id)
            }
            let api_url = ''

            if (filter.manager_id == '0') {

                api_url = 'api/master/dp_process'
            } else {
                api_url = 'api/master/dp_process'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.process_id = '0'
                    page_data.process_items = data.items
                })
                .catch(_error => {
                    filter.process_id = '0'
                    page_data.process_items = []
                });
        }

        const getLotDetails = async () => {
            let params = {
                id: window.btoa(filter.manager_id)
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = '0'
                    page_data.lot_items = []
                });
        }

        const getEmployeeDetails = async () => {

            let params = {
                manager_id: user_details.value.user_role == 1 ? (filter.manager_id == '0' ? '' : window.btoa(filter.manager_id)) : user_details.value.account_id,
                process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id),
                employee_type: filter.employee_type == '0' ? '' : window.btoa(filter.employee_type),
            }
            let api_url = ''

            if (filter.department_id == '0') {

                api_url = 'api/master/get_employees_by_manager_employee_type'
            } else {
                api_url = 'api/master/get_employees_by_manager_employee_type'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.employee_id = '0'
                    page_data.employee_items = data.items
                })
                .catch(_error => {
                    filter.employee_id = '0'
                    page_data.employee_items = []
                });
        }

        function onResetFilter() {
            filter.packet_no = ''
            filter.report_type = '4'
            filter.process_id = '0'
            filter.kapan_id = '0'
            filter.manager_id = '0'
            filter.employee_id = '0'
            filter.employee_type = '0'
            filter.packet_type = '0'
            filter.stock_type = '0'
            filter.in_details = false
            filter.issue_pending = true
            filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
            filter.receive_date =moment().format('YYYY-MM-DD')
        }

        function getProcessChange() {
            if (user_details.value.user_role == 1) {
                getManagerDetails()
            }
            getEmployeeDetails()
        }
        onMounted(() => {
            getManagerDetails()
            getProcessDetails()
            getLotDetails()
            getReportDetails()
        })
        return {
            page_data,
            filter,
            api_filter,
            user_details,
            getReportDetails,
            getProcessDetails,
            getManagerDetails,
            onResetFilter,
            getEmployeeDetails,
            getProcessChange,
        }
    }
})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 650px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>
