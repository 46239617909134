<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-2">
      <div class="w-full flex justify-between gap-2">
        <div>
          <div class="grid grid-cols-12 w-full gap-5 mt-2">
            <div class="col-span-12">
                <div class="flex flex-wrap">

                    <div class="text-sm font-bold mr-6">Issue Pieces: {{ page_data.issue_pieces ? page_data.issue_pieces : 0 }}</div>
                    <div class="text-sm font-bold mr-6">Issue Weight: {{ page_data.issue_weight ? page_data.issue_weight : 0 }} Ct</div>

                </div>
            </div>
          </div>
        </div>
        <div class="flex gap-2">
          <a
          class="btn btn-primary p-1  rounded-sm text-right"
          href="javascript:;"
          title="Add Comment"
          data-toggle="modal"
          data-target="#filter-modal"
          >Filters</a
        >
        <a class="btn btn-secondary p-1 rounded-sm text-right" @click="exportFilterData"
          >Export</a
        >
        </div>
      </div>
      <div class="progress w-1/2 h-4 rounded">
        <div
          class="progress-bar bg-theme-1 rounded"
          :style="{ width: page_data.progress + '%' }"
          role="progressbar"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ page_data.progress }} %
        </div>
      </div>
      <div>
        <!-- BEgin: Process Report Start-->
        <div
          id="responsive-table"
          class="p-5"
          :class="{ hidden: page_data.loading == false }"
        >
          <div class="preview">
            <div class="overflow-x-auto">
              <div
                class="col-span-6 sm:col-span-3 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{ hidden: page_data.loading }">
          <div class="mt-5">
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <ag-grid-vue
                  class="ag-theme-alpine"
                  style="height: 600px"
                  :columnDefs="columnDefs.value"
                  :rowData="rowData.value"
                  :defaultColDef="defaultColDef"
                  rowSelection="multiple"
                  animateRows="true"
                  @grid-ready="onGridReady"
                  @filterChanged="calculate_total"
                  :overlayNoRowsTemplate="overlayNoRowsTemplate"
                >
                </ag-grid-vue>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN:Modal Filter -->
    <div id="filter-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xxl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Filters</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Packet / Lot</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_no"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select Kapan</label
            >
            <TomSelect
              v-model="filter.kapan_id"
              :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Process'
                  }
                }
              }"
              class="w-full"
              multiple
            >
              <option
                v-for="item in page_data.lot_items"
                :key="item.id"
                :value="item.id"
                >{{ item.lot_no }} (
                {{ item.voucher_no ? item.voucher_no : '-' }} )</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select Party</label
            >
            <TomSelect
              v-model="filter.party_id"
              :options="{
                placeholder: 'Select Party',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Party'
                  }
                }
              }"
              class="w-full"
              multiple
            >
              <option
                v-for="party in page_data.parties"
                :key="party.id"
                :value="party.id"
                >{{ party.first_name }} {{ party.last_name }}</option
              >
            </TomSelect>
          </div>
        </div>
        <div
          class="col-span-6 md:col-span-4"
          v-if="user_details.user_role == 1"
        >
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select Department</label
            >
            <TomSelect
              v-model="filter.department_id"
              :options="{
                placeholder: 'Select Department',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Department'
                  }
                }
              }"
              class="w-full"
              multiple
            >
              <option
                v-for="department in page_data.departments"
                :key="department.id"
                :value="department.id"
                >{{ department.department_name }} (
                {{
                  department.department_short_code
                    ? department.department_short_code
                    : '-'
                }}
                )</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select Process</label
            >
            <TomSelect
              v-model="filter.process_id"
              @change="getEmployeeDetails"
              :options="{
                placeholder: 'Select Process',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Process'
                  }
                }
              }"
              class="w-full"
              multiple
            >
              <option value="stock">In Stock</option>
              <option value="in_process">In Process</option>
              <option
                v-for="item in page_data.process_items"
                :key="item.id"
                :value="item.id"
                >{{ item.process_name }} (
                {{ item.process_short_code ? item.process_short_code : '-' }}
                )</option
              >
            </TomSelect>
          </div>
        </div>
        <div
          class="col-span-6 md:col-span-4"
          v-if="user_details.user_role == 1"
        >
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select Manager</label
            >
            <TomSelect
              v-model="filter.manager_id"
              @change="getEmployeeDetails"
              :options="{
                placeholder: 'Select Manager',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Manager'
                  }
                }
              }"
              class="w-full"
              multiple
            >
              <option
                v-for="item in page_data.manager_items"
                :key="item.id"
                :value="item.id"
                >{{ item.first_name }} {{ item.last_name }} (
                {{ item.short_code ? item.short_code : '-' }} )</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Karigar Type</label
            >
            <TomSelect
              v-model="filter.employee_type"
              @change="getEmployeeDetails"
              :options="{
                placeholder: 'Select Karigar Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Karigar Type'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">Karigar Type</option>
              <option value="1">Employee</option>
              <option value="2">Planner / Checker</option>
              <option value="3">Job Party</option>
            </TomSelect>
          </div>
        </div>
        <div
          class="col-span-6 md:col-span-4"
          v-if="filter.employee_type != '0'"
        >
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select Employee</label
            >
            <TomSelect
              v-model="filter.employee_id"
              :options="{
                placeholder: 'Select Employee',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Employee'
                  }
                }
              }"
              class="w-full"
              multiple
            >
              <option
                v-for="item in page_data.employee_items"
                :key="item.id"
                :value="item.id"
                >{{ item.first_name }} {{ item.last_name }} (
                {{ item.process_short_code ? item.short_code : '-' }} )</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select Type</label
            >
            <TomSelect
              v-model="filter.packet_type"
              :options="{
                placeholder: 'Select Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Type'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">Select Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Stock Type</label
            >
            <TomSelect
              v-model="filter.stock_type"
              :options="{
                placeholder: 'Stock Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stock Type'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">All</option>
              <option value="1">Home Party</option>
              <option value="2">Job Work</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Stock Status</label
            >
            <TomSelect
              v-model="filter.stock_status"
              :options="{
                placeholder: 'Stock Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Stock Status'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">All</option>
              <option value="1">Internal</option>
              <option value="2">External</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <div class="flex items-center">
              <label for="update-profile-form-6" class="form-label py-0"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 h-5"
                type="checkbox"
                v-model="filter.issue_pending"
              />
            </div>
            <input
              type="date"
              id="update-profile-form-13"
              :disabled="filter.issue_pending"
              class="form-control"
              placeholder=""
              v-model="filter.issue_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-4">
          <div>
            <label for="update-profile-form-6" class="form-label py-0"
              >Select End Date</label
            >
            <input
              type="date"
              id="update-profile-form-13"
              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex">
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="getReportDetails(1)"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- END:Modal Filter -->
  </div>
</template>

<script setup>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import moment from 'moment'
import {
  ref,
  defineComponent,
  onMounted,
  reactive,
  toRef,
  watch,
  computed
} from 'vue'
import store from '@/store'

import { AgGridVue } from 'ag-grid-vue3'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import {
  GridApi,
  ModuleRegistry,
  createGrid
} from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { helper as $h } from '@/utils/helper'
// import MultipleSelect from '@/components/MultipleSelect/Main.vue'

// export default defineComponent({
//   components:{
//       // MultipleSelect
//     },
//     setup() {
const emit = defineEmits(['export'])
const user_details = computed(() => store.state.user.currentUser)
const page_data = reactive({
  total: [],
  items: [],
  main_tbl: [],
  manager_items: [],
  process_items: [],
  departments: [],
  parties: [],
  lot_items: [],
  employee_items: [],
  loading: false,
  in_progress: false,
  progress: 0,
  current_page: 1,
  total_records: 0,
  sr_no: 1,
  last_page: '',
  per_page: 5000,
  issue_pieces: 0,
  issue_weight: 0
})

const filter = reactive({
  report_type: '1',
  packet_no: '',
  kapan_id: [],
  manager_id: [],
  department_id: [],
  process_id: [],
  employee_id: [],
  employee_type: '0',
  stock_type: '0',
  packet_type: '0',
  stock_status: '0',
  issue_pending: true,
  issue_date: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  receive_date: moment().format('YYYY-MM-DD'),
  // process_done : '0',
  party_id: '0',
  days: '',
  late: '',
  day_order: true,
  late_order: false
})

const api_filter = reactive({
  report_type: '1',
  packet_no: '',
  kapan_id: [],
  manager_id: [],
  department_id: [],
  process_id: [],
  employee_id: [],
  employee_type: '0',
  stock_type: '0',
  packet_type: '0',
  stock_status: '0',
  issue_pending: true,
  issue_date: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  receive_date: moment().format('YYYY-MM-DD'),
  // process_done : '0',
  party_id: [],
  days: '',
  late: '',
  day_order: true,
  late_order: false
})

const gridApi = ref()

const rowData = reactive({
  value: []
})

const onGridReady = params => {
  gridApi.value = params.api
}

const defaultColDef = {
  sortable: true,
  filter: true,
  flex: 1,
  minWidth: 100,
  floatingFilter: true,
  menuTabs: ['filterMenuTab']
}

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ClipboardModule,
  MenuModule,
  MultiFilterModule,
  SetFilterModule
])

const overlayNoRowsTemplate = 'No Records Found!'

const columnDefs = reactive({
  value: [
    { headerName: '#', field: 'sr_no', minWidth: 100 },
    { headerName: 'Packet', field: 'number', minWidth: 150 },
    { headerName: 'Stock', field: 'vepari_no', minWidth: 150 },
    { headerName: 'Proc', field: 'process_short_code', minWidth: 150 },
    { headerName: 'Type', field: 'packet_type', minWidth: 100 },
    { headerName: 'Dept', field: 'department_short_code', minWidth: 150 },
    { headerName: 'Mgr', field: 'manager_short_name', minWidth: 150 },
    { headerName: 'Emp', field: 'employee_short_name', minWidth: 150 },
    { headerName: 'R', field: 'weight', minWidth: 100 },
    { headerName: 'Iss', field: 'issue_weight', minWidth: 100 },
    { headerName: 'PL', field: 'cr_polish_weight', minWidth: 100 },
    { headerName: 'Shape', field: 'shape', minWidth: 100 },
    { headerName: 'Color', field: 'color', minWidth: 100 },
    { headerName: 'Purity', field: 'purity', minWidth: 100 },
    { headerName: 'Cut', field: 'cut', minWidth: 100 },
    { headerName: 'Polish', field: 'polish', minWidth: 100 },
    { headerName: 'Symmetry', field: 'symmetry', minWidth: 100 },
    { headerName: 'length', field: 'f_length', minWidth: 100 },
    { headerName: 'width', field: 'f_width', minWidth: 100 },
    { headerName: 'height', field: 'f_height', minWidth: 100 },
    { headerName: 'Iss Date', field: 'issue_date', minWidth: 200 },
    { headerName: 'Status', field: 'status', minWidth: 120 }
  ]
})

const getReportDetails = index => {
  page_data.current_page = index
  if (page_data.current_page == 1) {
    api_filter.report_type = filter.report_type
    api_filter.packet_no = filter.packet_no
    api_filter.kapan_id = filter.kapan_id
    api_filter.manager_id = filter.manager_id
    api_filter.department_id = filter.department_id
    api_filter.process_id = filter.process_id
    api_filter.employee_id = filter.employee_id
    api_filter.employee_type = filter.employee_type
    api_filter.stock_type = filter.stock_type
    api_filter.packet_type = filter.packet_type
    api_filter.issue_pending = filter.issue_pending
    api_filter.issue_date = filter.issue_date
    api_filter.stock_status = filter.stock_status
    api_filter.receive_date = filter.receive_date
    // api_filter.process_done = filter.process_done
    api_filter.days = filter.days
    api_filter.late = filter.late
    api_filter.day_order = filter.day_order
    api_filter.late_order = filter.late_order
    api_filter.party_id = filter.party_id

    rowData.value = []
    gridApi.value.setRowData(rowData.value)
    page_data.loading = true
    page_data.sr_no = 1

    cash('#filter-modal').modal('hide')
  }
  page_data.in_progress = true

  const params = {
    report_control: window.btoa('lot'),
    report_type: window.btoa(api_filter.report_type),
    stock_status: api_filter.stock_status == '0' ? '' : api_filter.stock_status,
    party_id: api_filter.party_id,
    packet_no: window.btoa(api_filter.packet_no),
    process_id: api_filter.process_id,
    kapan_id: api_filter.kapan_id,
    department_id: api_filter.department_id,
    manager_id: api_filter.manager_id,
    employee_id: api_filter.employee_id,
    employee_type:
      api_filter.employee_type == '0' ? '' : api_filter.employee_type,
    stock_type: api_filter.stock_type == '0' ? '' : api_filter.stock_type,
    packet_type: api_filter.packet_type == '0' ? '' : api_filter.packet_type,
    issue_pending: api_filter.issue_pending,
    issue_date: api_filter.issue_date,
    receive_date: api_filter.receive_date,
    days: api_filter.days,
    late: api_filter.late,
    day_order: api_filter.day_order,
    late_order: api_filter.late_order,
    // process_done : api_filter.process_done,
    page: page_data.current_page,
    size: page_data.per_page
  }

  let promise = axios({
    url: 'api/report/ssr-ssr',
    method: 'post',
    data: params,
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    config: { headers: { Accept: 'application/json' } }
  })
  return promise
    .then(result => result.data)
    .then(data => {
      page_data.loading = false

      let items = data.data

      items.map(item => {
        rowData.value.push({
          number: item.number,
          vepari_no: item.vepari_no,
          process_short_code: item.process_short_code,
          packet_type: item.packet_type,
          department_short_code: item.department_short_code,
          manager_short_name: item.manager_short_name,
          employee_short_name: item.employee_short_name,
          weight: item.weight,
          issue_weight: item.issue_weight,
          cr_polish_weight: item.cr_polish_weight,
          shape: item.shape,
          color: item.color,
          purity: item.purity,
          cut: item.cut,
          polish: item.polish,
          symmetry: item.symmetry,
          f_length: item.f_length,
          f_width: item.f_width,
          f_height: item.f_height,
          issue_date: item.issue_date,
          status: item.transaction_status == 1 ? 'Hold' : 'Active',
          sr_no: page_data.sr_no
        })
        page_data.sr_no += 1
      })

      gridApi.value.setRowData(rowData.value)
      calculate_total()

      if (page_data.current_page == 1) {
        page_data.last_page = data.last_page
        page_data.total_records = data.total
      }

      if (page_data.current_page == data.last_page) {
        page_data.in_progress = false
      } else {
        page_data.current_page += 1
        getReportDetails(page_data.current_page)
      }
    })
    .catch(_error => {
      // page_data.items = []
      page_data.loading = false
    })
}

function onResetFilter() {
  filter.report_type = '1'
  filter.packet_no = ''
  filter.kapan_id = []
  filter.manager_id = []
  filter.process_id = []
  filter.employee_id = []
  filter.party_id = []
  filter.department_id = []
  filter.employee_type = '0'
  filter.stock_type = '0'
  filter.packet_type = '0'
  filter.stock_status = '0'
  filter.issue_pending = true
  filter.issue_date = moment()
    .startOf('month')
    .format('YYYY-MM-DD')
  filter.receive_date = moment().format('YYYY-MM-DD')
  // filter.process_done = '0'
  filter.days = ''
  filter.late = ''
}

const getEmployeeDetails = () => {
  let params = {
    manager_id: filter.manager_id,
    process_id: filter.process_id,
    employee_type:
      filter.employee_type == '0' ? '' : window.btoa(filter.employee_type)
  }
  let api_url = ''

  if (filter.department_id == '0') {
    api_url = 'api/master/get_employees_by_manager_employee_type_multi'
  } else {
    api_url = 'api/master/get_employees_by_manager_employee_type_multi'
  }
  let promise = axios({
    url: api_url,
    method: 'post',
    data: params,
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    config: {
      headers: {
        Accept: 'application/json'
      }
    }
  })
  return promise
    .then(result => result.data)
    .then(data => {
      filter.employee_id = []
      page_data.employee_items = data.items
    })
    .catch(_error => {
      filter.employee_id = []
      page_data.employee_items = []
    })
}

const calculate_total = () => {
  let issue_pieces = 0
  let issue_weight = 0
  rowData.value.forEach(element => {
    issue_pieces += 1
    issue_weight =
      parseFloat(issue_weight) +
      parseFloat(element.issue_weight ? element.issue_weight : 0)
  })

  page_data.issue_pieces = $h.myFloat(issue_pieces)
  page_data.issue_weight = $h.myFloat(issue_weight)

  page_data.progress = page_data.total_records
    ? parseFloat(
        (page_data.issue_pieces * 100) / page_data.total_records
      ).toFixed(2)
    : 0
}
const setKapanItems = items => {
  filter.kapan_id = []
  page_data.lot_items = items
}
const setPartyItems = items => {
  filter.party_id = []
  page_data.parties = items
}
const setManagerItems = items => {
  filter.manager_id = []
  page_data.manager_items = items
}
const setDepartmentItems = items => {
  page_data.departments = items
}
const setLotItems = items => {
  page_data.kapan_id = []
  page_data.lot_items = items
}

const setProcessItems = items => {
  filter.process_id = []
  page_data.process_items = items
}
const exportFilterData = () => {
  gridApi.value.exportDataAsCsv()
}
defineExpose({
  setProcessItems,
  setKapanItems,
  setPartyItems,
  setManagerItems,
  setDepartmentItems,
  setLotItems
})

onMounted(() => {
  setTimeout(() => {
    getReportDetails(1);
  }, 2000);
})
</script>
<style scoped>
.tableFixHead {
  overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
  max-height: 650px; /* gives an initial height of 200px to the table */
}
.tableFixHead thead {
  position: sticky; /* make the table heads sticky */
  top: 0px; /* table head will be placed from the top of the table and sticks to it */
}
</style>
