<template>
  <div>
      <!-- Headings -->
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Rejection Report</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">

          <div class="grid grid-cols-12 gap-x-2">
              <div class="col-span-6">
                  <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                      <TomSelect v-model="filter.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full" multiple>
                          <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-2">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                          Generate
                      </button>
                  </div>
              </div>
          </div>

          <div>
              <!-- BEgin: Process Report Start-->
              <div id="responsive-table" class="p-5" v-if="page_data.loading">
                  <div class="preview">
                      <div class="overflow-x-auto">
                          <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                              <LoadingIcon icon="bars" class="w-8 h-8" />
                              <div class="text-center text-xs mt-2">Getting Latest Price</div>
                          </div>
                      </div>
                  </div>
              </div>

              <div v-else>
                  <div class="scrollbar-hidden">
                      <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                          <table class="w-full border-0">
                              <thead>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <th class="py-2 text-xs whitespace-nowrap border">#</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">KAPAN NO</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">CREATED AT</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">CURRENT <br> AVAIL. PCS</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">CURRENT <br> AVAIL. WT(CT)</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">DIRECT <br> REJ. PCS</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">DIRECT <br> REJ. WT(CT)</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">PROCESS <br> REJ. PCS</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">PROCESS <br> REJ. WT(CT)</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">NET <br> REJ. PCS</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">NET <br> REJ. WT(CT)</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">PURCHASE <br> USED PCS</th>
                                      <th class="py-2 text-xs whitespace-nowrap border">PURCHASE <br> USED WT(CT)</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="py-2 border-b text-xs text-center border-r"></td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ page_data.total.total_kapans }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r"></td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.cr_rejection_pcs ? page_data.total.cr_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.cr_rejection_wt ? $h.myFloat(page_data.total.cr_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.d_rejection_pcs ? page_data.total.d_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.d_rejection_wt ? $h.myFloat(page_data.total.d_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.p_rejection_pcs ? page_data.total.p_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.p_rejection_wt ? $h.myFloat(page_data.total.p_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.n_rejection_pcs ? page_data.total.n_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.n_rejection_wt ? $h.myFloat(page_data.total.n_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.pr_rejection_pcs ? page_data.total.pr_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.pr_rejection_wt ? $h.myFloat(page_data.total.pr_rejection_wt) : 0) }} Ct</td>
                                    </tr>
                              </thead>
                                  <tbody v-for="(item,index) in page_data.items" :key="index">

                                    <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ index + 1 }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ item.lot_no }} ({{ item.voucher_no }})</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ item.created_at }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.cr_rejection_pcs ? item.cr_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.cr_rejection_wt ? $h.myFloat(item.cr_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.d_rejection_pcs ? item.d_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.d_rejection_wt ? $h.myFloat(item.d_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.p_rejection_pcs ? item.p_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.p_rejection_wt ? $h.myFloat(item.p_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.n_rejection_pcs ? item.n_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.n_rejection_wt ? $h.myFloat(item.n_rejection_wt) : 0) }} Ct</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.pr_rejection_pcs ? item.pr_rejection_pcs : 0) }}</td>
                                      <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.pr_rejection_wt ? $h.myFloat(item.pr_rejection_wt) : 0) }} Ct</td>
                                    </tr>
                                </tbody>

                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->

  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config';
  import axios from 'axios';
  import moment from 'moment';
  import {
      defineComponent,
      onMounted,
      reactive,
      toRef,
      watch,
      computed
  } from 'vue';
  import store from '@/store'
  import { helper as $h } from '@/utils/helper'

  export default defineComponent({
      setup() {
          const user_details = computed(() => store.state.user.currentUser)
          const page_data = reactive({
              total: [],
              items: [],
              manager_items: [],
              process_items: [],
              lot_items: [],
              employee_items: [],
              loading: false,
          })

          const filter = reactive({
              kapan_id: [],
              worker_id: '0',
              issue_pending : false,
              issue_date: moment().startOf('month').format('YYYY-MM-DD'),
              receive_date:moment().format('YYYY-MM-DD'),
          })

          const api_filter = reactive({
              kapan_id: [],
              issue_pending : false,
              issue_date: moment().startOf('month').format('YYYY-MM-DD'),
              receive_date:moment().format('YYYY-MM-DD'),
          })

          const getReportDetails = async () => {

              api_filter.kapan_id = filter.kapan_id
              api_filter.issue_pending = filter.issue_pending
              api_filter.issue_date = filter.issue_date
              api_filter.receive_date = filter.receive_date

              const params = {
                  kapan_id: (api_filter.kapan_id.length > 0  ? window.btoa(api_filter.kapan_id) : ""),
                  // issue_pending : api_filter.issue_pending,
                  // start_date : window.btoa(api_filter.issue_date),
                  // end_date : window.btoa(api_filter.receive_date),
              }
              page_data.loading = true

              let promise = axios({
                  url: 'api/report/rough_rejection_report',
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      page_data.items = data.items
                      page_data.total = data.total
                      page_data.loading = false
                  })
                  .catch(_error => {
                      page_data.items = []
                      page_data.total = []
                      page_data.loading = false
                  });
          }

          const getLotDetails = async () => {
              let params = {
              }
              let api_url = 'api/master/dp_lot_all_details'

              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.kapan_id = []
                      page_data.lot_items = data.items
                  })
                  .catch(_error => {
                      filter.kapan_id = []
                      page_data.lot_items = []
                  });
          }

          const getWorkerDetails = async () => {

              let params = {
                  manager_id: user_details.value.user_role == 1 ? '' : user_details.value.account_id,
                  employee_type: window.btoa('3'),
              }
              let api_url = ''

              if (filter.department_id == '0') {

                  api_url = 'api/master/get_employees_by_manager_employee_type'
              } else {
                  api_url = 'api/master/get_employees_by_manager_employee_type'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.worker_id = '0'
                      page_data.employee_items = data.items
                  })
                  .catch(_error => {
                      filter.worker_id = '0'
                      page_data.employee_items = []
                  });
          }

          function onResetFilter() {
              filter.kapan_id = '0'
              filter.issue_pending = false
              filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
              filter.receive_date =moment().format('YYYY-MM-DD')

              getReportDetails()
          }
          onMounted(() => {
              getLotDetails()
              getReportDetails()
              getWorkerDetails()
          })
          return {
              page_data,
              filter,
              api_filter,
              user_details,
              getReportDetails,
              onResetFilter,
              getWorkerDetails,
          }
      }
  })
  </script>
  <style scoped>
  .tableFixHead {
          overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
          max-height: 650px; /* gives an initial height of 200px to the table */
        }
        .tableFixHead thead {
          position: sticky; /* make the table heads sticky */
          top: 0px; /* table head will be placed from the top of the table and sticks to it */
        }
        </style>
