/* eslint-disable */
import { BASE_URL } from '@/constants/config.js'
import router from '@/router';
export default (to, from, next) => {
    console.log(to.fullPath)
    if (localStorage.getItem('token') != null && localStorage.getItem('token').length > 0) {
        // verify with firebase or jwt
        axios({
            url: 'api/user_details',
            method: 'get',
            baseURL: BASE_URL,
            params: {
              secret_key_auth: btoa(localStorage.getItem('sk_key'))
            },
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept': 'application/json' } }
        }).then(response => {

          console.log(response.data.sk_key)

          if(response.data.message == 'Remove Key.') {
            localStorage.removeItem('sk_key')
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            next('/user/login')
            router.go()
          }
            next()

        }).catch(response => {


            next('/user/login')
        });

    } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        next('/user/login')
    }
}
